import React from "react";
import DesktopNavBar from "./desktop/DesktopNavBar";
import MobileBottomNavBar from "./mobile/bottom/MobileBottomNavBar";
import MobileTopNavBar from "./mobile/top/MobileTopNavBar";
import { useIsSmScreen } from "hooks/useIsSmScreen";

/**
 * Component that renders desktop or mobile navigation bar depending on screen size.
 */
export default function NavBar() {
  const isSmScreen = useIsSmScreen();

  if (isSmScreen) {
    return (
      <>
        <MobileBottomNavBar />
        <MobileTopNavBar />
      </>
    );
  } else {
    return <DesktopNavBar />;
  }
}
