import React, { ReactNode } from "react";

/**
 * Component that renders a list item used in MobileBottomNavBar item.
 */
export default function MobileBottomNavBarItem({
  href,
  onClick,
  icon,
  label,
}: {
  href?: string;
  onClick?: () => void;
  icon: ReactNode;
  label: string;
}) {
  return (
    <div className="flex justify-center">
      {href && (
        <a
          href={href}
          className={
            "flex flex-col items-center text-base font-normal text-gray-900"
          }
          aria-label={label}
        >
          <span className={"rounded-2xl border-2 p-2 border-gray-200"}>
            {icon}
          </span>
          <span className="text-xs mt-1 text-center">{label}</span>
        </a>
      )}

      {onClick && (
        <button
          onClick={onClick}
          className={
            "flex flex-col items-center text-base font-normal text-gray-900"
          }
          aria-label={label}
        >
          <span className={"rounded-2xl border-2 p-2 border-gray-200"}>
            {icon}
          </span>
          <span className="text-xs mt-1 text-center">{label}</span>
        </button>
      )}
    </div>
  );
}
