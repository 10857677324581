/**
 * This module provides utility functions for making HTTP requests to the Braincargo API
 * using axios. It includes methods for GET, POST, PUT, PATCH, and DELETE requests,
 * and handles authentication through access tokens. Error handling is integrated with
 * toast notifications and redirects for unauthorized access. 
 * Admin notifications are sent through a Lambda function when server errors occur..
 */

import axios from "axios";
import { getAccessToken, removeTokens } from "./localStorage";
import { history } from "helpers/history";
import { QueryParams } from "types/api";
import { APP_URLS } from "navigation";
import { toast } from "react-toastify";

/**
 * Default page size for paginating API requests
 */
export const PAGE_SIZE = 8;

/**
 * Base URL for API requests
 */
export const API_BASE_URL = 
// "https://staging-api.braincargo.com"
  // process.env.REACT_APP_API_BASE_URL || "https://qa-api.braincargo.com";
  process.env.REACT_APP_API_BASE_URL || "https://staging-api.braincargo.com";
console.log("API_BASE_URL", API_BASE_URL);

/**
 * Generate headers for API requests
 */
const generateHeaders = function (contentType = "application/json") {
  const accessToken = getAccessToken();
  console.log("token_1", accessToken);

  return {
    headers: {
      "Content-Type": contentType,
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

/**
 * Remove tokens and redirect to login page if response status is 401, otherwise return response data
 */
const handleResponse = (response: any) => {
  if ([401, 403, 400, 404].includes(response.status)) {
    removeTokens();
    history.navigate(APP_URLS.LOGIN);
  }
  return response.data;
};

const handleError = async (error: any) => {
  if (error.response) {
    const status = error.response.status;

    if ([404, 401, 403, 400].includes(status)) {
      removeTokens();
      history.navigate(APP_URLS.LOGIN);
      toast.error("404 Error: Resource not found.");
      console.error("404 Error: Resource not found", error.response);
    } else if (status >= 500 && status < 600) {
      toast.error("Server error. Please try again later.");
      console.error("Server responded with error:", status);

      await sendNotificationToLambda();
    } else {
      toast.error(`Error: ${status}. Please try again.`);
      console.error("Error occurred:", error.response.status);
    }
  } else if (error.request) {
    toast.error("Server is down or unreachable. Please try again later.");
    console.error("Server is down or unreachable");

    await sendNotificationToLambda();
  } else {
    toast.error("An error occurred. Please try again.");
    console.error("Error in setting up the request", error.message);
  }

  throw error;
};

/**
 * Make a GET request to the API
 */
export const get = async function (
  url: string,
  noHeaders = false,
  contentType?: string,
  queryParams?: QueryParams
) {
  console.log("header", contentType, "::", queryParams);
  const headers = noHeaders ? {} : generateHeaders(contentType);
  const urlObj = new URL(API_BASE_URL + url);

  urlObj.searchParams.set("page_size", PAGE_SIZE.toString());
  if (queryParams) {
    Object.keys(queryParams).forEach((key) => {
      const val = queryParams[key];
      if (val) {
        urlObj.searchParams.set(key, val);
      }
    });
  }

  try {
    console.log("response_DATA", urlObj, headers);
    const response = await axios.get(urlObj.toString(), headers);
    return handleResponse(response);
  } catch (err: any) {
    await handleError(err);
    throw handleResponse(err.response);
  }
};

/**
 * Make a POST request to the API
 */
export const post = async function (
  url: string,
  body: any,
  noHeaders = false,
  contentType?: string
) {
  const headers = noHeaders ? {} : generateHeaders(contentType);

  try {
    const response = await axios.post(API_BASE_URL + url, body, headers);
    return handleResponse(response);
  } catch (err: any) {
    await handleError(err);
    throw handleResponse(err.response);
  }
};

/**
 * Make a PUT request to the API
 */
export const put = async function (
  url: string,
  body: any,
  noHeaders = false,
  contentType?: string
) {
  const headers = noHeaders ? {} : generateHeaders(contentType);

  try {
    const response = await axios.put(API_BASE_URL + url, body, headers);
    return handleResponse(response);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};

/**
 * Make a PATCH request to the API
 */
export const patch = async function (
  url: string,
  body: any,
  noHeaders = false,
  contentType?: string
) {
  const headers = noHeaders ? {} : generateHeaders(contentType);

  try {
    const response = await axios.patch(API_BASE_URL + url, body, headers);
    return handleResponse(response);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};

/**
 * Make a DELETE request to the API
 */
export const deleteRequest = async function (
  url: string,
  noHeaders = false,
  contentType?: string
) {
  const headers = noHeaders ? {} : generateHeaders(contentType);

  try {
    const response = await axios.delete(API_BASE_URL + url, headers);
    return handleResponse(response);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};

// import axios from "axios";
// import { getAccessToken, getRefreshToken, removeTokens, setTokens } from "./localStorage";
// import { history } from "helpers/history";
// import { QueryParams } from "types/api";
// import { APP_URLS } from "navigation";
// import { toast } from "react-toastify";

// /**
//  * Default page size for paginating API requests
//  */
// export const PAGE_SIZE = 8;

// /**
//  * Base URL for API requests
//  */
// export const API_BASE_URL =
// "https://staging-api.braincargo.com"
//   // process.env.REACT_APP_API_BASE_URL || "http://localhost:8000";
// console.log("API_BASE_URL", API_BASE_URL);
// /**
//  * Generate headers for API requests
//  */
// const generateHeaders = function (contentType = "application/json") {
//   const accessToken = getAccessToken();

//   return {
//     headers: {
//       "Content-Type": contentType,
//       Authorization: `Bearer ${accessToken}`,
//     },
//   };
// };

// const notifyAdminsViaLambda = async (statusCode: number) => {
//   const lambdaUrl =
//   'https://bvcbzxl640.execute-api.us-west-2.amazonaws.com/prod/send-notification-smtp';
//   try {
//     console.log('LAMBDA_URL', lambdaUrl, '::', statusCode);
//     const response = await axios.post(lambdaUrl, {
//       statusCode: statusCode,
//       message: `Server is down with status code ${statusCode}`,
//     });
//     console.log('Successfully notified admins via AWS Lambda.', response.data);
//   } catch (error) {
//     console.error('Error notifying admins via AWS Lambda.', error);
//   }
// };

// /**
//  * Remove tokens and redirect to login page if response status is 401, otherwise return response data
//  */
// // const handleResponse = (response: any) => {
// //   if (response.status === 401) {
// //     removeTokens();
// //     history.navigate(APP_URLS.LOGIN);
// //   }
// //   return response.data;
// // };

// const handleResponse = async (
//   response: any,
//   navigation: any,
//   retryFn: Function,
//   setShowPopup?: Function,
// ) => {
//   if (response?.status === 401) {
//     const refreshToken = await getRefreshToken();

//     if (refreshToken) {
//       try {
//         // Try to get new access token
//         const refreshResponse = await axios.post(
//           `${API_BASE_URL}/api/token/refresh/`,
//           { refresh: refreshToken },
//           { headers: { 'Content-Type': 'application/json' } }
//         );

//         if (refreshResponse.data.access) {
//           // Save new access token
//           setTokens({
//             access: refreshResponse.data.access,
//             refresh: refreshToken
//           });

//           // Retry the original request with new token
//           try {
//             const newResponse = await retryFn();
//             return newResponse?.data;
//           } catch (retryError) {
//             console.error("Retry failed after token refresh:", retryError);
//             throw retryError;
//           }
//         }
//       } catch (refreshError) {
//         // Only logout if refresh token is invalid/expired
//         console.error("Token refresh failed:", refreshError);
//         if ((refreshError as any)?.response?.status === 401) {
//           removeTokens();
//           window.location.href = '/login';
//         }
//         throw refreshError;
//       }
//     }
//   }

//   // For non-401 responses or if no refresh token
//   return response?.data;
// };

// /**
//  * Make a GET request to the API
//  */
// export const get = async function (
//   url: string,
//   noHeaders = false,
//   contentType?: string,
//   queryParams?: QueryParams,
//   setShowPopup?: Function,
// ) {
//   const headers = noHeaders ? {} : generateHeaders(contentType);
//   const urlObj = new URL(API_BASE_URL + url);

//   // Set default page size and provided query params
//   urlObj.searchParams.set("page_size", PAGE_SIZE.toString());
//   if (queryParams) {
//     Object.keys(queryParams).forEach((key) => {
//       const val = queryParams[key];
//       if (val) {
//         urlObj.searchParams.set(key, val);
//       }
//     });
//   }
//   const retryFn = async () => {
//     try {
//       console.log('RETRY_LOGS', urlObj.toString(), '::', headers);
//       const response = await axios.get(urlObj.toString(), headers);

//       return response;
//     } catch (error: unknown) {
//       console.log('RETRRY_ERR', (error as any).config);
//       throw error;
//     }
//   };
//   try {
//     const response = await retryFn();
//     return handleResponse(response, null, retryFn, setShowPopup);
//   } catch (err: any) {
//     throw handleResponse(err.response, null, retryFn, setShowPopup);
//   }
// };

// /**
//  * Make a POST request to the API
//  */
// export const post = async function (
//   url: string,
//   body: any,
//   noHeaders = false,
//   contentType?: string,
//   setShowPopup?: Function,
// ) {
//   const headers = noHeaders ? {} : generateHeaders(contentType);
//   const retryFn = async () =>
//     await axios.post(API_BASE_URL + url, body, headers);
//   try {
//     const response = await retryFn();
//     return handleResponse(response, null, retryFn, setShowPopup);
//   } catch (err: any) {
//     throw handleResponse(err.response,null, retryFn, setShowPopup);
//   }
// };

// /**
//  * Make a PUT request to the API
//  */
// export const put = async function (
//   url: string,
//   body: any,
//   noHeaders = false,
//   contentType?: string,
//   setShowPopup?: Function,
// ) {
//   const headers = noHeaders ? {} : generateHeaders(contentType);

//   const retryFn = () => axios.put(API_BASE_URL + url, body, headers);

//   try {
//     const response = await retryFn();
//     return handleResponse(response, null, retryFn, setShowPopup);
//   } catch (err: any) {
//     throw handleResponse(err.response, null, retryFn, setShowPopup);
//   }
// };

// /**
//  * Make a PATCH request to the API
//  */
// export const patch = async function (
//   url: string,
//   body: any,
//   noHeaders = false,
//   contentType?: string,
//   setShowPopup?: Function, // Pass this to trigger the popup

// ) {
//   const headers = noHeaders ? {} : generateHeaders(contentType);
//   const retryFn = () => axios.patch(API_BASE_URL + url, body, headers);
//   try {
//     const response = await  retryFn();
//     return handleResponse(response, null, retryFn, setShowPopup);
//   } catch (err: any) {
//     throw handleResponse(err.response, null, retryFn, setShowPopup);
//   }
// };

// /**
//  * Make a DELETE request to the API
//  */
// export const deleteRequest = async function (
//   url: string,
//   noHeaders = false,
//   contentType?: string,
//   setShowPopup?: Function,
// ) {
//   const headers = noHeaders ? {} : generateHeaders(contentType);
//   const retryFn = () => axios.delete(API_BASE_URL + url, headers);
//   try {
//     const response = await axios.delete(API_BASE_URL + url, headers);
//     return handleResponse(response, null, retryFn, setShowPopup);
//   } catch (err: any) {
//     throw handleResponse(err.response, null, retryFn, setShowPopup);
//   }
// };
/**
 * Send notifications to admin through two Lambda endpoints when server is down
 */
const sendNotificationToLambda = async () => {
  const lambdaUrl1 = "https://bvcbzxl640.execute-api.us-west-2.amazonaws.com/prod/send-notification-smtp";
  const lambdaUrl2 = "https://bvcbzxl640.execute-api.us-west-2.amazonaws.com/prod/ses-sns";

  try {
    const response1 = await axios.get(lambdaUrl1);
    console.log("Admin notified about server down through SMTP endpoint.", response1);

    const response2 = await axios.get(lambdaUrl2);
    console.log("Admin notified about server down through SES-SNS endpoint.", response2);
  } catch (error) {
    console.error("Failed to notify admin through one or both endpoints", error);
  }
};
