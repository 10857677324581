import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import NeuralNetworksTable from "components/neuralNetworks/NeuralNetworksTable";
import Header from "components/common/header";

/**
 * Neural Networks page
 */
export default function NeuralNetworks() {
  useEffect(() => {
    document.title = "Neural Networks | BrainCargo";
  }, []);

  return (
    <PageWrapper>
      <div className="border shadow-md lg:rounded-lg bg-white pb-6 space-y-2" aria-label="Neural Networks Page">
        <Header title="Neural Networks" />

        <div className="px-6 space-y-2">
          <p>
            Below you will find a list of all neural networks uploaded to the
            BrainCargo database. You can upload new neural networks and select
            existing ones in order to use them in other parts of the
            application.
          </p>
          <NeuralNetworksTable headerText="" />
        </div>
      </div>
    </PageWrapper>
  );
}
