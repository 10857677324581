import { Bars3Icon } from "@heroicons/react/24/solid";
import FileUpload from "components/common/file/FileUpload";
import Modal from "components/common/modal";
import YoutubeIcon from "components/icons/YoutubeIcon";
import { displayErrors } from "helpers/errors";
import { APP_URLS } from "navigation";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { isDispatchResponseError } from "redux/utils";
import {
  getYoutubeConnectionUrl,
  uploadGoogleTakeout,
} from "redux/youtube/youtubeSlice";

/**
 * A component that allows the user to connect their Youtube account.
 * It also displays the status of the connection.
 */
export default function YoutubeConnection() {
  const [showGoogleTakeoutModal, setShowGoogleTakeoutModal] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const user = useAppSelector((state) => state.user.user);
  const errorMessages = useAppSelector((state) => state.youtube.errorMessages);
  const pendingUpload = useAppSelector((state) => state.youtube.pending);
  const dispatch = useAppDispatch();

  /**
   * Redirects the user to the Google authorization page.
   */
  async function redirectToGoogleLogin() {
    const response = await dispatch(getYoutubeConnectionUrl());

    if (!isDispatchResponseError(response)) {
      window.open(response.payload as string);
    }
  }

  /**
   * Toggles the modal.
   */
  async function toggleGoogleTakeoutModal(e: React.MouseEvent) {
    e.stopPropagation();
    setShowGoogleTakeoutModal(!showGoogleTakeoutModal);
  }

  /**
   * Uploads the Google Takeout archive.
   */
  async function handleSubmitTakeout() {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const response = await dispatch(uploadGoogleTakeout(formData));
      if (!isDispatchResponseError(response)) {
        setShowSuccessMessage(true);
      } else {
        setShowSuccessMessage(false);
      }
    }
  }

  /**
   * Closes the modal and resets the state.
   */
  function handleCloseModal() {
    setFile(null);
    setShowSuccessMessage(false);
    setShowGoogleTakeoutModal(false);
  }

  return (
    <>
      <button
        className="flex justify-center space-x-2 items-center p-2 bg-red-100 rounded-full border-2 border-red-400 hover:cursor-pointer hover:bg-red-200 select-none"
        onClick={redirectToGoogleLogin}
      >
        <YoutubeIcon className="w-6 h-6" />
        <span className="text-gray-600 text-sm font-semibold">
          {user?.is_youtube_connected ? "Connected" : "Connect with Youtube"}
        </span>
        {user?.is_youtube_connected && (
          <button onClick={toggleGoogleTakeoutModal}>
            <Bars3Icon className="w-6 h-6 rounded hover:bg-red-300" />
          </button>
        )}
      </button>
      <Modal
        show={showGoogleTakeoutModal}
        onClose={handleCloseModal}
        headerText="Upload Google Takeout Archives"
        onSubmit={handleSubmitTakeout}
        submitButtonDisabled={!file || pendingUpload}
        submitButtonPending={pendingUpload}
        body={
          <div className="space-y-4">
            {showSuccessMessage ? (
              <>
                <div className="text-green-500 font-bold">
                  Upload successful!
                </div>
              </>
            ) : (
              <>
                <div>
                  Please upload your Google Takeout archives so that BrainCargo
                  can view your Youtube and Youtube Music watch and search
                  history.
                </div>
                <div>
                  Confused about generating the archives?
                  <br />
                  <a
                    href={APP_URLS.GUIDES_GOOGLE_TAKEOUT}
                    className="text-blue-600 hover:underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here to see a guide.
                  </a>
                </div>
                <FileUpload
                  file={file}
                  setFile={setFile}
                  maxSize="2 GB"
                  inputId="youtube-takeout-upload"
                />
                {displayErrors(errorMessages.file)}
              </>
            )}
          </div>
        }
      ></Modal>
    </>
  );
}
