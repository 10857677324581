import React from "react";
import { Button, Spinner } from "flowbite-react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import Modal from ".";

interface Props {
  title: string;
  show: boolean;
  pendingConfirm: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

/**
 * Modal used for confirming actions.
 */
export default function ConfirmationPopup({
  title,
  show,
  pendingConfirm,
  onClose,
  onConfirm,
}: Props) {
  return (
    <React.Fragment>
      <Modal
        show={show}
        onClose={onClose}
        hideFooter
        body={
          <div className="text-center">
            <ExclamationCircleIcon
              className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"
              aria-hidden="true"
            />
            <h3
              className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"
              aria-label="Confirmation Message"
            >
              {title}
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={onConfirm}
                aria-label="Confirm Action"
              >
                {pendingConfirm ? <Spinner size="sm" aria-label="Loading" /> : "Yes, I'm sure"}
              </Button>
              <Button
                color="gray"
                onClick={onClose}
                aria-label="Cancel Action"
              >
                No, cancel
              </Button>
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
}
