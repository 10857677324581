import React from "react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

/**
 * Payment success page
 */
export default function PaymentSuccess() {
  return (
    <div className="flex justify-center items-center m-auto h-screen">
      <div className="flex flex-col justify-center items-center border-2 border-green-500 rounded-xl p-8">
        <CheckCircleIcon className="text-green-500 w-20 h-20" />
        <div className="text-green-500 text-2xl font-bold">
          Payment Successful
        </div>
        <div className="text-gray-500 text-base font-semibold">
          Thank you for your purchase
        </div>
        <a
          href="/profile"
          className="text-blue-500 text-base font-semibold mt-4"
        >
          Return to profile page
        </a>
      </div>
    </div>
  );
}
