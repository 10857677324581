import SpotifyIcon from "components/icons/SpotifyIcon";
import React from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getSpotifyConnectionUrl } from "redux/spotify/spotifySlice";
import { isDispatchResponseError } from "redux/utils";

/**
 * A component that allows the user to connect their Spotify account.
 * It also displays the status of the connection.
 */
export default function SpotifyConnection() {
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();

  /**
   * Redirects the user to the Spotify authorization page.
   */
  async function redirectToSpotifyLogin() {
    const response = await dispatch(getSpotifyConnectionUrl());

    // Redirect user to Spotify authorization page
    if (!isDispatchResponseError(response)) {
      window.open(response.payload as string);
    }
  }

  return (
    <button
      className="flex justify-center space-x-2 items-center p-2 bg-green-100 rounded-full border-2 border-green-400 hover:cursor-pointer hover:bg-green-200 select-none"
      onClick={redirectToSpotifyLogin}
    >
      <SpotifyIcon className="w-6 h-6" />
      <span className="text-gray-600 text-sm font-semibold">
        {user?.is_spotify_connected ? "Connected" : "Connect with Spotify"}
      </span>
    </button>
  );
}
