import React from "react";
import { FeedItem } from "types/feed";

interface Props {
  track: FeedItem;
}

/**
 * A track feed item.
 */
export default function Track({ track }: Props) {
  return (
    <div className="flex space-x-4">
      <div className="flex-shrink-0">
        <img
          className="h-[100px] w-[100px] rounded"
          src={track.image || ""}
          alt="Music thumbnail"
        />
      </div>
      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium text-gray-900 truncate">
          {track.title}
        </p>
      </div>
    </div>
  );
}
