import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import MyPlaylistList from "components/playlist/MyPlaylistList";
import SharedPlaylistList from "components/playlist/SharedPlaylistList";
import { getIsSmScreen } from "helpers/responsiveness";
import { useIsSmScreen } from "hooks/useIsSmScreen";
import { APP_URLS } from "navigation";

/**
 * Playlists page for desktop
 */
export default function PlaylistsDesktop() {
  const isSmScreen = useIsSmScreen();

  useEffect(() => {
    document.title = "Playlists | BrainCargo";
  }, []);

  if (getIsSmScreen() || isSmScreen) {
    // Force the user to mobile-compatible videos view
    window.location.href = APP_URLS.PLAYLISTS_MOBILE;
  }

  return (
    <PageWrapper>
      <div className="overflow-x-auto border shadow-md sm:rounded-lg bg-white" aria-label="Playlists Container">
        <MyPlaylistList />
        <SharedPlaylistList />
      </div>
    </PageWrapper>
  );
}
