import { HandThumbUpIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { LIKE_POPUP_DURATION_MS, TRANSITION_STYLE_PROPERTY } from "../utils";

interface Props {
  showLikePopup: boolean;
}

/**
 * Component for displaying a popup when a user likes a feed item.
 */
export default function LikePopup({ showLikePopup }: Props) {
  const [display, setDisplay] = useState(false);
  const [opacity, setOpacity] = useState(false);

  /**
   * Displays the popup when the user likes a feed item and hides it after a LIKE_POPUP_DURATION_MS.
   */
  useEffect(() => {
    // LikePopup must be hidden from view & not block any items underneath it, so
    // its CSS display property has to be set with a delay
    const additionalDelayMS = 100;
    if (showLikePopup) {
      setDisplay(showLikePopup);

      setTimeout(() => {
        setOpacity(showLikePopup);
      }, additionalDelayMS);

      setTimeout(() => {
        setOpacity(!showLikePopup);
      }, LIKE_POPUP_DURATION_MS);

      setTimeout(() => {
        setDisplay(!showLikePopup);
      }, LIKE_POPUP_DURATION_MS + additionalDelayMS + additionalDelayMS);
    }
  }, [showLikePopup]);

  return (
    <div
      className={`absolute flex-col justify-center items-center transition-all ease-out space-y-2 overflow-hidden left-0 top-0 right-0 bottom-0 z-40 ${
        opacity ? "opacity-1" : "opacity-0"
      } ${display ? "flex" : "hidden"}`}
      style={{ ...TRANSITION_STYLE_PROPERTY }}
      aria-live="assertive"
      aria-atomic="true"
    >
      <HandThumbUpIcon
        className="text-slate-100 w-1/4 p-10 bg-green-500 rounded-full border-slate-100 border-4 box-content"
        aria-label="Like icon"
      />
      <span
        className="text-3xl text-slate-100 font-semibold tracking-wider drop-shadow-2xl"
        style={{
          textShadow:
            "-1px 0px 0px rgb(100 116 139), 1px 0px 0px rgb(100 116 139), 0px -1px 0px rgb(100 116 139), 0px 1px 0px rgb(100 116 139)",
        }}
      >
        Liked!
      </span>
    </div>
  );
}
