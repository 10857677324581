import { FeedItem } from "types/feed";
import React from "react";
import TMDBMovieVideo from "../common/TMDBMovieVideo";
import HorizontalDivider from "components/common/divider/HorizontalDivider";

interface Props {
  feedItem: FeedItem | null;
}

/**
 * Trailers and clips section for TMDBMovie component.
 */
export default function TMDBMovieTrailersClips({ feedItem }: Props) {
  if (!feedItem?.tmdb_data?.videos || feedItem?.tmdb_data?.videos?.length === 0)
    return null;

  return (
    <div className="flex flex-col gap-2">
      {feedItem.tmdb_data.videos.map((video, idx) => {
        const component = (
          <TMDBMovieVideo
            key={video.id}
            video={video}
            className="w-full h-40"
          />
        );
        if (idx === 0) {
          return component;
        } else {
          return (
            <>
              <HorizontalDivider />
              {component}
            </>
          );
        }
      })}
    </div>
  );
}
