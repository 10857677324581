import React, { useState } from "react";
import ModalEditCreatePlaylist from "components/playlist/ModalEditCreatePlaylist";
import { PlusCircleIcon } from "@heroicons/react/24/solid";

interface Props {
  additionalClasses?: string;
}

/**
 * Component used for creating a new playlist.
 */
export default function ButtonCreatePlaylist({
  additionalClasses = "",
}: Props) {
  const [showSelectPlaylistPopup, setShowSelectPlaylistPopup] =
    useState<boolean>(false);

  return (
    <>
      <button
        onClick={() => setShowSelectPlaylistPopup(true)}
        className={`flex p-2 text-center text-blue-600 bg-white border-[1.5px] border-blue-600 rounded-lg justify-center items-center ${additionalClasses}`}
        aria-label="Create Playlist"
      >
        <PlusCircleIcon className="w-5 h-5 mr-2" />
        <span className="text-sm font-semibold">Create Playlist</span>
      </button>

      <ModalEditCreatePlaylist
        show={showSelectPlaylistPopup}
        onClose={() => setShowSelectPlaylistPopup(false)}
      />
    </>
  );
}
