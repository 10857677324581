import React, { useState } from "react";
import {
  ChevronDoubleDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  FlagIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  HeartIcon,
  PlusIcon,
  ShareIcon,
} from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ReportFeedItemModal } from "components/report/ReportFeedItemModal";
import MobileBottomNavBarItem from "./MobileBottomNavBarItem";
import { isDispatchResponseError } from "redux/utils";
import { AddItemToPlaylistPayload } from "types/playlist";
import { addItemToPlaylists } from "redux/playlist/playlistSlice";
import {
  dislikeFeedItem,
  likeFeedItem,
  setCurrFeedItemIdx,
  setShowDislikePopup,
  setShowLikePopup,
  setShowPlaylistsDrawer,
  skipFeedItem,
} from "redux/feed/feedItemsSlice";
import MobileBottomNavBarExpandableItem from "./MobileBottomNavBarExpandableItem";
import MobileNavBarExpandableMenu from "../common/MobileNavBarExpandableMenu";
import ToBeImplementedPopup from "components/common/modal/ToBeImplementedPopup";

const ICON_CLASSNAME = "w-6 h-6 text-gray-500 transition duration-75";
export const MOBILE_FEED_BOTTOM_NAV_BAR_HEIGHT = "80px";

/**
 * The bottom navigation bar for MobileFeed.
 */
export default function MobileBottomNavBarFeed() {
  const dispatch = useAppDispatch();
  const [showReportModal, setShowReportModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [expandFeedMenu, setExpandFeedMenu] = useState(false);
  const currFeedItem = useAppSelector(
    (state) => state.feedItemReports.currFeedItem,
  );
  const feedItem = useAppSelector((state) => state.feedItems.feedItem);
  const defaultPlaylist = useAppSelector((state) =>
    state.playlist.myPlaylists.find((playlist) => playlist.is_default),
  );
  const showPlaylistsDrawer = useAppSelector(
    (state) => state.feedItems.showPlaylistsDrawer,
  );
  const currFeedItemIdx = useAppSelector(
    (state) => state.feedItems.currFeedItemIdx,
  );
  let likeTimeout: NodeJS.Timeout | null = null;
  let dislikeTimeout: NodeJS.Timeout | null = null;

  /**
   * Mark feed item as liked in the database.
   */
  const handleLikeFeedItem = async () => {
    // Mark item as liked in database
    if (feedItem) {
      const response = await dispatch(likeFeedItem(feedItem.id));
      if (!isDispatchResponseError(response)) {
        dispatch(setShowLikePopup(true));
        dispatch(setCurrFeedItemIdx(currFeedItemIdx + 1));
        if (likeTimeout) {
          clearTimeout(likeTimeout);
        }
        likeTimeout = setTimeout(() => {
          dispatch(setShowLikePopup(false));
        });

        handleAddToDefaultPlaylist();
      }
    }
  };

  /**
   * Mark feed item as disliked in the database.
   */
  const handleDislikeFeedItem = async () => {
    // Mark item as disliked in database
    if (feedItem) {
      const response = await dispatch(dislikeFeedItem(feedItem.id));
      if (!isDispatchResponseError(response)) {
        dispatch(setShowDislikePopup(true));
        dispatch(setCurrFeedItemIdx(currFeedItemIdx + 1));
        if (dislikeTimeout) {
          clearTimeout(dislikeTimeout);
        }
        dislikeTimeout = setTimeout(() => {
          dispatch(setShowDislikePopup(false));
        });
      }
    }
  };

  /**
   * Display previous feed item.
   */
  const handlePreviousFeedItem = async () => {
    if (currFeedItemIdx > 0) {
      dispatch(setCurrFeedItemIdx(currFeedItemIdx - 1));
    }
  };

  /**
   * Display next feed item.
   */
  const handleNextFeedItem = async () => {
    dispatch(setCurrFeedItemIdx(currFeedItemIdx + 1));
  };

  /**
   * Display more buttons.
   */
  const handleMore = async () => {
    setExpandFeedMenu(!expandFeedMenu);
  };

  /**
   * Open report feed item modal.
   */
  const handleReport = async () => {
    setShowReportModal(true);
  };

  /**
   * Open share modal.
   */
  const handleShare = async () => {
    setShowShareModal(true);
  };

  /**
   * Add feed item to default playlist.
   */
  async function handleAddToDefaultPlaylist() {
    // Add item to default playlist
    if (defaultPlaylist && feedItem) {
      // Prepare payload
      const data: AddItemToPlaylistPayload = {
        playlist_ids: [defaultPlaylist.id],
        feed_item_id: feedItem.id,
      };
      const response = await dispatch(addItemToPlaylists(data));
      if (!isDispatchResponseError(response)) {
        return true;
      }
    }
    return false;
  }

  return (
    <nav
      id="mobile-bottom-nav-bar-video-feed"
      className={
        "fixed bottom-0 left-0 z-50 w-screen bg-gray-50 border-t-2 border-gray-200"
      }
      aria-label="Mobile Bottom Navigation Bar"
    >
      <div className="grid grid-cols-7 py-2 gap-2">
        <MobileBottomNavBarItem
          onClick={handleLikeFeedItem}
          icon={<HandThumbUpIcon className={ICON_CLASSNAME} />}
          label="Like"
        />
        <MobileBottomNavBarItem
          onClick={handleDislikeFeedItem}
          icon={<HandThumbDownIcon className={ICON_CLASSNAME} />}
          label="Dislike"
        />
        <MobileBottomNavBarItem
          onClick={handlePreviousFeedItem}
          icon={<ChevronLeftIcon className={ICON_CLASSNAME} />}
          label="Previous"
        />
        <MobileBottomNavBarItem
          onClick={handleNextFeedItem}
          icon={<ChevronRightIcon className={ICON_CLASSNAME} />}
          label="Next"
        />
        <MobileBottomNavBarItem
          onClick={handleLikeFeedItem}
          icon={<HeartIcon className={ICON_CLASSNAME} />}
          label="Save"
        />
        <MobileBottomNavBarItem
          onClick={() => dispatch(setShowPlaylistsDrawer(!showPlaylistsDrawer))}
          icon={<PlusIcon className={ICON_CLASSNAME} />}
          label="Playlist"
        />
        <MobileBottomNavBarExpandableItem
          expanded={expandFeedMenu}
          onClick={handleMore}
          collapsedIcon={<EllipsisHorizontalIcon className={ICON_CLASSNAME} />}
          expandedIcon={<ChevronDoubleDownIcon className={ICON_CLASSNAME} />}
          label="More"
        />
      </div>

      <MobileNavBarExpandableMenu expanded={expandFeedMenu} gridCols={7}>
        <MobileBottomNavBarItem
          onClick={handleReport}
          icon={<FlagIcon className={ICON_CLASSNAME} />}
          label="Report"
        />
        <MobileBottomNavBarItem
          onClick={handleShare}
          icon={<ShareIcon className={ICON_CLASSNAME} />}
          label="Share"
        />
      </MobileNavBarExpandableMenu>

      {currFeedItem && (
        <ReportFeedItemModal
          feedItem={currFeedItem}
          show={showReportModal}
          setShow={setShowReportModal}
        />
      )}

      <ToBeImplementedPopup
        show={showShareModal}
        onSubmit={() => setShowShareModal(false)}
      />
    </nav>
  );
}
