import React, { useEffect, useState } from "react";
import { Label, Select, TextInput } from "flowbite-react";
import FileUpload from "components/common/file/FileUpload";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { displayErrors } from "helpers/errors";
import { addNeuralNetwork } from "redux/neuralNetworks/neuralNetworksSlice";
import { isDispatchResponseError } from "redux/utils";
import { NeuralNetworkCategory } from "types/neuralNetwork";
import Modal from "components/common/modal";

interface Props {
  show: boolean;
  onClose: () => void;
}

/**
 * Popup form for uploading a new Neural Network.
 */
export default function UploadNeuralNetworkFormPopup({ show, onClose }: Props) {
  const dispatch = useAppDispatch();
  const neuralNetworkCategories = useAppSelector(
    (state) => state.neuralNetworks.neuralNetworkCategories,
  );
  const errorMessages = useAppSelector(
    (state) => state.neuralNetworks.errorMessages,
  );
  const [name, setName] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const pendingAddNeuralNetwork = useAppSelector(
    (state) => state.neuralNetworks.pendingAddNeuralNetwork,
  );

  /**
   * Render the categories as options for the Select component.
   */
  const renderCategories = () => {
    return neuralNetworkCategories.map(
      (category: NeuralNetworkCategory, idx: number) => (
        <option key={idx} value={category.id}>
          {category.name}
        </option>
      ),
    );
  };

  /**
   * Submit the form.
   */
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    // Prepare form data
    const form_data = new FormData();
    form_data.append("name", name);
    form_data.append("category", category);
    if (file) {
      form_data.append("file", file, file.name);
    }

    // Submit Neural Network Create request
    const response = await dispatch(addNeuralNetwork(form_data));

    // Close the popup if Neural Network is successfully created
    if (!isDispatchResponseError(response)) {
      setName("");
      setCategory("");
      setFile(null);
      onClose();
    }
  };

  /**
   * Determine if the submit button should be disabled.
   */
  function isDisabled() {
    return !name || !category || !file;
  }

  /**
   * Pre-select the first category when they are loaded.
   */
  useEffect(() => {
    if (neuralNetworkCategories.length > 0) {
      setCategory(neuralNetworkCategories[0].id);
    }
  }, [neuralNetworkCategories]);

  return (
    <React.Fragment>
      <Modal
        show={show}
        onClose={onClose}
        onSubmit={handleSubmit}
        submitButtonDisabled={isDisabled()}
        submitButtonPending={pendingAddNeuralNetwork}
        body={
          <div className="space-y-4 px-4">
            <h3 className="text-xl font-medium text-gray-900">
              Add a new Neural Network
            </h3>

            <form onSubmit={handleSubmit} className="space-y-2">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Neural Network Name" />
                </div>
                <TextInput
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="My Convolutional Neural Network"
                  aria-label="Neural Network Name"
                />
                {displayErrors(errorMessages.name)}
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="category" value="Category" />
                </div>
                <Select
                  id="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  helperText="Select Category"
                  aria-label="Category"
                >
                  {renderCategories()}
                </Select>
                {displayErrors(errorMessages.category)}
              </div>
              <div>
                <div className="mb-2 block">
                  <Label value="Upload Neural Network file" />
                </div>
                <FileUpload file={file} setFile={setFile} aria-label="Upload Neural Network file" />
                {displayErrors(errorMessages.file)}
              </div>
            </form>
          </div>
        }
      />
    </React.Fragment>
  );
}
