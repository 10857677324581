import React from "react";
import { APP_URLS } from "navigation";
import QuizDetails from "pages/Quizzes/QuizDetails";

/**
 * Profile Quiz page
 */
export default function ProfileQuizPage() {
  const successMessage = (
    <>
      Thank you for taking part in the quiz!
      <br />
      You will be redirected to your profile page in 5 seconds.
    </>
  );

  return (
    <QuizDetails
      successRedirectUrl={APP_URLS.PROFILE}
      successRedirectDelayMs={5000}
      successMessage={successMessage}
    />
  );
}
