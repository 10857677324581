import { CheckoutSession } from "types/payments";
import NoImage from "../../images/No-Image.png";
import React from "react";
import { Button, Spinner } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { fetchCheckoutSessions, redeemNFT } from "redux/payments/paymentsSlice";
import { isDispatchResponseError } from "redux/utils";

interface Props {
  data: CheckoutSession;
}

/**
 * A component that displays an NFT product(CheckoutSession) and allows it to be clicked.
 */
export default function NFTRedeemableItem({ data }: Props) {
  const dispatch = useAppDispatch();
  const [isClicked, setIsClicked] = React.useState(false);
  const pendingFetchCheckoutSessions = useAppSelector(
    (state) => state.payments.pendingFetchCheckoutSessions,
  );
  const pendingRedeemNFT = useAppSelector(
    (state) => state.payments.pendingRedeemNFT,
  );

  function getImageSrc() {
    let image;
    if(data.nft_product.images){
      image = data.nft_product.images[0];
    }
    
    if (!image) {
      return NoImage;
    }
    return image.url;
  }

  async function handleRedeemNFT() {
    setIsClicked(true);
    const response = await dispatch(redeemNFT(data.id));
    if (!isDispatchResponseError(response)) {
      dispatch(fetchCheckoutSessions());
    }
    setIsClicked(false);
  }

  function renderBrainInfo() {
    if (!data.is_processed) {
      return (
        <>
          If you redeem this NFT, you will also receive{" "}
          <span className="font-medium">
            {data.nft_product.brain.toLocaleString()} BRAIN
          </span>
        </>
      );
    } else if (data.is_refunded) {
      return (
        <span className="font-medium">
          This purchase has been refunded due to an error on our end
        </span>
      );
    } else {
      return (
        <span className="font-medium">
          You have redeemed this NFT and received{" "}
          {data.nft_product.brain.toLocaleString()} BRAIN
        </span>
      );
    }
  }

  function generateOpenSeaLink() {
    let link = "";
    console.log("REDEEM",process.env.VITE_REACT_APP_OPENSEA_NFT_ADDRESS);
    if (process.env.VITE_REACT_APP_OPENSEA_NFT_ADDRESS && data.minted_nft_id) {
      link = process.env.VITE_REACT_APP_OPENSEA_NFT_ADDRESS + data.minted_nft_id;
    }

    return (
      <div>
        View on{" "}
        <a className="text-blue-600 font-medium" href={link}>
          OpenSea
        </a>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full p-2 rounded border-2 h-full gap-2">
      <img src={getImageSrc()} alt={data.nft_product.name} />
      <div className="font-medium text-center">{data.nft_product.name}</div>
      <div className="text-left">{renderBrainInfo()}</div>
      {data.minted_nft_id && (
        <div className="text-left mt-2">
          <div>NFT ID: {data.minted_nft_id}</div>
          {generateOpenSeaLink()}
        </div>
      )}
      <div className="text-left mt-2">{data.nft_product.description}</div>
      <Button
        className="mt-auto w-full"
        color="info"
        disabled={data.is_processed || (isClicked && pendingRedeemNFT)}
        title={data.is_processed ? "You've already redeemed this NFT" : ""}
        onClick={handleRedeemNFT}
      >
        {isClicked && pendingRedeemNFT ? (
          <div className="flex flex-col items-center justify-center">
            <div>
              <Spinner size="sm" />
            </div>
            <div className="text-sm mt-2">
              Please hold, this may take up to 30 seconds
            </div>
          </div>
        ) : (
          "Redeem"
        )}
      </Button>
    </div>
  );
}
