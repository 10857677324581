import React from "react";
import {setFeedFilterTypes} from "redux/feed/feedFilterTypesSlice";
import { useAppDispatch } from "redux/hooks";
import { FEED_ITEM_TYPES, FeedItemType } from "types/feed";

/**
 * Component that renders feed type selection menu in MobileBottomNavBarScrollableFeed.
 */
export default function MobileBottomNavBarFeedTypeSelection({
  feedFilterTypes,
}: {
  feedFilterTypes: FeedItemType[];
}) {
  const dispatch = useAppDispatch();

  function toggleType(type: FeedItemType) {
    let newFeedFilterTypes = [...feedFilterTypes];
    if (newFeedFilterTypes.includes(type)) {
      newFeedFilterTypes = newFeedFilterTypes.filter((item) => item !== type);
    } else {
      newFeedFilterTypes.push(type);
    }

    // There must always be at least one item selected
    if (newFeedFilterTypes.length === 0) {
      return;
    }

    dispatch(setFeedFilterTypes(newFeedFilterTypes));
  }

  function isTypeSelected(type: FeedItemType) {
    return feedFilterTypes.includes(type);
  }

  function renderButtons() {
    return Object.values(FEED_ITEM_TYPES).map((item) => {
      return (
        <button
          key={item}
          onClick={() => toggleType(item)}
          className={
            "flex capitalize px-4 py-2 text-center rounded-lg text-white " +
            (isTypeSelected(item) ? "bg-blue-600" : "bg-gray-300")
          }
          aria-label={`Toggle ${item} feed type`}
        >
          {item}
        </button>
      );
    });
  }

  return (
    <div className="flex flex-col justify-center col-span-5 border-b-2 py-2 gap-1">
      <div className="text-base text-center">
        Select which feed items should be shown:
      </div>
      <div className="flex flex-row justify-center items-center flex-wrap gap-4">
        {renderButtons()}
      </div>
    </div>
  );
}
