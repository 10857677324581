import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { updateUser } from "redux/user/userSlice";
import { retrieveQuizAnswers, retrieveQuizzes } from "redux/quiz/quizSlice";
import { fetchCheckoutSessions } from "redux/payments/paymentsSlice";
import PageWrapper from "components/common/pageWrapper";
import Header from "components/common/header";
import { Button, Spinner, ToggleSwitch, TextInput } from "flowbite-react";
import SpotifyConnection from "components/connections/SpotifyConnection";
import YoutubeConnection from "components/connections/YoutubeConnection";
import ShazamConnection from "components/connections/ShazamConnection";
import { useAccount, useDisconnect } from 'wagmi';
import { ethers } from "ethers";
import WagmiConnector from "components/brainCoin/WagmiConnection";
import SequenceWallet from "components/brainCoin/SequenceWallet";
import { sequence } from "0xsequence";
import NFTProductSelectionModal from "components/nft/NFTProductSelectionModal";
import NFTRedeemableItem from "components/nft/NFTRedeemableItem";
import { UserIcon } from "@heroicons/react/24/solid";
import { history } from "helpers/history";
import { APP_URLS } from "navigation";
import { Quiz } from "types/quiz";
import { removeTokens } from "services/localStorage";
import { formatDate } from "helpers/date";
import { deleteRequest, get, post } from "services/apiService";

/**
 * Profile page
 */

export default function Profile() {
  // Redux state
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const quizzes = useAppSelector((state) => state.quiz.quizzes);
  const quizAnswers = useAppSelector((state) => state.quiz.answers);
  const checkoutSessions = useAppSelector((state) => state.payments.checkoutSessions);
  const pendingRetrieveQuizzes = useAppSelector((state) => state.quiz.pendingRetrieveQuizzes);
  const pendingRetrieveQuizAnswers = useAppSelector((state) => state.quiz.pendingRetrieveQuizAnswers);
  const pendingFetchCheckoutSessions = useAppSelector((state) => state.payments.pendingFetchCheckoutSessions);

  // User related state
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableUser, setEditableUser] = useState({
    username: user?.username || "",
    email: user?.email || "",
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    is_touch_interface_enabled: user?.is_touch_interface_enabled || false,
  });

  // Quiz related state
  const [mainQuiz, setMainQuiz] = useState<Quiz | null>(null);

  // Wallet related state
  const [walletAddress, setWalletAddress] = useState("");
  const [balance, setBalance] = useState<string>("");
  const [generatedNonce, setGeneratedNonce] = useState<string>("");
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const { disconnect } = useDisconnect();
  const { isConnected: isWagmiWalletConnected, address: wagmiWalletAddress } = useAccount();

  // Sequence wallet state
  const [isSequenceWalletConnected, setIsSequenceWalletConnected] = useState(false);
  const [sequenceWalletAddress, setSequenceWalletAddress] = useState<string | undefined>(undefined);

  // NFT related state
  const [showNFTProductSelectionModal, setShowNFTProductSelectionModal] = useState(false);

  // Feature flag
  const FEATURE_FLAG = "true";

  // Add a new state for BrainCoin balance
  const [brainCoinBalance, setBrainCoinBalance] = useState<string>("");

  // Add this state at the top with other states
  const [isWalletLinked, setIsWalletLinked] = useState(false);

  // Add these states at the top
  const [isInitialized, setIsInitialized] = useState(false);

  // Add this state at the top with other states
  const [isLinking, setIsLinking] = useState(false);

  /**
   * Navigate to quiz page
   */
  function onClickTakeQuiz() {
    if (mainQuiz) {
      const url = APP_URLS.PROFILE_QUIZ.replace(":id", mainQuiz.id);
      history.navigate(url);
    }
  }

  /**
   * Render take/retake quiz button
   */
  function renderQuizButton() {
    let buttonContent;
    const pending = pendingRetrieveQuizAnswers || pendingRetrieveQuizzes;
    if (pending) {
      buttonContent = <Spinner size="sm" aria-label="Loading spinner" />;
    } else if (quizAnswers.length === 0) {
      buttonContent = "Take Quiz";
    } else {
      buttonContent = "Retake Quiz";
    }

    return (
      <div>
        <Button
          onClick={onClickTakeQuiz}
          size="md"
          disabled={pending}
          aria-label="Take or Retake Quiz"
        >
          {buttonContent}
        </Button>
        {quizAnswers.length !== 0 && (
          <span>Last taken: {formatDate(quizAnswers[0].updated_at || "")}</span>
        )}
      </div>
    );
  }

  /**
   * Log the user out
   */
  const onLogoutClick = () => {
    // Remove authentication tokens from local storage
    removeTokens();
    // Navigate user to Login page
    history.navigate(APP_URLS.LOGIN);
  };

  /**
   * Render checkout sessions representing redeemable NFTs
   */
  function renderNFTRedeemableItems() {
    console.log(
      "REDEEM_CHeck",
      checkoutSessions.length,
      "::",
      checkoutSessions
    );
    if (checkoutSessions.length == 0) {
      return (
        <div className="flex items-center justify-center">
          <span className="text-gray-500 text-base font-semibold">
            You don{"'"}t have any NFTs to redeem
          </span>
        </div>
      );
    }
    return checkoutSessions.map((checkoutSession, index) => (
      <NFTRedeemableItem data={checkoutSession} key={index} />
    ));
  }

  /**
   * Retrieve main quiz
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(retrieveQuizzes({ queryParams: { main_quiz: "true" } }));
      } catch (error) {
        console.error("Failed to retrieve quizzes:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  /**
   * Retrieve main quiz answers and extract main quiz data
   */
  useEffect(() => {
    if (quizzes.results.length > 0) {
      const newMainQuiz = quizzes.results[0];
      // Retrieve answers to the quiz
      dispatch(retrieveQuizAnswers({ quizId: newMainQuiz.id }));
      setMainQuiz(newMainQuiz);
    }
  }, [quizzes]);

  useEffect(() => {
    if (user) {
      setEditableUser({
        username: user.username || "",
        email: user.email || "",
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        is_touch_interface_enabled: user.is_touch_interface_enabled || false,
      });
    }
  }, [user]);

  /**
   * Handle touch interface toggle change
   */
  const handleTouchInterfaceChange = (isEnabled: boolean) => {
    setEditableUser((prev) => ({
      ...prev,
      is_touch_interface_enabled: isEnabled,
    }));
  };

  /**
   * Handle input changes
   */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditableUser((prev) => ({ ...prev, [name]: value }));
  };

  /**
   * Save changes
   */
  const handleSaveChanges = () => {
    toast.promise(dispatch(updateUser(editableUser)), {
      pending: "Saving changes...",
      success: "Changes saved successfully!",
      error: "Failed to save changes.",
    });
    setIsEditMode(false);
  };

  /**
   * Render either sequence or wagmi wallet connection component depending on the feature flag
   */
  function renderWalletConnection() {
    console.log(
      "FEATURE_FLAG",
      FEATURE_FLAG,
      "::",
      process.env.REACT_APP_API_BASE_URL
    );
    if (FEATURE_FLAG === "true") {
      return (
        <SequenceWallet
          isWalletConnected={isSequenceWalletConnected}
          setIsWalletConnected={setIsSequenceWalletConnected}
        />
      );
    } else {
      return <WagmiConnector />;
    }
  }

  /**
   * Retrieve Sequence wallet address
   */
  async function getSequenceWalletAddress() {
    if (isSequenceWalletConnected) {
      const wallet = sequence.getWallet();
      console.log("WALLET", wallet);
      return wallet.getAddress();
    }
  }

  /**
   * Retrieve sequence wallet address
   */
  useEffect(() => {
    if (isSequenceWalletConnected) {
      getSequenceWalletAddress().then((address) => {
        setSequenceWalletAddress(address);
      });
    } else {
      setSequenceWalletAddress("");
    }
  }, [isSequenceWalletConnected]);

  useEffect(() => {
    dispatch(fetchCheckoutSessions());
  }, []);

  async function getBalance(address: string) {
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const balanceWei = await provider.getBalance(address);
        const balanceEth = ethers.utils.formatEther(balanceWei);
        setBalance(parseFloat(balanceEth).toFixed(4));
      } catch (error) {
        console.error("Error getting balance:", error);
        setBalance("0");
      }
    }
  }

  useEffect(() => {
    if (walletAddress) {
      getBalance(walletAddress);
    } else {
      setBalance("");
    }
  }, [walletAddress]);

  // Add function to get BrainCoin balance
  const getBrainCoinBalance = async () => {
    try {
      console.log("Fetching BrainCoin balance...");
      const response = await get(
        "/api/payments/integrate-external-wallet/get_braincoin_balance/",
        false,
        "application/json"
      );
      console.log("BrainCoin balance API response:", response);
      
      // Make sure we're accessing the correct property from the response
      const balance = response?.data?.balance || response?.data;
      console.log("Parsed balance:", balance);
      
      if (balance !== undefined) {
        setBrainCoinBalance(balance.toString());
      } else {
        setBrainCoinBalance("undefined");
      }
    } catch (error) {
      console.error("Error fetching BrainCoin balance:", error);
      setBrainCoinBalance("undefined");
    }
  };

  // Add an effect to fetch balance when wallet is linked
  useEffect(() => {
    if (isWalletLinked && walletAddress) {
      getBrainCoinBalance();
    }
  }, [isWalletLinked, walletAddress]);

  // Add useEffect to check if wallet is linked when component mounts
  useEffect(() => {
    const checkWalletStatus = async () => {
      try {
        const response = await get("/api/accounts/current_user", false, "application/json");
        setIsWalletLinked(!!response?.eth_address);
      } catch (error) {
        console.error("Error checking wallet status:", error);
      }
    };

    checkWalletStatus();
  }, []);

  // Add the unlink wallet function
  const handleUnlinkWallet = async () => {
    try {
      await deleteRequest(
        "/api/payments/integrate-external-wallet/unlink_wallet/"
      );
      
      setIsWalletLinked(false);
      setBrainCoinBalance("");
      toast.success("Wallet unlinked successfully!");
    } catch (error) {
      console.error("Error unlinking wallet:", error);
      toast.error("Failed to unlink wallet");
    }
  };

  // Update the handleLinkWallet function to set isWalletLinked
  const handleLinkWallet = async () => {
    if (!user || !walletAddress) return;
    setIsLinking(true); // Start loading

    try {
      // Check if wallet is already linked to another user
      const response = await get("/api/accounts/current_user", false, "application/json");
      console.log("RESPONSE", response?.eth_address);
      const userEthAddress = response?.eth_address;
      
      if (userEthAddress && userEthAddress.toLowerCase() !== walletAddress.toLowerCase()) {
        toast.error("This wallet is already linked to another account");
        setIsLinking(false);
        return;
      }

      // Get nonce and proceed with linking
      const nonceResponse = await get(
        "/api/payments/integrate-external-wallet/generate_nonce/?change_address=true",
        false,
        "application/json"
      );
      const nonce = nonceResponse?.data;

      const signature = await window.ethereum.request({
        method: "personal_sign",
        params: [nonce, walletAddress],
      });

      await post(
        "/api/payments/integrate-external-wallet/link_wallet/?change_address=true",
        {
          signed_message: signature,
          eth_address: walletAddress,
        },
        false,
        "application/json"
      );

      // Add a small delay before checking the updated user data
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Fetch updated user data after linking wallet
      const updatedUserResponse = await get("/api/accounts/current_user", false, "application/json");
      if (updatedUserResponse?.eth_address) {
        setIsWalletLinked(true);
        await getBrainCoinBalance();
        toast.success("Wallet linked successfully!");
      } else {
        setIsWalletLinked(false);
        toast.error("Wallet linking failed - please try again");
      }
    } catch (error: any) {
      console.error("Wallet linking error:", error);
      setIsWalletLinked(false);
      if (error.code === 4001) {
        toast.error("You rejected the signature request. Please try again.");
      } else {
        toast.error(error.message || "Failed to link wallet");
      }
    } finally {
      setIsLinking(false); // End loading
    }
  };

  // Add this effect to initialize wallet states when user changes or logs in
  useEffect(() => {
    const initializeUserWallets = async () => {
      if (!user) {
        // Reset all wallet states when no user
        setWalletAddress("");
        setBalance("");
        setBrainCoinBalance("");
        setIsWalletLinked(false);
        setIsSequenceWalletConnected(false);
        setSequenceWalletAddress(undefined);
        return;
      }

      try {
        // Get user's current wallet status
        const response = await get("/api/accounts/current_user", false, "application/json");
        const userEthAddress = response?.eth_address;

        if (userEthAddress) {
          setIsWalletLinked(true);
          
          // Check if MetaMask is connected with correct address
          if (window.ethereum) {
            const accounts = await window.ethereum.request({
              method: "eth_accounts"
            });
            
            if (accounts?.[0]?.toLowerCase() === userEthAddress.toLowerCase()) {
              setWalletAddress(accounts[0]);
              const balance = await window.ethereum.request({
                method: "eth_getBalance",
                params: [accounts[0], "latest"],
              });
              setBalance(ethers.utils.formatEther(balance));
            }
          }
        }
      } catch (error) {
        console.error("Error initializing user wallets:", error);
      }
    };

    initializeUserWallets();
  }, [user]);

  // Update the sequence wallet effect to maintain connection
  useEffect(() => {
    if (isSequenceWalletConnected && user) {
      getSequenceWalletAddress().then((address) => {
        if (address) {
          setSequenceWalletAddress(address);
        }
      });
    }
  }, [isSequenceWalletConnected, user]);

  // Add event listener for MetaMask account changes
  useEffect(() => {
    if (window.ethereum && isWalletLinked) {
      const handleAccountsChanged = async (accounts: string[]) => {
        if (accounts.length === 0) {
          // User disconnected MetaMask
          setWalletAddress("");
          setBalance("");
          setBrainCoinBalance("");
        } else {
          // Account changed, verify if it matches linked wallet
          const response = await get("/api/accounts/current_user", false, "application/json");
          const userEthAddress = response?.data?.eth_address;
          
          if (userEthAddress?.toLowerCase() === accounts[0].toLowerCase()) {
            setWalletAddress(accounts[0]);
            await getBalance(accounts[0]);
            await getBrainCoinBalance();
          } else {
            setWalletAddress("");
            setBalance("");
            setBrainCoinBalance("");
            toast.error("Please connect with your linked wallet address");
          }
        }
      };

      window.ethereum.on('accountsChanged', handleAccountsChanged);
      return () => {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      };
    }
  }, [isWalletLinked]);

  // Handle MetaMask connection
  const connectMetaMask = async () => {
    if (!window.ethereum) {
      toast.error("MetaMask not detected. Please install MetaMask first.");
      window.open("https://metamask.io/download/", "_blank");
      return;
    }

    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      if (accounts && accounts.length > 0) {
        const account = accounts[0];
        
        // Check if this address is already linked to another user
        const response = await get("/api/accounts/current_user", false, "application/json");
        const userEthAddress = response?.eth_address;
        
        if (userEthAddress && userEthAddress.toLowerCase() !== account.toLowerCase()) {
          toast.error("This wallet is already linked to another account");
          return;
        }

        const balance = await window.ethereum.request({
          method: "eth_getBalance",
          params: [account, "latest"],
        });

        setWalletAddress(account);
        setBalance(ethers.utils.formatEther(balance));
        toast.success("MetaMask connected successfully!");
      }
    } catch (error: any) {
      console.error("MetaMask connection error:", error);
      if (error.code === 4001) {
        toast.error("You rejected the connection request. Please try again.");
      } else {
        toast.error(error.message || "Failed to connect MetaMask");
      }
    }
  };

  // Handle Sequence wallet connection
  const connectSequenceWallet = async () => {
    if (!user) return;

    try {
      const wallet = sequence.getWallet();
      const connectDetails = await wallet.connect({
        app: 'BrainCargo',
        authorize: true,
      });
      const address = connectDetails.session?.accountAddress;

      // Check if this address is already linked to another user
      const response = await get("/api/accounts/current_user", false, "application/json");
      const userEthAddress = response?.eth_address;
      
      if (userEthAddress && address && userEthAddress.toLowerCase() !== address.toLowerCase()) {
        toast.error("This wallet is already linked to another account");
        await wallet.disconnect();
        return;
      }

      setIsSequenceWalletConnected(true);
      setSequenceWalletAddress(address);
    } catch (error) {
      console.error("Sequence wallet connection error:", error);
      toast.error("Failed to connect Sequence wallet");
    }
  };

  return (
    <PageWrapper>
      <div className="flex flex-col  gap-4">
        {/* User Profile Section */}
        <div className="flex items-center justify-center gap-2">
          <div className="flex flex-col gap-2 justify-center items-center">
            <UserIcon
              className="w-20 h-20 p-4 box-content text-gray-600 bg-gray-200 rounded-full"
              aria-label="User Icon"
            />
            <Button
              onClick={onLogoutClick}
              size="md"
              color="failure"
              aria-label="Logout"
            >
              Logout
            </Button>
            <table className="mt-4 w-full lg:w-100%">
              <tbody>
                <tr className="border-gray-200 border-b-2">
                  <td className="py-2 w-1/2">
                    <span className="text-gray-500 text-base font-semibold mr-8">
                      Username:
                    </span>
                  </td>
                  <td className="py-2 w-1/2">
                    <TextInput
                      name="username"
                      value={editableUser.username}
                      onChange={handleInputChange}
                      disabled={!isEditMode}
                      aria-label="Username"
                    />
                  </td>
                </tr>

                <tr className="border-gray-200 border-b-2">
                  <td className="py-2 w-1/2">
                    <span className="text-gray-500 text-base font-semibold mr-8">
                      Email:
                    </span>
                  </td>
                  <td className="py-2 w-1/2">
                    <TextInput
                      name="email"
                      value={editableUser.email}
                      onChange={handleInputChange}
                      disabled={!isEditMode}
                      aria-label="Email"
                    />
                  </td>
                </tr>

                <tr className="border-gray-200 border-b-2">
                  <td className="py-2 w-1/2">
                    <span className="text-gray-500 text-base font-semibold mr-8">
                      First Name:
                    </span>
                  </td>
                  <td className="py-2 w-1/2">
                    <TextInput
                      name="first_name"
                      value={editableUser.first_name}
                      onChange={handleInputChange}
                      disabled={!isEditMode}
                      aria-label="First Name"
                    />
                  </td>
                </tr>

                <tr className="border-gray-200 border-b-2">
                  <td className="py-2 w-1/2">
                    <span className="text-gray-500 text-base font-semibold mr-8">
                      Last Name:
                    </span>
                  </td>
                  <td className="py-2 w-1/2">
                    <TextInput
                      name="last_name"
                      value={editableUser.last_name}
                      onChange={handleInputChange}
                      disabled={!isEditMode}
                      aria-label="Last Name"
                    />
                  </td>
                </tr>

                <tr className="border-gray-200 border-b-2">
                  <td className="py-2 w-1/2">
                    <span className="text-gray-500 text-base font-semibold mr-8">
                      Touch Interface:
                    </span>
                  </td>
                  <td className="py-2 w-1/2">
                    <ToggleSwitch
                      checked={editableUser.is_touch_interface_enabled}
                      onChange={handleTouchInterfaceChange}
                      disabled={!isEditMode}
                      aria-label="Touch Interface"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="mt-4 flex gap-2">
              <Button
                onClick={() => setIsEditMode(!isEditMode)}
                size="md"
                aria-label={isEditMode ? "Cancel Edit" : "Edit"}
              >
                {isEditMode ? "Cancel" : "Edit"}
              </Button>
              {isEditMode && (
                <Button
                  onClick={handleSaveChanges}
                  size="md"
                  color="success"
                  aria-label="Save Changes"
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>

       

        {/* Media Quiz Section */}
        <div className="flex flex-col gap-4">
          <Header title="Media preference quiz:" isSubHeader />
          <div className="flex flex-col content-center items-center justify-center p-4">
            <table className="w-full lg:w-1/2">
              <tbody>
                <tr className="border-gray-200 border-b-2">
                  <td className="py-2 w-1/2">
                    <span className="text-gray-500 text-base font-semibold mr-8">
                      Quiz:
                    </span>
                  </td>
                  <td className="py-2 w-1/2">
                    <span className="text-gray-500 text-base">
                      {renderQuizButton()}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Connected Accounts Section */}
        <div className="flex flex-col gap-4">
          <Header title="Connect Accounts" isSubHeader />
          <div className="flex flex-col content-center items-center justify-center p-4">
            <table className="w-full lg:w-1/2">
              <tbody>
                <tr className="border-gray-200 border-b-2">
                  <td className="py-2 w-1/2" align="left">
                    <span className="text-gray-500 text-base font-semibold">
                      Spotify
                    </span>
                  </td>
                  <td className="py-2 w-1/2" align="left">
                    <SpotifyConnection />
                  </td>
                </tr>
                <tr className="border-gray-200 border-b-2">
                  <td className="py-2 w-1/2" align="left">
                    <span className="text-gray-500 text-base font-semibold">
                      YouTube
                    </span>
                  </td>
                  <td className="py-2 w-1/2" align="left">
                    <YoutubeConnection />
                  </td>
                </tr>
                <tr className="border-gray-200 border-b-2">
                  <td className="py-2 w-1/2" align="left">
                    <span className="text-gray-500 text-base font-semibold">
                      Shazam
                    </span>
                  </td>
                  <td className="py-2 w-1/2" align="left">
                    <ShazamConnection />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


       <Header title="Wallet" isSubHeader />
        <div className="flex justify-center items-center p-4 text-center">
          <div className={"w-full sm:w-1/2"}>{renderWalletConnection()}</div>
        </div>


        {/* NFT Section */}
        <div className="flex flex-col gap-4">
          <Header title="NFT" isSubHeader />
          <div className="p-4 text-center">
            <span className="text-base">
              <div className="p-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center items-start flex-wrap gap-4">
                {renderNFTRedeemableItems()}
              </div>
              <div className="flex items-center justify-center">
                <Button
                  onClick={() => setShowNFTProductSelectionModal(true)}
                  disabled={!isSequenceWalletConnected && !isWagmiWalletConnected}
                  title={
                    !isSequenceWalletConnected && !isWagmiWalletConnected
                      ? "Please connect your Sequence wallet first"
                      : ""
                  }
                >
                  {showNFTProductSelectionModal ? (
                    <Spinner size="sm" />
                  ) : (
                    "Buy NFT"
                  )}
                </Button>
              </div>
              {isSequenceWalletConnected || isWagmiWalletConnected  ? (
                <NFTProductSelectionModal
                  show={showNFTProductSelectionModal}
                  setShow={setShowNFTProductSelectionModal}
                  walletAddress={
                    FEATURE_FLAG ? sequenceWalletAddress : wagmiWalletAddress
                  }
                />
              ):null}
            </span>
          </div>
        </div>

        {/* MetaMask Section */}
        <div className="flex flex-col gap-4">
          <Header title="MetaMask" isSubHeader />
          <div className="p-4 text-center">
            <div className="flex flex-col items-center justify-center gap-4">
            
              <Button 
                onClick={async () => {
                  if (walletAddress) {
                    try {
                      disconnect();
                      setWalletAddress("");
                      setBalance("");
                      setGeneratedNonce("");
                      toast.success("MetaMask disconnected successfully!");
                      return;
                    } catch (error) {
                      console.error("MetaMask disconnection error:", error);
                      toast.error("Failed to disconnect MetaMask");
                      return;
                    }
                  }

                  if (!window.ethereum) {
                    toast.error("MetaMask not detected. Please install MetaMask first.");
                    window.open("https://metamask.io/download/", "_blank");
                    return;
                  }

                  try {
                    const accounts = await window.ethereum.request({
                      method: "eth_requestAccounts",
                    });

                    if (accounts && accounts.length > 0) {
                      const account = accounts[0];
                      const balance = await window.ethereum.request({
                        method: "eth_getBalance",
                        params: [account, "latest"],
                      });

                      setWalletAddress(account);
                      setBalance(ethers.utils.formatEther(balance));
                      toast.success("MetaMask connected successfully!");
                    }
                  } catch (error: any) {
                    console.error("MetaMask connection error:", error);
                    if (error.code === 4001) {
                      toast.error("You rejected the connection request. Please try again.");
                    } else {
                      toast.error(error.message || "Failed to connect MetaMask");
                    }
                  }
                }}
                className={walletAddress ? "bg-red-600 hover:bg-red-700" : ""}
              >
                {walletAddress ? "Disconnect MetaMask" : "Connect MetaMask"}
              </Button>

              {walletAddress && (
                <Button
                  onClick={isWalletLinked ? handleUnlinkWallet : handleLinkWallet}
                  className={isWalletLinked ? "bg-red-600 hover:bg-red-700" : "bg-green-600 hover:bg-green-700"}
                  disabled={isLinking}
                >
                  {isLinking ? (
                    <Spinner size="sm" />
                  ) : (
                    isWalletLinked ? "Unlink Wallet" : "Link Wallet"
                  )}
                </Button>
              )}

              {walletAddress && (
                <div className="flex flex-col items-center gap-2">
                  <div className="text-sm">
                    <span className="font-semibold">Wallet Address: </span>
                    <span className="font-mono">
                      {walletAddress.slice(0, 6)}...{walletAddress.slice(-4)}
                    </span>
                  </div>
                  <div className="text-sm">
                    <span className="font-semibold">ETH Balance: </span>
                    <span className="font-mono">{balance} ETH</span>
                  </div>
                  <div className="text-sm">
                    <span className="font-semibold">BrainCoin Balance: </span>
                    <span className="font-mono">
                      {brainCoinBalance !== undefined ? `${brainCoinBalance} BRAIN` : 'Not available'}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <span className="text-gray-500 text-base font-semibold">
              Placeholder text
            </span>
          </div>
        </div>

        {/* AI Training Section */}
        <div className="flex flex-col gap-4">
          <Header title="AI Training" isSubHeader />
          <div className="p-4 text-center">
            <span className="text-gray-500 text-base font-semibold">
              Placeholder text
            </span>
          </div>
        </div>

        {/* More Integrations Section */}
        <div className="flex flex-col gap-4">
          <div className="text-center">
            <span className="text-gray-500 text-base font-semibold">
              More integrations on the way!
            </span>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
