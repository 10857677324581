import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ConfirmationPopup from "components/common/modal/ConfirmationPopup";
import Pagination from "components/common/pagination/Pagination";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { Spinner } from "flowbite-react";
import {
  deleteDataSet,
  getDataSets,
  selectDataSets,
} from "redux/dataSets/dataSetsSlice";
import UploadDataSetFormPopup from "./UploadDataSetFormPopup";
import { DataSet } from "types/dataset";

interface Props {
  headerText?: string;
  subHeaderText?: string;
  submitLabelText?: string;
  headerClassName?: string;
  afterDataSetSelect?: () => void;
  requireSelection?: boolean;
  singularSelection?: boolean;
}

/**
 * A component that allows the user to select datasets.
 */
export default function SelectDataSetsTable({
  headerText = "Select Datasets",
  subHeaderText,
  submitLabelText = "Submit Selection",
  headerClassName,
  afterDataSetSelect,
  requireSelection,
  singularSelection,
}: Props) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const dataSets = useAppSelector((state) => state.dataSets.dataSets);
  const pendingSelectDataSets = useAppSelector(
    (state) => state.dataSets.pendingSelectDataSets,
  );
  const pendingGetDataSets = useAppSelector(
    (state) => state.dataSets.pendingGetDataSets,
  );
  const pendingDeleteDataSet = useAppSelector(
    (state) => state.dataSets.pendingDeleteDataSet,
  );

  const [showConfirmationPopup, setShowConfirmationPopup] =
    useState<boolean>(false);
  const [showFormPopup, setShowFormPopup] = useState<boolean>(false);
  const [recordId, setRecordId] = useState<string>("");
  const [selectedDataSets, setSelectedDataSets] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);

  const onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    let newSelectedDataSets = [...selectedDataSets];
    if (singularSelection) {
      if (checked) {
        newSelectedDataSets = [id];
      } else {
        newSelectedDataSets = [];
      }
    } else {
      if (checked) {
        newSelectedDataSets.push(id);
      } else {
        newSelectedDataSets = newSelectedDataSets.filter(
          (datasetId) => datasetId !== id,
        );
      }
    }

    setSelectedDataSets(newSelectedDataSets);
  };

  /**
   * Displays the confirmation popup.
   */
  const onRemoveClick = (id: string) => {
    setRecordId(id);
    setShowConfirmationPopup(true);
  };

  /**
   * Deletes the dataset.
   */
  const onConfirmDelete = async () => {
    await dispatch(deleteDataSet(recordId));
    setRecordId("");
    setShowConfirmationPopup(false);
    setPage(0);
    dispatch(getDataSets({}));
  };

  /**
   * Submits the selected datasets.
   */
  const onSubmit = async () => {
    const data = {
      datasets: selectedDataSets,
    };
    const success = await dispatch(selectDataSets({ data }));

    // Run optional function
    if (success && afterDataSetSelect) {
      afterDataSetSelect();
    }
  };

  /**
   * Gets previous page of datasets.
   */
  const getPreviousPage = () => {
    dispatch(getDataSets({ url: dataSets.previous }));
    setPage(page - 1);
  };

  /**
   * Gets next page of datasets.
   */
  const getNextPage = () => {
    dispatch(getDataSets({ url: dataSets.next }));
    setPage(page + 1);
  };

  /**
   * Checks if the item was uploaded by the user.
   */
  function isItemUploadedByUser(item: DataSet) {
    return item.uploaded_by === user?.id;
  }

  /**
   * Maps datasets to table rows.
   */
  const displayRecords = () => {
    return dataSets.results.map((item: DataSet, idx: number) => (
      <tr
        key={idx}
        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      >
        <td className="w-4 p-4">
          <div className="flex items-center">
            <input
              id={item.id}
              type="checkbox"
              onChange={onSelect}
              checked={selectedDataSets.includes(item.id || "")}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
              aria-label={`Select ${item.name}`}
            />
            <label htmlFor={item.id} className="sr-only">
              Checkbox
            </label>
          </div>
        </td>
        <th scope="row" className="px-6 py-4 font-medium text-gray-900">
          <a href="#" className="text-blue-600 font-medium underline" aria-label={`Dataset ${item.name}`}>
            {item.name}
          </a>
        </th>

        <td className="px-6 py-4">
          <button
            onClick={() => onRemoveClick(item?.id || "")}
            className="font-medium enabled:text-red-600 enabled:hover:underline"
            disabled={!isItemUploadedByUser(item)}
            title={
              isItemUploadedByUser(item)
                ? undefined
                : "You can only delete your own datasets"
            }
            aria-label={`Remove ${item.name}`}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                onRemoveClick(item?.id || "");
              }
            }}
          >
            Remove
          </button>
        </td>
      </tr>
    ));
  };

  /**
   * Checks if the submit button should be disabled.
   */
  function isSubmitDisabled() {
    if (!requireSelection) return false;
    else return !selectedDataSets.length;
  }

  /**
   * Preselects the datasets that the user has already selected.
   */
  useEffect(() => {
    dispatch(getDataSets({}));

    let newSelectedDataSets: string[] = [];
    if (user?.assigned_datasets) {
      newSelectedDataSets = user.assigned_datasets;
    }
    setSelectedDataSets(newSelectedDataSets);
  }, [user]);

  return (
    <>
      <div
        className={
          headerClassName
            ? headerClassName
            : "flex flex-col sm:flex-row space-y-1 items-center bg-white"
        }
      >
        {(!!headerText || !!subHeaderText) && (
          <div className="flex flex-col">
            {headerText && (
              <span className="text-lg font-semibold text-gray-800 capitalize">
                {headerText}
              </span>
            )}
            {subHeaderText && (
              <span className="text-sm text-gray-500">{subHeaderText}</span>
            )}
          </div>
        )}

        <div className="flex flex-col sm:flex-row space-y-1 sm:space-y-0 space-x-0 sm:space-x-2 justify-items-center items-center bg-white ml-0 sm:ml-auto">
          <button
            onClick={() => setShowFormPopup(true)}
            className="flex items-center space-x-2 justify-center mx-0 sm:ml-auto pl-4 pr-5 py-2 text-center text-blue-600 bg-white border-[1.5px] border-blue-600 rounded-lg"
            aria-label="Add Dataset"
          >
            <PlusCircleIcon className="w-5 h-5" />
            <span className="text-sm font-semibold">Add Dataset</span>
          </button>

          <button
            onClick={onSubmit}
            disabled={isSubmitDisabled()}
            className={
              "flex px-4 py-2 text-center rounded-lg " +
              (isSubmitDisabled()
                ? "text-white bg-gray-300"
                : "text-white bg-blue-600")
            }
          >
            <span className="text-sm font-semibold">
              {pendingSelectDataSets ? <Spinner size="sm" aria-label="Loading spinner" /> : submitLabelText}
            </span>
          </button>
        </div>
      </div>

      {pendingGetDataSets ? (
        <div className="flex justify-center">
          <Spinner size="sm" aria-label="Loading spinner" />
        </div>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
            <tr>
              <th scope="col"></th>
              <th scope="col" className="px-6 py-3">
                Dataset Name
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>{displayRecords()}</tbody>
        </table>
      )}

      <Pagination
        count={dataSets.count}
        page={page}
        previous={dataSets.previous}
        getPreviousPage={getPreviousPage}
        next={dataSets.next}
        getNextPage={getNextPage}
      />

      <ConfirmationPopup
        title="Are you sure you want to delete this dataset?"
        show={showConfirmationPopup && !!recordId}
        onClose={() => setShowConfirmationPopup(false)}
        onConfirm={onConfirmDelete}
        pendingConfirm={pendingDeleteDataSet}
      />
      <UploadDataSetFormPopup
        show={showFormPopup}
        onClose={() => setShowFormPopup(false)}
      />
    </>
  );
}
