import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import Header from "components/common/header";
import downloadCsvImage from "./images/download-csv.jpeg";

const GUIDE_STEPS = [
  <>
    Open your Shazam library:{" "}
    <a
      href="https://www.shazam.com/myshazam/"
      className="text-blue-600 hover:underline"
      target="_blank"
      rel="noreferrer"
      aria-label="Shazam Library Page"
    >
      https://www.shazam.com/myshazam/
    </a>
  </>,
  <>
    Click <b>DOWNLOAD CSV</b>
    <div className="flex justify-center">
      <img src={downloadCsvImage} alt="Download CSV" />
    </div>
  </>,
  <>
    Wait until the download is complete. Once it&apos;s done, please upload the{" "}
    <b>shazamlibrary.csv</b> file to BrainCargo on your profile page under{" "}
    <b>Connect Accounts</b> tab.
  </>,
];

/**
 * Shazam library guide page.
 */
export default function ShazamLibraryGuide() {
  useEffect(() => {
    document.title = "Shazam Library | BrainCargo";
  }, []);

  /**
   * Renders a list of guide steps.
   */
  function renderGuideStepsList() {
    return (
      <ol className="space-y-4">
        {GUIDE_STEPS.map((elem, index) => (
          <li key={index} className="px-6 py-4 border-b" aria-label={`Step ${index + 1}`}>
            <div className="max-w-full sm:max-w-[1024px]">
              <span className="select-none">{index + 1}. </span>
              {elem}
            </div>
          </li>
        ))}
      </ol>
    );
  }

  return (
    <PageWrapper>
      <div className="overflow-x-auto border shadow-md sm:rounded-lg bg-white" aria-label="Shazam Library Guide Page">
        <Header title="Shazam Library Guide" />
        <div>
          {renderGuideStepsList()}
        </div>
      </div>
    </PageWrapper>
  );
}
