import { StripeNFTProduct } from "types/payments";
import NoImage from "images/No-Image.png";
import React from "react";

interface Props {
  data: StripeNFTProduct;
  onClick: () => void;
  selected: boolean;
}

export default function NFTProductClickable({
  data,
  onClick,
  selected,
}: Props) {
  function getImageSrc() {
    return data.images[0] || NoImage;
  }

  function formatPrice(price: number) {
    return "$" + (price / 100).toLocaleString();
  }

  return (
    <div
      className={`${
        selected ? "bg-cyan-700 text-white" : ""
      } w-60 p-2 rounded hover:cursor-pointer`}
      onClick={onClick}
    >
      <img src={getImageSrc()} alt={data.name} />
      <div className="font-medium text-center">{data.name}</div>
      <div className="font-medium text-center">
        {formatPrice(data.price.unit_amount)}
      </div>
      <div className="text-left">
        If you buy this NFT, you will also receive{" "}
        <span className="font-medium">{data.brain.toLocaleString()} BRAIN</span>
      </div>
      <div className="text-left mt-2">{data.description}</div>
    </div>
  );
}
