import React from "react";
import { retrieveVideosDataset } from "redux/feed/feedDatasetSlice";
import { useAppSelector } from "redux/hooks";
import FeedDataTable from "../common/FeedDataTable";
import { generateYoutubeUrl } from "helpers/youtube";
import { APP_URLS } from "navigation";

/**
 * Component for displaying a list of video-related feed data.
 */
export default function FeedDataVideosList() {
  function onClickRow(itemId: string) {
    // Navigate to video details page
    window.location.href = APP_URLS.FEED_DATA_VIDEO_DETAILS.replace(
      ":videoId",
      itemId,
    );
  }

  return (
    <FeedDataTable
      headerText="Videos"
      defaultOrdering="title"
      paginatedList={useAppSelector((state) => state.feedDataset.videos)}
      fetchThunkFunction={retrieveVideosDataset}
      pending={useAppSelector(
        (state) => state.feedDataset.retrieveVideosDatasetPending,
      )}
      labels={{
        id: "ID",
        title: "Title",
        youtube_video_id: "Link",
        liked_by_count: "Likes",
        disliked_by_count: "Dislikes",
        watched_by_count: "Watches",
        skipped_by_count: "Skips",
      }}
      onClickRow={onClickRow}
      valueOverrides={{
        youtube_video_id: generateYoutubeUrl,
      }}
    />
  );
}
