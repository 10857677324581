import { jwtDecode } from "jwt-decode";
import { AuthTokens } from "types/auth";

/**
 * Set the access and refresh tokens in local storage
 */
export const setTokens = ({ access, refresh }: { access: string; refresh: string }) => {
  localStorage.setItem('accessToken', access);
  localStorage.setItem('refreshToken', refresh);
};

/**
 * Remove the access and refresh tokens from local storage
 */
export const removeTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

/**
 * Get the access token from local storage
 */
export const getAccessToken = () => localStorage.getItem('accessToken');

/**
 * Get the refresh token from local storage
 */
export const getRefreshToken = () => localStorage.getItem('refreshToken');

/**
 * Check if the access token is valid
 */
export const isTokenValid = (token: string | null) => {
  try {
    if (!token) return false;

    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decodedToken.exp) {
      return decodedToken.exp > currentTime;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
