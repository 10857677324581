import React, { useEffect, useState } from "react";
import BackgroundImage from "images/Landing/Landing-BG.png";
import CoinImage from "images/Landing/Landing-Coin.png";
import EddieImg from "images/Landing/eddie.gif";
import LinkedInIcon from "images/Landing/Landing-LinkedIn-Icon.png";
import DiscordIcon from "images/Landing/Landing-Discord-Icon.png";
import FacebookIcon from "images/Landing/Landing-Facebook-Icon.png";
import TwitterIcon from "images/Landing/Landing-Twitter-Icon.png";
import TelegramIcon from "images/Landing/Landing-Telegram-Icon.png";
import MaciejTruskolaskiImg from "images/Landing/Maciej-Truskolaski.jpeg";
import MichalLechImg from "images/Landing/Michał-Lech.jpg";
import ThomasLechImg from "images/Landing/Thomas-Lech.png";
import {
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowUpIcon,
} from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { submitEmail, submitPhone } from "redux/newsletter/newsletterSlice";
import { displayErrors } from "helpers/errors";
import { Spinner } from "flowbite-react";
import { isDispatchResponseError } from "redux/utils";
import { APP_URLS } from "navigation";

const WHITEPAPER_PDF_ADDRESS = "/White-Paper-BrainCargo-Draft-2.pdf#toolbar=0";
const PRIMARY_TEXT_COLOR_CLASS = "text-stone-200";
const SECONDARY_TEXT_COLOR_CLASS = "text-stone-300";
const TERTIARY_TEXT_COLOR_CLASS = "text-[#DDBC74]";
const TEAM_DATA = [
  {
    image: EddieImg,
    name: "Iron Maiden",
    jobTitle: "Founder",
    linkedIn: "",
    description:
      "I am a cutting-edge wizard\
        maintaining elite understanding of AI, MLOPS, DevSecOps, DevEx,\
        Automating CI/CD Workflows with Cloud deployment strategies.\
        With over twenty years experience as a Senior Software and DevOps Engineer.\
        ",
  },
  {
    image: MichalLechImg,
    name: "Michał Lech",
    jobTitle: "RooTXNET Founder",
    linkedIn: "https://www.linkedin.com/in/rootxnetdjango/",
    description:
      "Founder of RooTXNET and software engineer with over 12 years of commercial experience.\
        Michał has been passionate about computers and technology from a very young age.",
  },
  {
    image: ThomasLechImg,
    name: "Thomas Lech",
    jobTitle: "RooTXNET Co-Founder",
    linkedIn: "https://www.linkedin.com/in/thomas-lech-593090212/",
    description:
      "Manages team, keeps everything on track and makes sure we deliver code on time.\
        Specializes in web design, SEO and marketing.\
        Thomas likes pursing his interest in Artificial Intelligence in his spare time.",
  },
  {
    image: MaciejTruskolaskiImg,
    name: "Maciej Truskolaski",
    jobTitle: "Software Developer",
    linkedIn: "https://www.linkedin.com/in/maciej-truskolaski-7a786b15b/",
    description:
      "Maciej is an experienced full-stack developer with an inquisitive mindset.\
        Being a flexible member of the team, he's able to swiftly switch between ongoing projects and build upon existing foundations or raise his own.",
  },
];

/**
 * Landing page.
 */
export default function LandingPage() {
  const [email, setEmail] = useState("");
  const emailPending = useAppSelector((state) => state.newsletter.pendingEmail);
  const emailErrors = useAppSelector(
    (state) => state.newsletter.errorMessagesEmail,
  );
  const [phone, setPhone] = useState("");
  const phonePending = useAppSelector((state) => state.newsletter.pendingPhone);
  const phoneErrors = useAppSelector(
    (state) => state.newsletter.errorMessagesPhone,
  );
  const [newsletterSignupSuccess, setNewsletterSignupSuccess] = useState(false);
  const dispatch = useAppDispatch();

  /**
   * Renders team members.
   */
  function renderTeamContent() {
    return TEAM_DATA.map((item, idx) => (
      <div
        className="flex flex-col justify-center items-center gap-2"
        key={idx}
        aria-label={`Team Member ${item.name}`}
      >
        <img src={item.image} width="75%" className="rounded-full" alt={`${item.name} Image`} />
        <p
          className={`text-center text-2xl font-bold ${PRIMARY_TEXT_COLOR_CLASS} mt-8`}
        >
          {item.name}
        </p>
        <div className="flex flex-row items-center justify-center gap-2">
          <p
            className={`text-center text-xl font-extrabold ${TERTIARY_TEXT_COLOR_CLASS} scale-y-[0.8]`}
          >
            {item.jobTitle}
          </p>
          <a href={item.linkedIn} target="_blank" rel="noreferrer" aria-label={`${item.name} LinkedIn`}>
            <img src={LinkedInIcon} alt="LinkedIn Icon" />
          </a>
        </div>
        <p
          className={`text-center text-lg font-light ${SECONDARY_TEXT_COLOR_CLASS}`}
        >
          {item.description}
        </p>
      </div>
    ));
  }

  /**
   * Scrolls to the top of the page.
   */
  function scrollToTop() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  /**
   * Sign up for newsletter with email.
   */
  async function handleEmailSubmit() {
    const response = await dispatch(submitEmail(email));
    if (!isDispatchResponseError(response)) {
      setNewsletterSignupSuccess(true);
    }
  }

  /**
   * Sign up for newsletter with phone.
   */
  async function handlePhoneSubmit() {
    const response = await dispatch(submitPhone(phone));
    if (!isDispatchResponseError(response)) {
      setNewsletterSignupSuccess(true);
    }
  }

  useEffect(() => {
    document.title = "BrainCargo";
  }, []);

  return (
    <div style={{ backgroundImage: `url(${BackgroundImage})` }} id="top" aria-label="Landing Page">
      <br />
      <div className="float-right">
        <a
          href={APP_URLS.FEED_MOBILE}
          className="text-slate-100 rounded-sm py-2 px-6 font-medium"
          style={{ backgroundColor: "#9D8359" }}
          aria-label="Your AI"
        >
          Your AI
        </a>
      </div>
      <br />
      <div className="flow-root">
        <div className="flex sm:flex-row flex-col sm:items-start items-center sm:justify-center justify-stretch py-4 gap-2">
          <img src={CoinImage} height={300} width={300} alt="Coin Image" />
        </div>
      </div>

      <div>
        <div>
          <div className="flex flex-col p-4 sm:p-28 gap-2">
            <h1
              className={`text-4xl sm:text-8xl ${PRIMARY_TEXT_COLOR_CLASS} font-extrabold sm:w-2/3`}
            >
              BrainCargo <br />
              OWN YOUR AI
            </h1>
            <p
              className={`text-2xl sm:text-3xl ${SECONDARY_TEXT_COLOR_CLASS} sm:w-3/4`}
            >
              BrainCargo is the revolutionary platform that lets you own your AI
              and monetize your data. Have fun training your own AI and turn the
              fun into a source of income.
            </p>
            <div
              className={`flex flex-col sm:flex-row items-center gap-2 sm:gap-4 text-2xl sm:text-4xl ${TERTIARY_TEXT_COLOR_CLASS} font-bold`}
            >
              <a href="/login" aria-label="Sign Up">SIGN UP</a>
              <div>
                <ArrowRightIcon className="h-8 sm:h-12 hidden sm:inline" aria-hidden="true" />{" "}
                <ArrowDownIcon className="h-8 sm:h-12 sm:hidden" aria-hidden="true" />
              </div>
              <div>BUILD AI DATA</div>
              <div>
                <ArrowRightIcon className="h-8 sm:h-12 hidden sm:inline" aria-hidden="true" />
                <ArrowDownIcon className="h-8 sm:h-12 sm:hidden" aria-hidden="true" />
              </div>
              <div>EARN BRAINCOIN</div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-8 p-4 sm:p-28">
        <h1
          className={`text-4xl sm:text-6xl ${PRIMARY_TEXT_COLOR_CLASS} font-extrabold sm:w-2/3`}
        >
          BRAINCOIN is the token for Monetizing Your Data With AI
        </h1>
        <p
          className={`text-2xl sm:text-6xl ${TERTIARY_TEXT_COLOR_CLASS} sm:w-2/3 font-extrabold`}
        >
          Use AI to get custom recommendations for videos, music, news and more
          all while generating BRAINCOIN for every click making your daily
          activities into a source of income.
        </p>
        <div className="flex-1 h-[450px]">
          <iframe
            width="100%"
            className="h-[450px]"
            src={WHITEPAPER_PDF_ADDRESS}
            aria-label="Whitepaper PDF"
          ></iframe>
        </div>

        <p className={`text-xl ${PRIMARY_TEXT_COLOR_CLASS} font-bold`}>
          A WORD FROM THE FOUNDER
        </p>
        <div className="flex flex-col sm:flex-row gap-8">
          <div className="flex-1">
            <iframe
              width="100%"
              className="h-[450px]"
              src="https://www.youtube.com/embed/XHOmBV4js_E"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              aria-label="YouTube Video"
            ></iframe>
          </div>
          <p
            className={`text-2xl sm:text-4xl ${SECONDARY_TEXT_COLOR_CLASS} sm:w-2/3 text-center`}
          >
            Own your data & earn BRAINCOIN selling it
          </p>
        </div>
      </div>

      <div>
        <h1
          className={`text-4xl sm:text-6xl ${PRIMARY_TEXT_COLOR_CLASS} font-extrabold text-center`}
        >
          Team
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-24 p-8 sm:px-40 sm:py-16 flex-wrap">
          {renderTeamContent()}
        </div>
      </div>

      <div className=" bg-neutral-900 p-8 sm:px-28 sm:py-14">
        <div className="flex flex-col sm:flex-row sm:w-2/3 sm:gap-32">
          <div className="flex flex-col gap-8 text-sm">
            <h1
              className={`text-4xl sm:text-6xl ${PRIMARY_TEXT_COLOR_CLASS} font-extrabold`}
            >
              Join the AI Revolution
            </h1>
            {newsletterSignupSuccess ? (
              <p className={`${TERTIARY_TEXT_COLOR_CLASS}`}>
                Thank you for contacting us.
              </p>
            ) : (
              <>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col sm:flex-row sm:border-neutral-600 sm:bg-neutral-800 sm:border-[1px] rounded-lg items-center p-1 text-stone-200 gap-4">
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="john@example.com"
                      className="w-full focus-visible:outline-0 p-2 text-neutral-600 placeholder:text-neutral-600 flex-1 sm:bg-transparent sm:border-none border-neutral-600 bg-neutral-800 border-[1px] rounded-lg"
                      aria-label="Email Input"
                    ></input>
                    {emailPending ? (
                      <div className="h-full flex justify-center items-center bg-[#DDBC74] rounded-lg w-16">
                        <Spinner
                          size="sm"
                          className="text-neutral-900 w-full sm:w-auto px-4 sm:px-8"
                          aria-label="Loading Spinner"
                        />
                      </div>
                    ) : (
                      <input
                        type="button"
                        className={
                          "bg-[#DDBC74] rounded-lg h-9 font-extrabold px-4 sm:px-8 text-neutral-900 hover:cursor-pointer hover-gold-shadow w-full sm:w-auto"
                        }
                        onClick={handleEmailSubmit}
                        value="Submit"
                        aria-label="Submit Email"
                      />
                    )}
                  </div>
                  {emailErrors && displayErrors(emailErrors.email)}
                </div>
                <span className={"text-center text-neutral-600 sm:text-xl"}>
                  or
                </span>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col sm:flex-row sm:border-neutral-600 sm:bg-neutral-800 sm:border-[1px] rounded-lg items-center p-1 text-stone-200 gap-4">
                    <input
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="(123) 123-1234"
                      className="w-full focus-visible:outline-0 p-2 text-neutral-600 placeholder:text-neutral-600 flex-1 sm:bg-transparent sm:border-none border-neutral-600 bg-neutral-800 border-[1px] rounded-lg"
                      aria-label="Phone Input"
                    ></input>
                    {phonePending ? (
                      <div className="h-full flex justify-center items-center bg-[#DDBC74] rounded-lg w-16">
                        <Spinner
                          size="sm"
                          className="text-neutral-900 w-full sm:w-auto px-4 sm:px-8"
                          aria-label="Loading Spinner"
                        />
                      </div>
                    ) : (
                      <input
                        type="button"
                        className={
                          "bg-[#DDBC74] rounded-lg h-9 font-extrabold px-4 sm:px-8 text-neutral-900 hover:cursor-pointer hover-gold-shadow w-full sm:w-auto"
                        }
                        onClick={handlePhoneSubmit}
                        value="Submit"
                        aria-label="Submit Phone"
                      />
                    )}
                  </div>
                  {phoneErrors && displayErrors(phoneErrors.phone)}
                </div>
              </>
            )}

            <p className="text-xs text-neutral-600">
              © 2023 Braincargo.All Rights Reserved.
            </p>
          </div>

          <div
            className={`flex flex-col gap-4 sm:gap-8  ${PRIMARY_TEXT_COLOR_CLASS}`}
          >
            <h1 className={"text-2xl font-light mt-8 sm:mt-0"}>Socials</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-20 gap-y-6">
              <a
                className="flex flex-row gap-2 items-center hover:cursor-pointer hover-gold-shadow hover-gold-text"
                href="https://www.twitter.com"
                target="_blank"
                rel="noreferrer"
                aria-label="Twitter"
              >
                <img src={TwitterIcon} className="h-6 inline" alt="Twitter Icon" />
                Twitter
              </a>
              <a
                className="flex flex-row gap-2 items-center hover:cursor-pointer hover-gold-shadow hover-gold-text"
                href="https://www.discord.com"
                target="_blank"
                rel="noreferrer"
                aria-label="Discord"
              >
                <img src={DiscordIcon} className="h-6 inline" alt="Discord Icon" />
                Discord
              </a>
              <a
                className="flex flex-row gap-2 items-center hover:cursor-pointer hover-gold-shadow hover-gold-text"
                href="https://www.telegram.com"
                target="_blank"
                rel="noreferrer"
                aria-label="Telegram"
              >
                <img src={TelegramIcon} className="h-6 inline" alt="Telegram Icon" />
                Telegram
              </a>
              <a
                className="flex flex-row gap-2 items-center hover:cursor-pointer hover-gold-shadow hover-gold-text"
                href="https://www.facebook.com"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <img src={FacebookIcon} className="h-6 inline" alt="Facebook Icon" />
                Facebook
              </a>
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={scrollToTop}
        className="fixed bottom-8 right-8 bg-[#DDBC74] rounded p-2"
        aria-label="Scroll to Top"
      >
        <ArrowUpIcon className="h-6" aria-hidden="true" />
      </button>
    </div>
  );
}
