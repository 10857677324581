import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  checkEmail,
  generateLoginOTP,
  googleRegister,
  login,
  register,
} from "redux/auth/authSlice";
import { history } from "helpers/history";
import { getAccessToken } from "services/localStorage";
import { APP_URLS } from "navigation";
import CoinImage from "images/Landing/Landing-Coin.png";
import { GoogleLoginPayload } from "../../types/auth";
import { GoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import TextInput from "../../components/common/form/TextInput";
import "react-international-phone/style.css";
import OTPLogin from "./OTPLogin";
import CustomPhoneInput from "../../components/common/form/CustomPhoneInput";
import { isPossiblePhoneNumber } from "libphonenumber-js";
import { To } from "react-router-dom";

const PRIMARY_TEXT_COLOR_CLASS = "text-stone-200";

/**
 * Login page.
 */
export default function Login() {
  const dispatch = useAppDispatch();

  const loginOtpErrorMessage = useAppSelector(
    (state) => state.auth.generateOTPErrorMessages
  );
  const registerErrorMessage = useAppSelector(
    (state) => state.auth.registerErrorMessages
  );

  const loginErrorMessage = useAppSelector(
    (state) => state.auth.loginErrorMessages
  );

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [isSignInFieldEmail, setIsSignInFieldEmail] = useState(true);
  const [showOTPField, setShowOTPField] = useState(false);

  const [showOTPForm, setShowOTPForm] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);

  const [userExists, setUserExists] = useState(false);
  const [agreed, setAgreed] = useState(false);
  /**
   * Log the user in.
   */
  const handleLogin = async () => {
    try {
      await toast.promise(
        dispatch(login({ email, password })).unwrap(),
        {
          pending: "Logging in...",
          success: "Logged In Successfully",
          error: {
            render({ data }: any) {
              return data?.detail || "Login Failed";
            },
          },
        },
        {
          autoClose: 5000,
        }
      );
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const responseMessage = (response: any) => {
    const credential = response?.credential;
    const body: GoogleLoginPayload = {
      auth_token: credential,
    };
    dispatch(googleRegister(body));
  };

  const handleRequestCode = async () => {
    try {
      const payload = isSignInFieldEmail ? { email } : { phone };
      const response = await toast.promise(
        dispatch(generateLoginOTP(payload)).unwrap(),
        {
          pending: "Sending OTP...",
          success: {
            render({ data }: any) {
              return data?.message || "OTP Sent";
            },
          },
          error: {
            render({ data }: any) {
              return data?.detail || "OTP Sending Failed";
            },
          },
        },
        {
          autoClose: 5000,
        }
      );
      if (response?.status === 200 || response?.status == 201) {
        if (response.status === 201) {
          setOtpToken(response?.token || "");
        }
        setShowOTPField(true);
        setShowOTPForm(true);
      }

      return response;
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleRegister = async () => {
    const registrationPromise = dispatch(
      register({ email, password1: password })
    ).unwrap();
    try {
      const response = await toast.promise(
        registrationPromise,
        {
          pending: "Registration in Progress",
          success: {
            render({ data }: any) {
              return data?.message || "Registration Successful";
            },
          },
          error: {
            render({ data }: any) {
              return data?.detail || "Registration Failed. Please try again.";
            },
          },
        },
        {
          autoClose: 5000,
        }
      );
    } catch (error) {
      console.error("Registration error:", error);
    }
  };

  const handleEmailCheck = async () => {
    const { password_is_set, user_exists } = await dispatch(
      checkEmail({ email })
    ).unwrap();
    setUserExists(user_exists);

    if (password_is_set || !user_exists) {
      setShowPasswordField(true);
    } else {
      await handleRequestCode();
      setShowOTPForm(true);
    }
  };

  const handleLoginOrRegister = async () => {
    if (userExists) {
      await handleLogin();
    } else {
      await handleRegister();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isSignInFieldEmail && !isPossiblePhoneNumber(phone)) {
      setPhoneError("Please Enter a Valid Phone Number");
      return;
    }

    setPhoneError("");

    if (!agreed) {
      alert("You must agree to the Privacy Policy and Terms of Service.");
      return;
    }

    if (isSignInFieldEmail) {
      if (showPasswordField) {
        await handleLoginOrRegister();
      } else {
        await handleEmailCheck();
      }
    } else {
      await handleRequestCode();
    }
  };

  useEffect(() => {
    document.title = "Login | BrainCargo";
    const accessToken = getAccessToken();
    if (accessToken) {
      history.navigate(APP_URLS.LANDING_PAGE);
    }
  }, []);

  const handlePolicyNavigation = (url: string | URL | undefined) => {
    // Use window.open for external links
    window.open(url, "_blank");
  };

  const renderInputField = () => {
    if (isSignInFieldEmail) {
      return (
        <TextInput
          label="Email"
          id="email"
          type="email"
          required
          onChange={(event) => setEmail(event.target.value)}
          placeholder="example@exaple.com"
          value={email}
          readOnly={showPasswordField}
        />
      );
    }

    return (
      <CustomPhoneInput
        value={phone}
        label="Phone Number"
        id="phone"
        type="text"
        required
        onChangePhone={(phone) => {
          setPhone(phone);
        }}
        readOnly={showOTPField}
        placeholder="Phone"
        errorMessages={loginOtpErrorMessage?.phone || phoneError}
      />
    );
  };

  const renderPasswordField = () => {
    if (!showPasswordField) return null;

    return (
      <>
        <TextInput
          label={userExists ? "Password" : "Set Password"}
          value={password}
          id="password"
          type="password"
          required
          onChange={(event) => setPassword(event.target.value)}
          placeholder="***********"
          errorMessages={
            !userExists
              ? registerErrorMessage?.non_field_errors ||
                registerErrorMessage?.password1
              : loginErrorMessage?.detail
          }
        />
        <div className="flex flex-row items-center justify-between">
          {userExists && (
            <div
              onClick={() => {
                if (isSignInFieldEmail) {
                  history.navigate(APP_URLS.FORGOT_PASSWORD);
                } else {
                  handleRequestCode();
                }
              }}
              className="flex justify-end py-2 text-sm text-white hover:underline cursor-pointer"
            >
              Forget Password?
            </div>
          )}
          <div
            onClick={async () => {
              const response = await handleRequestCode();
              if (response?.status === 200 || response?.status == 201) {
                setShowOTPForm(true);
              }
            }}
            className="flex justify-start py-2 text-sm text-white hover:underline cursor-pointer"
          >
            Verify Via OTP
          </div>
        </div>
      </>
    );
  };

  // Main JSX return block
  return (
    <div
      className="relative flex flex-col justify-center p-4 md:p-0 min-h-screen overflow-hidden bg-[#02041a]"
      role="main"
    >
      <div
        className="flex items-center justify-between w-full bg-yellow-500 text-black font-bold p-2 text-sm md:text-base lg:text-lg md:p-3"
        role="alert"
      >
        <div className="flex-1 text-center">
          This is a development server, all data will be lost, this is for
          experimentation and dev only
        </div>
      </div>
      <div
        className="w-full p-4 sm:p-10 m-auto bg-[#02041a] drop-shadow-xl border border-[#fbfbff] rounded-xl lg:max-w-xl"
        role="form"
      >
        <div className="flex flex-col items-center">
          <h1
            className={`text-xl text-center sm:text-4xl ${PRIMARY_TEXT_COLOR_CLASS} font-extrabold sm:w-2/3 width: -webkit-fill-available;`}
          >
            BRAINCOIN&trade;
          </h1>
          <div className="flex sm:flex-row flex-col sm:items-start items-center sm:justify-center justify-stretch py-4 gap-2">
            {/* <img src={CoinImage} height={100} width={100} alt="BrainCoin" /> */}
            <img
              src="https://braincoin.com/assets/Gold-Brain-Coin-DL9cS1jd.png"
              height={100}
              width={100}
              alt="BrainCoin"
            />
          </div>
        </div>

        {!showOTPForm ? (
          <form onSubmit={handleSubmit}>
            <div className="mt-6">
              {renderInputField()}
              {renderPasswordField()}

              <div className="flex items-center mb-4">
                <input
                  type="checkbox"
                  id="agree"
                  checked={agreed}
                  onChange={() => setAgreed(!agreed)}
                  className="mr-2"
                />
                <label htmlFor="agree" className="text-white text-sm">
                  I agree to the{" "}
                  <span
                    className="underline cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePolicyNavigation(APP_URLS.PRIVACY_POLICY);
                    }}
                  >
                    Privacy Policy
                  </span>{" "}
                  and{" "}
                  <span
                    className="underline cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePolicyNavigation(APP_URLS.TERMS_OF_SERVICE);
                    }}
                  >
                    Terms of Service
                  </span>
                </label>
              </div>

              <div className="mt-6 flex justify-center">
                <button
                  type="submit"
                  className="w-full px-4 py-3 tracking-wide text-white transition-colors border border-transparent duration-200 transform bg-[linear-gradient(95.49deg,#5d3ef8_-12.23%,#ba4cd6_117.24%)] rounded-full hover:bg-transparent hover:border-[#cec0f3] focus:outline-none focus:bg-blue-600"
                  aria-label="Continue"
                >
                  Continue
                </button>
              </div>
            </div>
          </form>
        ) : (
          <OTPLogin
            email={email}
            phone={phone}
            isSignInFieldEmail={isSignInFieldEmail}
            otpToken={otpToken}
          />
        )}

        <br />
        <div className="relative flex justify-center items-center w-full">
          <div className="px-5 flex items-center justify-center w-full text-center text-white relative before:content-[''] before:absolute before:w-[45%] before:h-[1px] before:bg-white before:left-0 after:content-[''] after:absolute after:w-[45%] after:h-[1px] after:bg-white after:right-0">
            OR
          </div>
        </div>

        <div className="flex justify-center sm:mt-6 mt-4 gap-x-2">
          <GoogleLogin onSuccess={responseMessage} shape="pill" />
        </div>

        <p className="mt-8 text-sm font-light text-center text-white">
          {" "}
          Sign in with{" "}
          <button
            onClick={() => {
              setIsSignInFieldEmail((prevState) => !prevState);
              setShowOTPForm(false);
              setShowPasswordField(false);
            }}
            className="font-medium [-webkit-text-fill-color:transparent] bg-[linear-gradient(92.19deg,#cec0f3_.3%,#c38af4_53.07%)] bg-clip-text"
            aria-label="Switch Sign In Method"
          >
            {isSignInFieldEmail ? "Phone Number" : "Email"}
          </button>
        </p>
      </div>
    </div>
  );
}
