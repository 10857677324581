import React from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const phoneInputContainerStyles = {
  marginTop: "0.5rem",
  border: "1px solid white",
  borderRadius: "25px",
};
const phoneInputStyle = {
  width: "100%",
  height: "50px",
  background: "transparent",
  color: "white",
  fontSize: "20px",
  borderTopRightRadius: "25px",
  borderBottomRightRadius: "25px",
  border: "none",
};

const countrySelectorStyle = {
  buttonStyle: {
    height: "50px",
    width: "70px",
    borderTopLeftRadius: "25px",
    borderBottomLeftRadius: "25px",
    border: "none",
    borderRight: "1px solid white",
  },
};

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: string;
  value: string;
  onChangePhone?: (phone: string) => void;
  errorMessages?: string[];
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  errorClassName?: string;
  readOnly?: boolean;
}

const CustomPhoneInput: React.FC<TextInputProps> = ({
  id,
  label,
  type = "text",
  value,
  onChangePhone,
  errorMessages = [],
  containerClassName = "mb-4",
  labelClassName = "flex text-sm font-normal text-gray-200",
  inputClassName = "block w-full px-4 py-3 mt-2 bg-transparent text-white border border-white rounded-full focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
  errorClassName = "text-red-500 text-sm mt-1",
  readOnly = false,
  ...props
}) => {
  const displayErrors = (errors: string[] | string) => {
    const errorArray = Array.isArray(errors) ? errors : [errors];
    return errorArray.map((error, index) => (
      <p key={index} className={errorClassName}>
        {error}
      </p>
    ));
  };

  return (
    <div className={containerClassName}>
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
        </label>
      )}
      <PhoneInput
        defaultCountry="us"
        value={value}
        onChange={onChangePhone}
        inputClassName={inputClassName}
        inputStyle={phoneInputStyle}
        countrySelectorStyleProps={countrySelectorStyle}
        style={phoneInputContainerStyles}
        disabled={readOnly}
      />
      {displayErrors(errorMessages)}
    </div>
  );
};

export default CustomPhoneInput;
