import React, { useEffect } from "react";
import { getSharedPlaylists } from "redux/playlist/playlistSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ExpandablePlaylist from "components/playlist/ExpandablePlaylist";

interface Props {
  smallHeader?: boolean;
}

/**
 * Component for displaying a list of playlists that have been shared with the user.
 */
export default function SharedPlaylistList({ smallHeader = false }: Props) {
  const dispatch = useAppDispatch();
  const sharedPlaylists = useAppSelector(
    (state) => state.playlist.sharedPlaylists,
  );

  /**
   * Retrieve a list of playlists that have been shared with the user on mount.
   */
  useEffect(() => {
    dispatch(getSharedPlaylists({}));
  }, []);

  /**
   * Display a list of playlists.
   */
  const displaySharedPlaylists = () => {
    if (sharedPlaylists.length > 0) {
      return sharedPlaylists.map((playlist, idx) => (
        <ExpandablePlaylist key={idx} playlist={playlist} />
      ));
    } else {
      return (
        <p className="py-4 px-4 text-gray-500 text-sm font-semibold">
          No Playlists have been shared with you yet!
        </p>
      );
    }
  };

  return (
    <>
      <div className="flex items-center p-4 border-b" aria-label="Shared Playlists Header">
        {smallHeader ? (
          <span className="font-semibold">Playlists Shared with Me</span>
        ) : (
          <h3 className="text-lg font-semibold text-gray-800 capitalize">
            Playlists Shared with Me
          </h3>
        )}
      </div>
      <ul className="divide-y divide-gray-200" aria-label="Shared Playlists List">
        {displaySharedPlaylists()}
      </ul>
    </>
  );
}
