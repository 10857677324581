import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  clearFeedItems,
  getFeedItem,
  getRandomFeedItems,
  setCurrFeedItemIdx,
  setFeedItem,
  setFeedItems,
  setShowPlaylistsDrawer,
} from "redux/feed/feedItemsSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import PlaylistsDrawer from "./playlists/PlaylistsDrawer";
import ScrollableMobileFeedElement from "./ScrollableMobileFeedElement";
import { FeedItem } from "types/feed";
import { isDispatchResponseError } from "redux/utils";
import { initiateSpotifyMobileFeed } from "helpers/spotifyIFrameAPI";
import { PlaylistItem } from "types/playlist";
import { getPlaylist } from "redux/playlist/playlistSlice";
import LikePopup from "./popups/LikePopup";
import DislikePopup from "./popups/DislikePopup";
import {
  generateFeedItemHtmlId,
  updateFeedItemZIndexes,
  SEEK_BAR_HEIGHT,
  TRANSITION_STYLE_PROPERTY,
  getComponentHeight,
  focusFeedItem,
} from "./utils";
import { Spinner } from "flowbite-react";
import { setCurrFeedItem } from "redux/feed/feedItemReportsSlice";
import { QueryParams } from "types/api";

interface Props {
  // Id of a feed item to be displayed first
  feedItemId?: string;
  // Id of a playlist to play feed items from
  playlistId?: string;
}

/**
 * Alternative feed for mobile devices that does not support gestures.
 */
export default function MobileFeed({ feedItemId, playlistId }: Props) {
  const dispatch = useAppDispatch();

  const [playing, setPlaying] = useState(false);
  const [spotifyIFrameAPI, setSpotifyIFrameAPI] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [firstFetchComplete, setFirstFetchComplete] = useState(false);

  const feedFilterTypes = useAppSelector(
    (state) => state.feedFilterTypes.feedFilterTypes,
  );
  const selectedFeedDataSet = useAppSelector(
    (state) => state.dataSets.selectedFeedDataSet,
  );
  const pendingGetRandomFeedItems = useAppSelector(
    (state) => state.feedItems.pendingGetRandomFeedItems,
  );
  const pendingGetFeedItem = useAppSelector(
    (state) => state.feedItems.pendingGetFeedItem,
  );
  const showLikePopup = useAppSelector(
    (state) => state.feedItems.showLikePopup,
  );
  const showDislikePopup = useAppSelector(
    (state) => state.feedItems.showDislikePopup,
  );
  const showPlaylistsDrawer = useAppSelector(
    (state) => state.feedItems.showPlaylistsDrawer,
  );
  const currFeedItemIdx = useAppSelector(
    (state) => state.feedItems.currFeedItemIdx,
  );
  const feedItems = useAppSelector((state) => state.feedItems.feedItems);

  const overlayRef = useRef<HTMLDivElement | null>(null);

  const renderedFeedItems = useMemo(
    function renderFeedItems() {
      return feedItems.map((feedItem, idx) => {
        const renderedItemHeight = getComponentHeight();
        const id = generateFeedItemHtmlId(feedItem, idx);
        return (
          <ScrollableMobileFeedElement
            key={id}
            id={id}
            className="absolute w-full aspect-video rounded bg-gray-100"
            style={{
              ...TRANSITION_STYLE_PROPERTY,
              height: renderedItemHeight + "px",
              top: renderedItemHeight * idx,
            }}
            data={{
              playing: idx === currFeedItemIdx ? playing : false,
              feedItem,
            }}
            spotifyIFrameAPI={spotifyIFrameAPI}
          />
        );
      });
    },
    [playing, feedItems, spotifyIFrameAPI],
  );

  /**
   * Retrieve feed items from the backend.
   */
  async function retrieveFeedItems() {
    if (feedItemId) {
      return dispatch(getFeedItem({ id: feedItemId }));
    } else {
      const queryParams: QueryParams = {};
      if (feedFilterTypes) {
        queryParams["types"] = feedFilterTypes.join(",");
      }
      if (selectedFeedDataSet) {
        queryParams["dataset"] = selectedFeedDataSet.id;
      }
      return dispatch(
        getRandomFeedItems({
          queryParams,
        }),
      );
    }
  }

  /**
   * Fetch feed item or items from the backend.
   */
  async function fetchFeedItems() {
    const response = await retrieveFeedItems();
    if (!isDispatchResponseError(response)) {
      const payload = response.payload;
      if (Array.isArray(payload)) {
        await dispatch(setFeedItems([...(payload as FeedItem[])]));
      } else {
        await dispatch(setFeedItems([payload as FeedItem]));
      }
    }
    setFirstFetchComplete(true);
  }

  /**
   * Fetch next set of feed items from the backend.
   */
  async function fetchAdditionalFeedItems() {
    const response = await retrieveFeedItems();
    if (!isDispatchResponseError(response)) {
      await dispatch(
        setFeedItems([...feedItems, ...(response.payload as FeedItem[])]),
      );
    }
  }

  /**
   * Fetch feed items from a playlist.
   */
  async function fetchPlaylistFeedItems() {
    if (playlistId) {
      const response = await dispatch(getPlaylist({ id: playlistId }));
      if (!isDispatchResponseError(response) && response.payload) {
        const newFeedItems: Array<FeedItem> = [];
        (response.payload.items as PlaylistItem[]).forEach((item) => {
          // Extract feed items
          newFeedItems.push(item.feed_item);
        });

        await dispatch(setFeedItems(newFeedItems));
      }
      setFirstFetchComplete(true);
    }
  }

  /**
   * Fetch feed items whenever the component mounts and append Spotify setup script.
   */
  useEffect(() => {
    // Append Spotify script used by SpotifyTrack component
    initiateSpotifyMobileFeed(setSpotifyIFrameAPI);
    const script = document.createElement("script");
    script.src = "https://open.spotify.com/embed/iframe-api/v1";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  /**
   * Fetch feed items whenever feedFilterTypes and selectedFeedDataSet change.
   */
  useEffect(() => {
    // Reload feed items if feedFilterTypes changes
    dispatch(setCurrFeedItemIdx(0));
    setPlaying(false);
    dispatch(clearFeedItems());

    // Fetch feed items
    if (playlistId) {
      fetchPlaylistFeedItems();
    } else {
      fetchFeedItems();
    }
  }, [feedFilterTypes, selectedFeedDataSet]);

  /**
   * Stop feed items from playing whenever currFeedItemIdx changes.
   */
  useEffect(() => {
    setPlaying(false);

    focusFeedItem({
      feedItems,
      feedItemIdx: currFeedItemIdx,
    });
  }, [currFeedItemIdx]);

  /**
   * Set up gesture handlers whenever feed items change.
   */
  useEffect(() => {
    // Avoid infinite fetching of feed items if there are no items available
    if (feedItems.length === 0) return;

    // Fetch another set of feed items if user gets close to the end of the array
    if (currFeedItemIdx >= feedItems.length - 1 && !playlistId && !feedItemId) {
      fetchAdditionalFeedItems();
    }

    // Update every feed item element's z-index
    updateFeedItemZIndexes({ feedItemIdx: currFeedItemIdx, feedItems });
  }, [feedItems, currFeedItemIdx, playing, pendingGetRandomFeedItems]);

  /**
   * Set loading state whenever feed items are fetched.
   */
  useEffect(() => {
    const isEmpty = firstFetchComplete && feedItems.length === 0;
    const newLoading =
      (pendingGetRandomFeedItems || pendingGetFeedItem) && !isEmpty;
    if (newLoading) {
      // Immediately set loading to true in order to display loading indicator
      setLoading(newLoading);
    } else {
      // Add a delay to setting loading to false.
      // It's done in order to hide loading states of embeds.
      setTimeout(() => {
        setLoading(newLoading);
      }, 2500);
    }
  }, [
    pendingGetRandomFeedItems,
    pendingGetFeedItem,
    feedItems,
    firstFetchComplete,
  ]);

  /**
   * Update currFeedItem and feedItem whenever feedItems change
   * so that ReportFeedItemModal can display the correct feed item and
   * the navigation bar knows which feed item is currently being viewed.
   */
  useEffect(() => {
    if (currFeedItemIdx < feedItems.length) {
      dispatch(setCurrFeedItem(feedItems[currFeedItemIdx]));
      dispatch(setFeedItem(feedItems[currFeedItemIdx]));
    } else {
      dispatch(setCurrFeedItem(null));
      dispatch(setFeedItem(null));
    }
  }, [feedItems, currFeedItemIdx]);

  return (
    <div className="relative h-full overflow-hidden">
      {feedItems instanceof Array && feedItems.length === 0 ? (
        <div
          className={
            "flex justify-center items-center text-lg bg-neutral-800 text-slate-100 h-full text-center"
          }
          role="alert"
          aria-live="assertive"
        >
          {loading ? (
            <div className="bg-gray-100 justify-center items-center flex w-full h-full">
              <Spinner size="xl" aria-label="Loading" />
            </div>
          ) : (
            "There isn't anything available for you to watch"
          )}
        </div>
      ) : (
        <>
          {/* Overlay
            Iframes themselves cannot be draggable since we cannot capture touch/click events on them, hence the overlay
          */}
          {/* Disabled overlay for the purpose of implementing new movie feed component */}
          {/* <div
            className={"absolute left-0 top-0 right-0 bottom-0 z-40 flex"}
            ref={overlayRef}
            draggable
            style={{
              ...TRANSITION_STYLE_PROPERTY,
              height: loading
                ? getComponentHeight()
                : getComponentHeight() - SEEK_BAR_HEIGHT,
            }}
            onClick={() => {
              if (!loading) {
                setPlaying(!playing);
              }
            }}
          >
            {loading && (
              <div className="bg-gray-100 justify-center items-center flex w-full h-full">
                <Spinner size="xl" />
              </div>
            )}
          </div> */}

          <LikePopup showLikePopup={showLikePopup} />
          <DislikePopup showDislikePopup={showDislikePopup} />

          {renderedFeedItems}

          {feedItems && (
            <PlaylistsDrawer
              currItemId={feedItems[currFeedItemIdx].id}
              show={showPlaylistsDrawer}
              setShow={(val) => dispatch(setShowPlaylistsDrawer(val))}
              incrementCurrFeedItemIdx={() =>
                dispatch(setCurrFeedItemIdx(currFeedItemIdx + 1))
              }
              closeMode="button"
            />
          )}
        </>
      )}
    </div>
  );
}
