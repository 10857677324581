export const QUESTION_CATEGORIES = {
  MOVIES: "MOVIES",
  YOUTUBE: "YOUTUBE",
  MUSIC: "MUSIC",
  NEWS: "NEWS",
};

export const QUESTION_CATEGORIES_LABELS = {
  MOVIES: "Movies",
  YOUTUBE: "YouTube",
  MUSIC: "Music",
  NEWS: "News",
};

export const QUESTION_TYPES = {
  AB: "AB",
  MULTIPLE: "MULTIPLE",
  RATING: "RATING",
  LISTCHOICE: "LISTCHOICE",
  FREEFORM: "FREEFORM",
};

export const QUESTION_TYPES_LABELS = {
  AB: "A or B",
  MULTIPLE: "Multiple choice",
  RATING: "Rating",
  LISTCHOICE: "List pick",
  FREEFORM: "Freeform",
};

export const QUESTION_TYPES_EXPLANATIONS = {
  AB: "User will select from one of two available answers",
  MULTIPLE: "User will select one from any number of available answers",
  RATING: "User will provide an answer in the form of 1-5 stars",
  LISTCHOICE: "User will select multiple options from available answers",
  FREEFORM: "User will provide their answer themself",
};

export interface QuizQuestionAnswerOption {
  id: number;
  text: string;
  value: string;
  position: number;
  question: number;
  image?: string | File | null;
  external_image?: string | null;
}

export interface QuizQuestionAnswerOptionFormData
  extends Omit<QuizQuestionAnswerOption, "id" | "question"> {
  id?: QuizQuestionAnswerOption["id"];
}

export interface QuizQuestionAnswerOptionImageFormData {
  answer_option: QuizQuestionAnswerOption["id"];
  image?: QuizQuestionAnswerOption["image"];
  external_image: QuizQuestionAnswerOption["external_image"];
}

export interface QuizQuestionAnswer {
  question: number;
  answer: number;
  selected: boolean;
  value?: string;
  created_at?: string;
  updated_at?: string;
}

export type QuizQuestionCategory = keyof typeof QUESTION_CATEGORIES;

export type QuizQuestionType = keyof typeof QUESTION_TYPES;

export interface QuizQuestion {
  id: number;
  text: string;
  value: string;
  category: QuizQuestionCategory;
  type: QuizQuestionType;
  position: number;
  required: boolean;
  image?: string | File | null;
  external_image?: string | null;
  answer_options: QuizQuestionAnswerOption[];
}

export interface QuizQuestionFormData
  extends Omit<QuizQuestion, "id" | "answer_options"> {
  id?: number;
  quiz?: string;
  answer_options: QuizQuestionAnswerOptionFormData[];
}

export interface QuizQuestionImageFormData {
  question: QuizQuestion["id"];
  image?: QuizQuestion["image"];
  external_image: QuizQuestion["external_image"];
}

export interface Quiz {
  id: string;
  name: string;
  is_main: boolean;
  neural_network: string;
  created_by: string;
  been_taken: boolean;
  is_hidden: boolean;
}

export interface QuizResults {
  id: string;
  questions: Array<QuizResultsQuestion>;
  completed_by_count: number;
  attempt_count: number;
}

export interface QuizResultsQuestion {
  id: number;
  text: string;
  answer_options: Array<QuizResultsAnswerOption>;
}

export interface QuizResultsAnswerOption {
  id: number;
  text: string;
  count: number;
  open_question_values: Array<string>;
}

export interface QuizFormData
  extends Pick<Quiz, "name" | "neural_network" | "is_hidden"> {
  questions: QuizQuestionFormData[];
}
