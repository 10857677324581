import { HandThumbDownIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { LIKE_POPUP_DURATION_MS, TRANSITION_STYLE_PROPERTY } from "../utils";

interface Props {
  showDislikePopup: boolean;
}

/**
 * Component for displaying a popup when a user dislikes a feed item.
 */
export default function DislikePopup({ showDislikePopup }: Props) {
  const [display, setDisplay] = useState(false);
  const [opacity, setOpacity] = useState(false);

  /**
   * Displays the popup when the user dislikes a feed item and hides it after a LIKE_POPUP_DURATION_MS.
   */
  useEffect(() => {
    // LikePopup must be hidden from view & not block any items underneath it, so
    // its CSS display property has to be set with a delay
    const additionalDelayMS = 100;
    if (showDislikePopup) {
      setDisplay(showDislikePopup);

      setTimeout(() => {
        setOpacity(showDislikePopup);
      }, additionalDelayMS);

      setTimeout(() => {
        setOpacity(!showDislikePopup);
      }, LIKE_POPUP_DURATION_MS);

      setTimeout(() => {
        setDisplay(!showDislikePopup);
      }, LIKE_POPUP_DURATION_MS + additionalDelayMS + additionalDelayMS);
    }
  }, [showDislikePopup]);

  return (
    <div
      className={`absolute flex-col justify-center items-center transition-all ease-out space-y-2 overflow-hidden left-0 top-0 right-0 bottom-0 z-40 ${
        opacity ? "opacity-1" : "opacity-0"
      } ${display ? "flex" : "hidden"}`}
      style={{ ...TRANSITION_STYLE_PROPERTY }}
      aria-live="assertive"
      aria-atomic="true"
    >
      <HandThumbDownIcon
        className="text-slate-100 w-1/4 p-10 bg-red-500 rounded-full border-slate-100 border-4 box-content"
        aria-label="Dislike icon"
      />
      <span className="text-3xl text-slate-100 font-semibold tracking-wider">
        Disliked!
      </span>
    </div>
  );
}
