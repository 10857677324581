import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import Header from "components/common/header";
import deselectAllImage from "./images/deselect-all.jpeg";
import selectYoutubeImage from "./images/select-youtube.jpeg";
import selectYoutubeHistoryImage from "./images/select-youtube-history.jpeg";
import selectYoutubeNextStepImage from "./images/select-youtube-next-step.jpeg";
import archiveDetailsImage from "./images/archive-details.jpeg";
import archiveProgressImage from "./images/archive-progress.jpeg";
import archiveEmailImage from "./images/archive-email.jpeg";

const GUIDE_STEPS = [
  <>
    Open Google Takeout page:{" "}
    <a
      href="https://takeout.google.com/"
      className="text-blue-600 hover:underline"
      target="_blank"
      rel="noreferrer"
      aria-label="Google Takeout Page"
    >
      https://takeout.google.com/
    </a>
  </>,
  <>
    Click <b>Deselect all</b>
    <div className="flex justify-center">
      <img src={deselectAllImage} alt="Deselect all" />
    </div>
  </>,
  <>
    Select <b>YouTube and YouTube Music</b>, then press{" "}
    <b>All YouTube data included</b>.
    <div className="flex justify-center mt-4">
      <img src={selectYoutubeImage} alt="Select YouTube and YouTube Music" />
    </div>
  </>,
  <>
    Select <b>history</b>, then press <b>OK</b>.
    <div className="flex justify-center mt-4">
      <img src={selectYoutubeHistoryImage} alt="Select YouTube history" />
    </div>
  </>,
  <>
    Click <b>Next step</b>.
    <div className="flex justify-center mt-4">
      <img src={selectYoutubeNextStepImage} alt="Click Next step" />
    </div>
  </>,
  <>
    Make sure that <b>Destination</b> is set to email, <b>Frequency</b> to once,{" "}
    <b>File type</b> to zip and <b>File size</b> to 2 GB.
    <br />
    Once you have confirmed the above, press <b>Create export</b>.
    <div className="flex justify-center">
      <img src={archiveDetailsImage} alt="Archive details" />
    </div>
  </>,
  <>
    Please wait until the process is complete. Once it&apos;s done, you should
    receive an email containing the files from google.
    <div className="flex justify-center mt-4">
      <img src={archiveProgressImage} alt="Archive progress" />
    </div>
    <div className="flex justify-center ">
      <img src={archiveEmailImage} alt="Archive email" />
    </div>
  </>,
];

/**
 * Google takeout guide page.
 */
export default function GoogleTakeoutGuide() {
  useEffect(() => {
    document.title = "Google Takeout | BrainCargo";
  }, []);

  /**
   * Renders a list of guide steps.
   */
  function renderGuideStepsList() {
    return (
      <ol className="space-y-4">
        {GUIDE_STEPS.map((elem, index) => (
          <li key={index} className="px-6 py-4 border-b" aria-label={`Step ${index + 1}`}>
            <div className="max-w-full sm:max-w-[1024px]">
              <span className="select-none">{index + 1}. </span>
              {elem}
            </div>
          </li>
        ))}
      </ol>
    );
  }

  return (
    <PageWrapper>
      <div className="overflow-x-auto border shadow-md sm:rounded-lg bg-white" aria-label="Google Takeout Guide Page">
        <Header title="Google Takeout Guide" />
        <div>
          {renderGuideStepsList()}
        </div>
      </div>
    </PageWrapper>
  );
}
