import React from "react";
import { FeedItem } from "types/feed";

interface Props {
  video: FeedItem;
}

/**
 * A video feed item.
 */
export default function Video({ video }: Props) {
  return (
    <div className="flex space-x-4">
      <div className="flex-shrink-0">
        <img
          className="h-[100px] w-[100px] rounded object-contain"
          src={video.image || ""}
          alt="Video thumbnail"
        />
      </div>
      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium text-gray-900 truncate">
          {video.title}
        </p>
      </div>
    </div>
  );
}
