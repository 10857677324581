import React from "react";
import { retrieveNewsItemsDataset } from "redux/feed/feedDatasetSlice";
import { useAppSelector } from "redux/hooks";
import FeedDataTable from "../common/FeedDataTable";
import { APP_URLS } from "navigation";

/**
 * Component for displaying a list of news-related feed data.
 */
export default function FeedDataNewsList() {
  function onClickRow(itemId: string) {
    // Navigate to news details page
    window.location.href = APP_URLS.FEED_DATA_NEWS_DETAILS.replace(
      ":newsId",
      itemId,
    );
  }
  
  return (
    <FeedDataTable
      headerText="News"
      defaultOrdering="title"
      paginatedList={useAppSelector((state) => state.feedDataset.newsItems)}
      fetchThunkFunction={retrieveNewsItemsDataset}
      pending={useAppSelector(
        (state) => state.feedDataset.retrieveNewsItemsDatasetPending,
      )}
      labels={{
        id: "ID",
        title: "Title",
        bing_news_url: "Link",
        liked_by_count: "Likes",
        disliked_by_count: "Dislikes",
        watched_by_count: "Watches",
        skipped_by_count: "Skips",
      }}
      onClickRow={onClickRow}
    />
  );
}
