import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import PaginationV2 from "components/common/pagination/PaginationV2";
import SearchInput from "components/common/searchInput";
import { Button, Spinner } from "flowbite-react";
import { formatDateTime } from "helpers/date";
import { APP_URLS } from "navigation";
import React, { useEffect, useState } from "react";
import {
  getFeedItemReports,
  toggleFeedItemReportReviewStatus,
} from "redux/feed/feedItemReportsSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { isDispatchResponseError } from "redux/utils";
import { QueryParams } from "types/api";
import { FEED_ITEM_TYPES, FeedItem, FeedItemReport } from "types/feed";
import { useDebounce } from "use-debounce";

const DEFAULT_ORDERING = "created_at";

const LABELS = {
  created_at: "Creation date",
  user: "Reported by",
  feed_item: "Feed item",
  description: "Description",
  is_reviewed: "Is reviewed",
};

/**
 * Table used for displaying feed item reports.
 */
export default function ReportListTable() {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [ordering, setOrdering] = useState(DEFAULT_ORDERING);
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const pendingGetFeedItemReports = useAppSelector(
    (state) => state.feedItemReports.pendingGetFeedItemReports,
  );
  const feedItemReports = useAppSelector(
    (state) => state.feedItemReports.feedItemReports,
  );
  const pendingToggleFeedItemReportReviewStatus = useAppSelector(
    (state) => state.feedItemReports.pendingToggleFeedItemReportReviewStatus,
  );

  /**
   * Render table header.
   */
  function renderTableHeader({ key, label }: { key: string; label: string }) {
    let icon = null;
    if (ordering === key) {
      icon = <ChevronDownIcon className="w-4" aria-hidden="true" />;
    }
    if (ordering === "-" + key) {
      icon = <ChevronUpIcon className="w-4" aria-hidden="true" />;
    }

    function onClickHeader() {
      if (ordering === key) {
        setOrdering("-" + ordering);
      } else if (ordering === "-" + key) {
        setOrdering(ordering.replaceAll("-", ""));
      } else {
        setOrdering(key);
      }
    }

    return (
      <th className="p-2" key={key}>
        <button onClick={onClickHeader} className="flex flex-row gap-1" aria-label={`Sort by ${label}`}>
          {icon}
          {label}
        </button>
      </th>
    );
  }

  /**
   * Toggle review status.
   */
  async function onToggleReviewStatus(item: FeedItemReport) {
    const data = { id: item.id, is_reviewed: !item.is_reviewed };
    const response = await dispatch(toggleFeedItemReportReviewStatus(data));
    if (!isDispatchResponseError(response)) {
      fetchData(page, query);
    }
  }

  /**
   * Generate link to feed item.
   */
  function generateFeedItemLink(feedItem: FeedItem) {
    const { type } = feedItem;
    if (type === FEED_ITEM_TYPES.NEWS) {
      return APP_URLS.FEED_DATA_NEWS_DETAILS.replace(":newsId", feedItem.id);
    } else if (type === FEED_ITEM_TYPES.TRACK) {
      return APP_URLS.FEED_DATA_TRACK_DETAILS.replace(":trackId", feedItem.id);
    } else {
      return APP_URLS.FEED_DATA_VIDEO_DETAILS.replace(":videoId", feedItem.id);
    }
  }

  /**
   * Fetch feed item reports.
   */
  function fetchData(page: number, query: string) {
    const queryParams: QueryParams = {
      page: page.toString(),
      page_size: pageSize.toString(),
      query,
      ordering,
    };
    dispatch(getFeedItemReports({ queryParams }));
  }

  /**
   * Fetch data whenever page, query or ordering changes.
   */
  useEffect(() => {
    fetchData(page, debouncedQuery);
  }, [page, debouncedQuery, ordering]);

  return (
    <div>
      <div className="pb-2">
        <SearchInput value={query} onChange={setQuery} />
      </div>
      {pendingGetFeedItemReports || pendingToggleFeedItemReportReviewStatus ? (
        <div className="flex justify-center items-center" role="status" aria-live="polite">
          <Spinner />
        </div>
      ) : (
        <div className="border rounded overflow-y-auto">
          <table className="w-full text-sm text-left bg-white">
            <thead className="text-xs text-gray-700 uppercase border-b sticky top-0">
              <tr className="bg-gray-50">
                {Object.keys(LABELS).map((key) =>
                  renderTableHeader({
                    key,
                    label: LABELS[key as keyof typeof LABELS],
                  }),
                )}
                <th className="p-2">
                  <button className="flex flex-row gap-1" aria-label="Actions">Actions</button>
                </th>
              </tr>
            </thead>
            <tbody>
              {feedItemReports.results.map((elem) => {
                return (
                  <tr key={elem.id} className="border-b border-gray-200">
                    <td className="p-2">{formatDateTime(elem.created_at)}</td>
                    <td className="p-2">{elem.user.username}</td>
                    <td className="p-2">
                      <a
                        className="text-blue-600 underline cursor-pointer"
                        href={generateFeedItemLink(elem.feed_item)}
                        target="_blank"
                        rel="noreferrer"
                        aria-label={`Link to ${elem.feed_item?.title}`}
                      >
                        {elem.feed_item?.title}
                      </a>
                    </td>
                    <td className="p-2">{elem.description}</td>
                    <td className="p-2">
                      {elem.is_reviewed ? (
                        <CheckCircleIcon className="w-8 text-green-500" aria-hidden="true" />
                      ) : (
                        <XCircleIcon className="w-8 text-red-500" aria-hidden="true" />
                      )}
                    </td>
                    <td>
                      <Button onClick={() => onToggleReviewStatus(elem)} aria-label="Toggle review status">
                        Toggle review status
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <PaginationV2
        count={feedItemReports.count}
        page={page}
        previous={feedItemReports.previous}
        next={feedItemReports.next}
        onClickPrev={() => setPage(page - 1)}
        onClickNext={() => setPage(page + 1)}
        pageSize={pageSize}
      />
    </div>
  );
}
