import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import Header from "components/common/header";
import FeedbackListTable from "components/feedback/FeedbackListTable";

/**
 * Feedback list page.
 */
export default function FeedbackList() {
  useEffect(() => {
    document.title = "Feedbacks | BrainCargo";
  }, []);

  return (
    <PageWrapper>
      <div className="border shadow-md lg:rounded-lg bg-white pb-6 space-y-2" role="main">
        <Header title="Feedbacks" />
        <div className="py-2 px-4">
          <FeedbackListTable />
        </div>
      </div>
    </PageWrapper>
  );
}
