import React, { ReactNode } from "react";

/**
 * Component that renders an expandable menu used in MobileBottomNavBar.
 */
export default function MobileNavBarExpandableMenu({
  expanded,
  children,
  gridCols,
}: {
  expanded: boolean;
  children: ReactNode;
  gridCols: number;
}) {
  return (
    <div
      className={`overflow-hidden ${expanded ? "" : "h-0"}`}
      aria-expanded={expanded}
      aria-label="Expandable menu"
    >
      <div className={`grid grid-cols-${gridCols} gap-2 shadow-inner py-2`}>
        {children}
      </div>
    </div>
  );
}
