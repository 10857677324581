import { PayloadAction } from "@reduxjs/toolkit";

/**
 * Returns true if provided action has thrown an error, otherwise returns false
 *
 * Example use:
 * const response = await dispatch(register({username, email, password1, password2}));
 * if (!isDispatchResponseError(response)) {
 *  -- Handle success
 * }
 */
export const isDispatchResponseError = (
  action: PayloadAction<any>,
): boolean => {
  return action.type.endsWith("/rejected");
};
