import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import { history } from "helpers/history";
import { APP_URLS } from "navigation";
import QuizTable from "components/quiz/QuizTable";
import Header from "components/common/header";

export interface NeuralNetwork {
  id: string;
  name: string;
  category_name: string;
  file: string;
  uploaded_by: string;
}

/**
 * Quiz List page
 */
export default function QuizList() {
  function onAdd() {
    history.navigate(APP_URLS.QUIZZES_CREATE);
  }

  useEffect(() => {
    document.title = "Quizzes | BrainCargo";
  }, []);

  return (
    <PageWrapper>
      <div className="border shadow-md lg:rounded-lg bg-white pb-6 space-y-2">
        <Header title="Quizzes" />

        <div className="px-6 space-y-2">
          <QuizTable onAdd={onAdd} />
        </div>
      </div>
    </PageWrapper>
  );
}
