import { StripeNFTProduct } from "types/payments";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Modal from "components/common/modal";
import {
  fetchCheckoutUrl,
  fetchNFTProducts,
} from "redux/payments/paymentsSlice";
import { Button, Spinner } from "flowbite-react";
import NFTProductClickable from "./NFTProductClickable";
import { isDispatchResponseError } from "redux/utils";

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  walletAddress: string | undefined;
}

export default function NFTProductSelectionModal({
  show,
  setShow,
  walletAddress,
}: Props) {
  const dispatch = useAppDispatch();
  const nftProducts = useAppSelector((state) => state.payments.nftProducts);
  const pendingFetchNFTProducts = useAppSelector(
    (state) => state.payments.pendingFetchNFTProducts,
  );
  const errorMessagesFetchNFTProducts = useAppSelector(
    (state) => state.payments.errorMessagesFetchNFTProducts,
  );
  const pendingFetchCheckoutUrl = useAppSelector(
    (state) => state.payments.pendingFetchCheckoutUrl,
  );
  const [
    selectedProduct,
    setSelectedProduct,
  ] = useState<StripeNFTProduct | null>(null);

  function onClose() {
    setShow(false);
    setSelectedProduct(null);
  }

  function onClickNFTProduct(product: StripeNFTProduct) {
    setSelectedProduct(product);
  }

  async function onConfirm() {
    console.log("CONFIRMATION",walletAddress,"::");
    if (!selectedProduct) {
      return;
    }
    if (!walletAddress) {
      return;
    }
    console.log("FETCHING CHECKOUT URL", selectedProduct.price.id,"::", walletAddress);
    const response = await dispatch(
      fetchCheckoutUrl({ priceId: selectedProduct.price.id, walletAddress }),
    );
    console.log("URL_RESPONSE", response);
    if (!isDispatchResponseError(response)) {
      window.location.href = response.payload as string;
    }
  }

  useEffect(() => {
    dispatch(fetchNFTProducts());
  }, []);

  return (
    <Modal
      show={show}
      onClose={onClose}
      hideFooter
      body={
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Please select an NFT to purchase
          </h3>

          {pendingFetchNFTProducts ? (
            <Spinner size="sm" />
          ) : (
            <div className="flex flex-wrap justify-center items-center gap-2">
              {nftProducts?.map((product: StripeNFTProduct) => (
                <NFTProductClickable
                  key={product.id}
                  data={product}
                  onClick={() => onClickNFTProduct(product)}
                  selected={selectedProduct?.id === product.id}
                />
              ))}
            </div>
          )}

          <div className="flex justify-center gap-4 mt-8">
            <Button
              color="info"
              onClick={onConfirm}
              disabled={!selectedProduct}
            >
              {pendingFetchCheckoutUrl ? <Spinner size="sm" /> : "Checkout"}
            </Button>
            <Button color="gray" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      }
    />
  );
}
