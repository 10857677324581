import React, { CSSProperties, useEffect, useState } from "react";
import { increaseWatchedFor, watchFeedItem } from "redux/feed/feedItemsSlice";
import { useAppDispatch } from "redux/hooks";
import { FeedItem } from "types/feed";

interface Props {
  id: string;
  className?: string;
  style: CSSProperties;
  data: { playing: boolean; feedItem: FeedItem | null };
}

/**
 * Component for displaying a Bing News feed item in ScrollableMobileFeed.
 */
export default function BingNews({ id, className, style, data }: Props) {
  const dispatch = useAppDispatch();
  const [blurDate, setBlurDate] = useState<Date | null>(null);

  /**
   * Increases the watched for time for the feed item.
   */
  function updateWatchedFor(readFor: number) {
    if (data.feedItem && readFor > 0) {
      dispatch(
        increaseWatchedFor({
          feed_item: data.feedItem.id,
          watched_for: readFor,
          watched: true,
        }),
      );
    }
  }

  /**
   * Opens the news item in a new tab and sets up variables used by updateWatchedFor.
   */
  useEffect(() => {
    if (data.playing && data.feedItem) {
      window.open(data.feedItem.bing_news_url);
      setBlurDate(new Date());
      dispatch(watchFeedItem(data.feedItem.id));
    }
  }, [data.playing]);

  /**
   * Updates watched for time when the user navigates back into the app.
   */
  useEffect(() => {
    const onVisibilityChange = function (e: Event) {
      if (!document.hidden && blurDate) {
        const now = new Date();
        const seconds = (now.getTime() - blurDate.getTime()) / 1000;
        updateWatchedFor(seconds);
        setBlurDate(null);
      }
    };

    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [blurDate]);

  return (
    <div id={id} className={className} style={style}>
      {data.feedItem ? (
        <div className="flex flex-col p-8 rounded gap-4 h-full justify-center">
          <div className="flex justify-center items-center">
            <img
              src={data.feedItem.image || ""}
              className="h-[150px] rounded"
              alt={data.feedItem.title}
            />
          </div>

          <div className="text-lg font-bold border-b">
            {data.feedItem.title}
          </div>

          <div className="text-base">{data.feedItem.description}</div>

          <a
            className="text-blue-600 font-medium cursor-pointer inline text-center p-2"
            href={data.feedItem.bing_news_url}
            target="_blank"
            rel="noreferrer"
            aria-label={`Read more about ${data.feedItem.title}`}
          >
            Tap to read more
          </a>
        </div>
      ) : (
        <div className="text-center">
          <p className="bg-gray-200 font-semibold p-6">
            News not available at this time
          </p>
        </div>
      )}
    </div>
  );
}
