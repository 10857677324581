import React, { useEffect } from "react";
import { getMyPlaylists } from "redux/playlist/playlistSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ExpandablePlaylist from "components/playlist/ExpandablePlaylist";
import ButtonCreatePlaylist from "components/playlist/ButtonCreatePlaylist";
import ButtonSharePlaylists from "components/playlist/ButtonSharePlaylist";

interface Props {
  smallHeader?: boolean;
}

/**
 * Component for displaying a list of playlists that the user has created.
 */
export default function MyPlaylistList({ smallHeader = false }: Props) {
  const dispatch = useAppDispatch();
  const myPlaylists = useAppSelector((state) => state.playlist.myPlaylists);

  useEffect(() => {
    document.title = "Playlists | BrainCargo";

    // Retrieve a list of playlists
    dispatch(getMyPlaylists({}));
  }, []);

  /**
   * Display a list of playlists.
   */
  const displayMyPlaylists = () => {
    if (myPlaylists.length > 0) {
      return myPlaylists.map((playlist, idx) => (
        <ExpandablePlaylist key={idx} playlist={playlist} />
      ));
    } else {
      return (
        <p className="py-4 px-4 text-gray-500 text-sm font-semibold">
          No Playlists added yet!
        </p>
      );
    }
  };

  return (
    <>
      <div className="flex items-center p-4 border-b" aria-label="My Playlists Header">
        {smallHeader ? (
          <span className="font-semibold">My Playlists</span>
        ) : (
          <h3 className="text-lg font-semibold text-gray-800 capitalize">
            My Playlists
          </h3>
        )}
        <ButtonSharePlaylists additionalClasses="ml-auto" />
        <ButtonCreatePlaylist additionalClasses="ml-2" />
      </div>
      <ul className="divide-y divide-gray-200" aria-label="My Playlists List">
        {displayMyPlaylists()}
      </ul>
    </>
  );
}
