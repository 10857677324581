import React, { FormEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  googleRegister,
  incrementActiveStepIndex, login, phoneRegister,
  register, verifyOTP,
} from "redux/auth/authSlice";
import CoinImage from "images/Landing/Landing-Coin.png";

import { isDispatchResponseError } from "redux/utils";
import { getUser } from "redux/user/userSlice";
import { APP_URLS } from "navigation";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleLoginPayload } from "../../../types/auth";
import { history } from "../../../helpers/history";
import TextInput from "../../../components/common/form/TextInput";

/**
 * Register page
 */
export default function Register() {
  const dispatch = useAppDispatch();
  const registerErrorMessages = useAppSelector(
    (state) => state.auth.registerErrorMessages,
  );

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [code, setCode] = useState("");
  const [isSignupFieldEmail, setIsSignupFieldEmail] = useState(true);

  const [showOTP, setShowOTP] = useState(false);

  useEffect(() => {
    document.title = "Register | BrainCargo";
  }, []);
  const PRIMARY_TEXT_COLOR_CLASS = "text-stone-200";
  /**
   * Submit form data
   */
  const handleRegister = async () => {
    try {
      if (isSignupFieldEmail) {
        dispatch(register({email, password1, password2}));
      } else {
        dispatch(phoneRegister({phone}));
      }


    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleVerifyOTP = async () => {
    dispatch(verifyOTP({phone, otp: code}));
  };

  const responseMessage = (response: any) => {
    const credential = response?.credential;
    const body: GoogleLoginPayload = {
      auth_token: credential,
    };
    dispatch(googleRegister(body));

  };

  const toggleFields = () => {
    setIsSignupFieldEmail(prevState => !prevState);
  };


  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (showOTP) {
      handleVerifyOTP();
    } else {
      handleRegister();
    }

  };
  return (
    <div className="relative flex flex-col justify-start p-4 w-screen overflow-y-auto min-h-screen bg-[#02041a]">
      <div
        className="w-full p-4 md:p-10 m-auto bg-[#02041a] drop-shadow-xl border border-[#fbfbff] rounded-xl lg:max-w-xl">

        <div className="flex flex-col items-center">
          <h2
            className={`text-xl text-center sm:text-4xl ${PRIMARY_TEXT_COLOR_CLASS} font-extrabold sm:w-2/3 width: -webkit-fill-available;`}
          >
            BRAINCOIN&trade;
          </h2>
          <div
            className="flex sm:flex-row flex-col sm:items-start items-center sm:justify-center justify-stretch py-4 gap-2">
            <img src={CoinImage} height={100} width={100}/>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
        >
          <div className="mt-6">
            {
              isSignupFieldEmail ? <>
                <TextInput
                  label="Email Address"
                  id="email"
                  type="email"
                  required
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="example@domain.com" value={email}></TextInput>
                <TextInput
                  label="Password"
                  id="password1"
                  type="password"
                  required
                  onChange={(event) => setPassword1(event.target.value)}
                  placeholder="***********" value={password1}></TextInput>
              </> :
                <TextInput
                  label="Phone"
                  id="phone"
                  type="phone"
                  readOnly={showOTP}
                  value={phone}
                  required
                  onChange={(event) => setPhone(event.target.value)}
                  placeholder="Phone Number"></TextInput>
            }
            {/*{displayErrors(errorMessages.non_field_errors)}*/}
            {showOTP &&
              <TextInput
                label="Enter Verification Code" id="code"
                value={code}
                type="code"
                required
                onChange={(event) => setCode(event.target.value)}
                placeholder="XXXXXX"></TextInput>}
            <div className="mt-6">
              <button
                type="submit"
                className="w-full px-4 py-3 tracking-wide text-white transition-colors border border-transparent duration-200 transform bg-[linear-gradient(95.49deg,#5d3ef8_-12.23%,#ba4cd6_117.24%)] rounded-full hover:bg-transparent hover:border-[#cec0f3] focus:outline-none focus:bg-blue-600"
              >
                {showOTP ? "Verify Phone" : "Register"}
              </button>
            </div>
          </div>
        </form>
        <div className="relative flex justify-center items-center w-full sm:mt-6 mt-4">
          <div
            className="px-5 flex items-center justify-center w-full text-center text-white relative before:content-[''] before:absolute before:w-[45%] before:h-[1px] before:bg-white before:left-0
          after:content-[''] after:absolute after:w-[45%] after:h-[1px] after:bg-white after:right-0"
          >
            OR
          </div>
        </div>
        <div className="flex justify-center sm:mt-6 mt-4 gap-x-2">
          <GoogleLogin onSuccess={responseMessage} shape="pill" />
        </div>

        <p className="sm:mt-8 mt-6 text-sm font-light text-center text-gray-700">
          {" "}
          Already have an account?{" "}
          <button
            onClick={()=>history.navigate(APP_URLS.LOGIN)}
            className="font-medium text-blue-600 hover:underline"
          >
            Login
          </button>
        </p>
        <p className="mt-2 text-sm font-light text-center text-white">
          {" "}
          Sign Up with{" "}
          <button
            onClick={toggleFields}
            className="font-medium [-webkit-text-fill-color:transparent] bg-[linear-gradient(92.19deg,#cec0f3_.3%,#c38af4_53.07%)] bg-clip-text "
          >
            {isSignupFieldEmail ? "Phone" : "Email"}
          </button>
        </p>
      </div>
    </div>
  );

}
