import { shortenText } from "helpers/text";
import React, { useMemo, useState } from "react";
import { FeedItem } from "types/feed";
import TMDBMovieVideo from "../common/TMDBMovieVideo";
import TMDBMovieRatings from "../common/TMDBMovieRatings";
import TMDBMovieCastItem from "../common/TMDBMovieCastItem";
import HorizontalDivider from "components/common/divider/HorizontalDivider";

interface Props {
  feedItem: FeedItem | null;
}

/**
 * Overview section for TMDBMovie component.
 */
export default function TMDBMovieOverview({ feedItem }: Props) {
  if (!feedItem) return null;

  const [expandDescription, setExpandDescription] = useState(false);
  const trailerVideo = useMemo(() => getTrailerVideo(feedItem), [feedItem]);

  /**
   * Find a movie trailer in the list of videos and generate a link to it.
   */
  function getTrailerVideo(feedItem: Props["feedItem"]) {
    if (!feedItem?.tmdb_data?.videos) {
      return null;
    }

    const video = feedItem.tmdb_data.videos.find(
      (video) => video.type === "Trailer",
    );
    if (!video) {
      return null;
    }

    return video;
  }

  /**
   * Generate genres string from the list of genres.
   */
  function getGenresString(feedItem: Props["feedItem"]) {
    if (!feedItem?.tmdb_data?.genres) {
      return null;
    }

    return feedItem.tmdb_data.genres.map((genre) => genre.name).join("/");
  }

  /**
   * Generate runtime string from the runtime in minutes.
   */
  function getRuntimeString(feedItem: Props["feedItem"]) {
    if (!feedItem?.tmdb_data?.runtime) {
      return null;
    }

    const hours = Math.floor(feedItem.tmdb_data.runtime / 60);
    const minutes = feedItem.tmdb_data.runtime % 60;

    let runtimeString = "";
    if (hours > 0) {
      runtimeString += `${hours}h `;
    }
    if (minutes > 0) {
      runtimeString += `${minutes}m`;
    }

    return runtimeString;
  }

  return (
    <>
      <div>
        <div className="text-lg font-bold leading-5">{feedItem.title}</div>
        <div className="text-sm font-bold text-neutral-400">
          {feedItem.tmdb_data?.release_date} - {getGenresString(feedItem)} -{" "}
          {getRuntimeString(feedItem)}
        </div>
      </div>

      {feedItem.description && (
        <>
          <HorizontalDivider />
          <div>
            <h4 className="text-lg font-bold">Description</h4>
            {expandDescription ? (
              feedItem.description
            ) : (
              <>{shortenText(feedItem.description, 255)} </>
            )}{" "}
            <button
              className="inline text-blue-600"
              onClick={() => setExpandDescription(!expandDescription)}
              aria-label={expandDescription ? "Read less" : "Read more"}
            >
              {expandDescription ? "Read less" : "Read more"}
            </button>
          </div>
        </>
      )}

      {trailerVideo && (
        <>
          <HorizontalDivider />
          <div>
            <h4 className="text-lg font-bold">Trailer</h4>
            <TMDBMovieVideo
              video={getTrailerVideo(feedItem)}
              className="w-full h-[200px]"
            />
          </div>
        </>
      )}

      {feedItem.ratings && feedItem.ratings.length !== 0 && (
        <>
          <HorizontalDivider />
          <div>
            <h4 className="text-lg font-bold">Ratings</h4>
            <TMDBMovieRatings feedItem={feedItem} />
          </div>
        </>
      )}

      {feedItem.tmdb_data?.cast && feedItem.tmdb_data.cast.length > 0 && (
        <>
          <HorizontalDivider />
          <div>
            <h4 className="text-lg font-bold">Cast</h4>
            <div className="flex flex-row space-x-2 overflow-x-auto scrollbar-hide">
              {feedItem.tmdb_data.cast.slice(0, 4).map((cast) => (
                <TMDBMovieCastItem key={cast.id} cast={cast} />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}
