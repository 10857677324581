import React, { useState } from "react";
import { Label, TextInput } from "flowbite-react";
import FileUpload from "components/common/file/FileUpload";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { displayErrors } from "helpers/errors";
import { isDispatchResponseError } from "redux/utils";
import { addDataSet, getDataSets } from "redux/dataSets/dataSetsSlice";
import Modal from "components/common/modal";

interface Props {
  show: boolean;
  onClose: () => void;
}

/**
 * A popup that allows the user to upload a new dataset.
 */
export default function UploadDataSetFormPopup({ show, onClose }: Props) {
  const dispatch = useAppDispatch();
  const errorMessages = useAppSelector((state) => state.dataSets.errorMessages);
  const [name, setName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const pendingAddDataSet = useAppSelector(
    (state) => state.dataSets.pendingAddDataSet,
  );

  /**
   * Submits the form.
   */
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const form_data = new FormData();
    form_data.append("name", name);
    if (file) {
      form_data.append("file", file, file.name);
    }

    const response = await dispatch(addDataSet(form_data));

    // Close the popup if request succeeded
    if (!isDispatchResponseError(response)) {
      dispatch(getDataSets({}));
      setName("");
      setFile(null);
      onClose();
    }
  };

  /**
   * Determine if the form submit button should be disabled.
   */
  function isSubmitDisabled() {
    return !name || !file;
  }

  return (
    <React.Fragment>
      <Modal
        show={show}
        onClose={onClose}
        headerText="Add a new Dataset"
        submitButtonPending={pendingAddDataSet}
        submitButtonDisabled={isSubmitDisabled()}
        onSubmit={handleSubmit}
        body={
          <div className="space-y-4 px-4">
            <form onSubmit={handleSubmit} className="space-y-2">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Dataset Name" />
                </div>
                <TextInput
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="My Dataset"
                />
                {displayErrors(errorMessages.name)}
              </div>
              <div>
                <div className="mb-2 block">
                  <Label value="Upload Dataset File" />
                </div>
                <FileUpload file={file} setFile={setFile} />
                {displayErrors(errorMessages.file)}
              </div>
            </form>
          </div>
        }
      ></Modal>
    </React.Fragment>
  );
}
