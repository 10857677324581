import React, { useState } from "react";
import { ChevronRightIcon, PlusIcon } from "@heroicons/react/24/outline";
import ModalEditCreatePlaylist from "../ModalEditCreatePlaylist";

/**
 * Component user to create a new playlist.
 */
export default function PlaylistListNewPlaylistItem() {
  const [showModal, setShowModal] = useState(false);

  /**
   * Open modal for creating a new playlist.
   */
  function onClick() {
    setShowModal(true);
  }

  /**
   * Close modal for creating a new playlist.
   */
  function onCloseModal() {
    setShowModal(false);
  }

  return (
    <>
      <button
        className="grid grid-cols-6 text-left space-x-4 w-full p-2 pr-0 border-b-2 bg-gray-200"
        onClick={onClick}
        aria-label="Create a new playlist"
      >
        <div className="col-span-1 flex h-full justify-center items-center text-neutral-900">
          <div className="h-[50px]">
            <PlusIcon className="w-full h-full" aria-hidden="true" />
          </div>
        </div>
        <div className="col-span-4 h-full flex flex-col text-gray-600 font-medium justify-center items-start">
          <div className="capitalize font-bold text-xl text-gray-800 w-full truncatetracking-wider">
            Create Playlist
          </div>
        </div>
        <div className="col-span-1 h-full flex justify-center items-center">
          <ChevronRightIcon className="col-span-1 w-[20px] text-gray-500" aria-hidden="true" />
        </div>
      </button>
      <ModalEditCreatePlaylist show={showModal} onClose={onCloseModal} />
    </>
  );
}
