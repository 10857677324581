import FileUpload from "components/common/file/FileUpload";
import { Button, Radio, Spinner, Textarea } from "flowbite-react";
import { displayErrors } from "helpers/errors";
import React, { useState } from "react";
import { createFeedback } from "redux/feedback/feedbackSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { isDispatchResponseError } from "redux/utils";
import { CreateFeedbackPayload, FEEDBACK_TYPE_LABELS } from "types/feedback";

/**
 * Component that renders a form for creating feedback.
 */
export function CreateFeedbackForm() {
  /**
   * Represents the current stage of the form.
   * 0: select feedback type
   * 1: display input fields
   * 2: confirmation
   */
  const [stage, setStage] = useState(0);
  const [type, setType] = useState<CreateFeedbackPayload["type"] | "">("");
  const [file, setFile] = useState<CreateFeedbackPayload["file"]>(null);
  const [description, setDescription] =
    useState<CreateFeedbackPayload["description"]>("");

  const dispatch = useAppDispatch();
  const errorMessagesCreateFeedback = useAppSelector(
    (state) => state.feedback.errorMessagesCreateFeedback,
  );
  const pendingCreateFeedback = useAppSelector(
    (state) => state.feedback.pendingCreateFeedback,
  );

  /**
   * Submits the form.
   */
  async function handleSubmit() {
    const data = new FormData();
    if (description) data.append("description", description);
    if (file) data.append("file", file as File);
    if (type) data.append("type", type);
    const response = await dispatch(createFeedback(data));

    if (!isDispatchResponseError(response)) {
      setStage(2);
    }
  }

  /**
   * Determines whether the next button should be enabled.
   */
  function isNextButtonEnabled() {
    if (stage === 0) {
      return type !== "";
    } else if (stage === 1) {
      return description !== "";
    } else {
      return true;
    }
  }

  return (
    <div className="space-y-4 flex flex-col ">
      {pendingCreateFeedback ? (
        <div className="flex justify-center items-center">
          <Spinner size="xl" />
        </div>
      ) : (
        <>
          {stage === 0 && (
            <>
              <div>What kind of feedback would you like to provide?</div>
              <div className="space-y-1">
                {Object.keys(FEEDBACK_TYPE_LABELS).map((key) => (
                  <div className="flex items-center" key={key}>
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        setType(key as CreateFeedbackPayload["type"])
                      }
                      role="button"
                      tabIndex={0}
                      aria-label={`Select ${FEEDBACK_TYPE_LABELS[key as CreateFeedbackPayload["type"]]}`}
                    >
                      <Radio
                        checked={type === key}
                        onChange={() =>
                          setType(key as CreateFeedbackPayload["type"])
                        }
                        className="mr-2 hover:cursor-pointer focus:ring-opacity-0"
                      />
                      {
                        FEEDBACK_TYPE_LABELS[
                          key as CreateFeedbackPayload["type"]
                        ]
                      }
                    </div>
                  </div>
                ))}
                {displayErrors(errorMessagesCreateFeedback.type)}
              </div>
            </>
          )}
          {stage === 1 && (
            <>
              <div>Please provide a description to your feedback</div>
              <Textarea
                placeholder="Description"
                rows={7}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                aria-label="Feedback description"
              />
              {displayErrors(errorMessagesCreateFeedback.description)}
              <div>
                Would you like to upload a file related to your feedback?
              </div>
              <FileUpload file={file} setFile={setFile} maxSize="50 MB" />
              {displayErrors(errorMessagesCreateFeedback.file)}
            </>
          )}

          {stage === 2 && (
            <>
              <div>Thank you for your feedback!</div>
            </>
          )}
          {stage !== 2 && (
            <div className="flex flex-row justify-center space-x-2 border-t px-6 py-4">
              <Button
                className="flex-1"
                disabled={stage == 0}
                onClick={() => setStage(stage - 1)}
                aria-label="Previous Page"
              >
                Previous Page
              </Button>
              {stage < 1 ? (
                <Button
                  className="flex-1"
                  onClick={() => setStage(stage + 1)}
                  disabled={!isNextButtonEnabled()}
                  title={
                    isNextButtonEnabled()
                      ? undefined
                      : "Answer all required questions before continuing"
                  }
                  aria-label="Next Page"
                >
                  Next Page
                </Button>
              ) : (
                <Button
                  className="flex-1"
                  onClick={handleSubmit}
                  disabled={!isNextButtonEnabled()}
                  title={
                    isNextButtonEnabled()
                      ? undefined
                      : "Answer all required questions before continuing"
                  }
                  aria-label="Submit Feedback"
                >
                  {pendingCreateFeedback ? <Spinner size="sm" /> : "Submit"}
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
