import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React from "react";

interface Props {
  value: string;
  onChange: (val: string) => void;
}

/**
 * A search input.
 */
export default function SearchInput({ value, onChange }: Props) {
  return (
    <div className="w-full flex flex-row rounded-xl border-gray-200 border-[1px] bg-white justify-center items-center">
      <MagnifyingGlassIcon className="inline h-6 text-gray-500 px-2" aria-hidden="true" aria-label="Search icon" />
      <input
        type="text"
        className="w-full border-0 rounded-r-full bg-transparent pl-0 focus:ring-0"
        placeholder="Search"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        aria-label="Search input"
        role="searchbox"
      />
    </div>
  );
}
