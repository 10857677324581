import React from "react";
import { Playlist } from "types/playlist";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { selectLatestPlaylistItemImg } from "helpers/playlist";
import { APP_URLS } from "navigation";
import { replaceSpecialChars } from "helpers/text";

interface Props {
  playlist: Playlist;
}

/**
 * Component used for displaying a playlist item in PlaylistListMobile.
 */
export default function PlaylistListItem({ playlist }: Props) {
  /**
   * Get the name of the playlist.
   */
  function getPlaylistName(playlist: Props["playlist"]) {
    return replaceSpecialChars(playlist.name || "Unknown");
  }

  /**
   * Get the subtitle of the playlist.
   */
  function getSubtitle(playlist: Props["playlist"]) {
    const isPrivate = playlist.shared_with.length === 0;
    return isPrivate ? (
      <div>{`Private | ${playlist.items.length} items`}</div>
    ) : (
      <>
        <div>{`Shared with ${playlist.shared_with.length} users`}</div>
        <div>{`${playlist.items.length} items`}</div>
      </>
    );
  }

  /**
   * Navigate to playlist details.
   */
  function onClick() {
    const playlistDetailsUrl = APP_URLS.PLAYLIST_DETAILS_MOBILE.replace(
      ":id",
      playlist.id,
    );
    window.location.href = playlistDetailsUrl;
  }

  return (
    <button
      className="grid grid-cols-6 text-left space-x-4 w-full p-2 pr-0 border-b-2"
      onClick={onClick}
      aria-label={`View details for playlist ${getPlaylistName(playlist)}`}
    >
      <div className="col-span-1 flex h-full justify-center items-center">
        <img
          src={selectLatestPlaylistItemImg(playlist)}
          className="rounded max-h-[65px]"
          alt={`Thumbnail for playlist ${getPlaylistName(playlist)}`}
        />
      </div>
      <div className="col-span-4 h-full flex flex-col text-gray-600 font-medium justify-center items-start">
        <div className="capitalize font-bold text-xl text-gray-800 w-full truncatetracking-wider">
          {getPlaylistName(playlist)}
        </div>
        <div className="font-semibold text-base text-gray-400 w-full truncate">
          {getSubtitle(playlist)}
        </div>
      </div>
      <div className="col-span-1 h-full flex justify-center items-center">
        <ChevronRightIcon className="col-span-1 w-[20px] text-gray-500" aria-hidden="true" />
      </div>
    </button>
  );
}
