import { generateSpotifyUrl } from "helpers/spotify";
import {
  createSpotifyPlayer,
  spotifyPlayerPause,
  spotifyPlayerPlay,
  updateSpotifyPlayerUri,
} from "helpers/spotifyIFrameAPI";
import React, { CSSProperties, useEffect, useState } from "react";
import { increaseWatchedFor, watchFeedItem } from "redux/feed/feedItemsSlice";
import { useAppDispatch } from "redux/hooks";
import { FeedItem } from "types/feed";

interface Props {
  id: string;
  className?: string;
  style: CSSProperties;
  data: { playing: boolean; feedItem: FeedItem | null };
  spotifyIFrameAPI: any;
}

/**
 * Component for displaying a Spotify track in ScrollableMobileFeed.
 */
export default function SpotifyTrack({
  id,
  className,
  style,
  data,
  spotifyIFrameAPI,
}: Props) {
  const dispatch = useAppDispatch();
  const [progressSeconds, setProgressSeconds] = useState(0);
  const spotifyIframeId = id + "SpotifyIframe";
  let watchTrackTriggered = false;
  let prevPositionSeconds = 0;
  let prevProgressSeconds = 0;

  /**
   * Increases the watched for time for the feed item.
   */
  function updateWatchedFor() {
    // Update watched_for state
    if (progressSeconds > 0 && data.feedItem) {
      dispatch(
        increaseWatchedFor({
          feed_item: data.feedItem.id,
          watched_for: progressSeconds,
          watched: true,
        }),
      );
      setProgressSeconds(0);
    }
  }

  /**
   * Handles playback updates from the Spotify player.
   */
  function onPlaybackUpdate({
    data: playbackData,
  }: {
    data: {
      duration: number;
      isBuffering: boolean;
      isPaused: boolean;
      position: number;
    };
  }) {
    if (!playbackData.isPaused && !watchTrackTriggered && data.feedItem) {
      dispatch(watchFeedItem(data.feedItem.id || ""));
      watchTrackTriggered = true;
    }

    if (playbackData.isPaused) {
      prevPositionSeconds = 0;
      prevProgressSeconds = 0;
      return;
    }

    const positionSeconds = playbackData.position / 1000;
    const newProgressSeconds = positionSeconds - prevPositionSeconds;
    if (newProgressSeconds > 0) {
      if (newProgressSeconds < 2) {
        // if newProgressSeconds >= 2sec then we can assume that the user has seeked forward
        // We need to ignore that case
        setProgressSeconds(prevProgressSeconds + newProgressSeconds);
        prevProgressSeconds = prevProgressSeconds + newProgressSeconds;
      }
    }
    prevPositionSeconds = positionSeconds;
  }

  /**
   * Creates the Spotify player.
   */
  useEffect(() => {
    if (spotifyIFrameAPI && data.feedItem) {
      createSpotifyPlayer({
        elementId: spotifyIframeId,
        spotifyIFrameAPI,
        uri: generateSpotifyUrl(data.feedItem.spotify_track_id),
        onPlaybackUpdate,
      });
    }
  }, [spotifyIFrameAPI, data.feedItem]);

  /**
   * Updates the Spotify player's uri when the feed item changes.
   */
  useEffect(() => {
    if (data.feedItem) {
      updateSpotifyPlayerUri({
        elementId: spotifyIframeId,
        uri: generateSpotifyUrl(data.feedItem.spotify_track_id),
      });
    }
  }, [data.feedItem]);

  /**
   * Handles the play/pause state of the Spotify player.
   */
  useEffect(() => {
    if (data.playing) {
      spotifyPlayerPlay({ elementId: spotifyIframeId });
    } else {
      spotifyPlayerPause({ elementId: spotifyIframeId });
      updateWatchedFor();
    }
  }, [data.playing]);

  return (
    <div
      className={`flex justify-center items-center ${className}`}
      style={style}
      id={id}
    >
      <div id={spotifyIframeId}></div>
    </div>
  );
}
