import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUsers as fetchUsersService } from "services/usersService";
import { ErrorMessages } from "types/redux/slice";
import { UserRestricted } from "types/user";

interface UsersState {
  pending: boolean;
  errorMessages: ErrorMessages;
  users: UserRestricted[];
}

const initialState: UsersState = {
  pending: false,
  errorMessages: {},
  users: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state, action) => {
      state.pending = true;
      state.errorMessages = {};
      state.users = [];
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.pending = false;
      state.errorMessages = {};
      state.users = action.payload;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.pending = false;
      state.errorMessages = action.payload || {};
      state.users = [];
    });
  },
});

export const fetchUsers = createAsyncThunk<
  UserRestricted[],
  string | undefined,
  { rejectValue: ErrorMessages }
>("users/fetchUsers", async (query, thunkApi) => {
  try {
    // Request the currently logged-in user with Django API
    const response = await fetchUsersService(query);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});
