import {
  Button,
  Modal as FBModal,
  Spinner,
} from "flowbite-react";
import React from "react";

interface Props {
  show: boolean;
  onClose: () => void;
  body: JSX.Element | JSX.Element[];
  onSubmit?: (param?: any) => void;
  submitButtonDisabled?: boolean;
  submitButtonPending?: boolean;
  submitButtonText?: string;
  submitButtonColor?: string | undefined;
  customFooter?: JSX.Element;
  headerText?: string;
  hideFooter?: boolean;
}

/**
 * A modal component.
 */
export default function Modal({
  show,
  onClose,
  onSubmit,
  body,
  submitButtonDisabled,
  submitButtonPending,
  submitButtonText = "Submit",
  submitButtonColor = "blue",
  customFooter,
  headerText,
  hideFooter,
}: Props) {
  return (
    <FBModal
      show={show}
      dismissible
      onClose={onClose}
      style={{ zIndex: 100, height: "calc(100%)" }}
      position="center"
      aria-label="Modal"
    >
      {headerText && (
        <FBModal.Header className="text-xl font-medium text-gray-900">
          {headerText}
        </FBModal.Header>
      )}

      <FBModal.Body className="space-y-4">{body}</FBModal.Body>

      {!hideFooter && (
        <FBModal.Footer>
          {customFooter ? (
            customFooter
          ) : (
            <Button
              className="px-10 m-auto"
              onClick={onSubmit}
              disabled={submitButtonDisabled}
              color={submitButtonColor}
              aria-label={submitButtonText}
            >
              {submitButtonPending ? <Spinner size="sm" aria-label="Loading" /> : submitButtonText}
            </Button>
          )}
        </FBModal.Footer>
      )}
    </FBModal>
  );
}
