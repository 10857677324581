import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ErrorMessages } from "types/redux/slice";
import * as newsletterService from "services/newsletterService";

interface NewsletterState {
  pendingEmail: boolean;
  pendingPhone: boolean;
  errorMessagesEmail: ErrorMessages;
  errorMessagesPhone: ErrorMessages;
}

const initialState: NewsletterState = {
  pendingEmail: false,
  pendingPhone: false,
  errorMessagesEmail: {},
  errorMessagesPhone: {},
};

export const newsletterSlice = createSlice({
  name: "newsletter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(submitEmail.pending, (state, action) => {
      state.pendingEmail = true;
      state.errorMessagesEmail = {};
    });
    builder.addCase(submitEmail.fulfilled, (state, action) => {
      state.pendingEmail = false;
      state.errorMessagesEmail = {};
    });
    builder.addCase(submitEmail.rejected, (state, action) => {
      state.pendingEmail = false;
      state.errorMessagesEmail = action.payload || {};
    });

    builder.addCase(submitPhone.pending, (state, action) => {
      state.pendingPhone = true;
      state.errorMessagesPhone = {};
    });
    builder.addCase(submitPhone.fulfilled, (state, action) => {
      state.pendingPhone = false;
      state.errorMessagesPhone = {};
    });
    builder.addCase(submitPhone.rejected, (state, action) => {
      state.pendingPhone = false;
      state.errorMessagesPhone = action.payload || {};
    });
  },
});

export const submitEmail = createAsyncThunk<
  boolean,
  string,
  { rejectValue: ErrorMessages }
>("newsletter/submitEmail", async (email, thunkApi) => {
  try {
    let errors = {};
    if (!email) {
      errors = { email: ["Please provide your email"] };
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors = { email: ["Invalid email address"] };
    }
    if (Object.keys(errors).length > 0) throw errors;

    await newsletterService.submitEmailOrPhone({ email });
    return true;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const submitPhone = createAsyncThunk<
  boolean,
  string,
  { rejectValue: ErrorMessages }
>("newsletter/submitPhone", async (phone, thunkApi) => {
  try {
    let errors = {};
    if (!phone) {
      errors = { phone: ["Please provide your phone number"] };
    } else if (!/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(phone)) {
      errors = { phone: ["Invalid phone number"] };
    }
    if (Object.keys(errors).length > 0) throw errors;

    await newsletterService.submitEmailOrPhone({ phone });
    return true;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});
