import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/solid";
import React from "react";
import { PAGE_SIZE } from "services/apiService";

interface Props {
  count: number;
  page: number;
  previous: string | null;
  getPreviousPage: () => void;
  next: string | null;
  getNextPage: () => void;
}

/**
 * Pagination component.
 */
export default function Pagination({
  count,
  page,
  previous,
  getPreviousPage,
  next,
  getNextPage,
}: Props) {
  const getStart = () => {
    return 1 + page * PAGE_SIZE;
  };

  /**
   * Get the number of the last item on the page.
   */
  const getEnd = () => {
    return count <= 1 + page * PAGE_SIZE + (PAGE_SIZE - 1)
      ? count
      : 1 + page * PAGE_SIZE + (PAGE_SIZE - 1);
  };

  return (
    <div className="flex flex-col py-4 items-center" role="navigation" aria-label="Pagination">
      <span className="text-sm text-gray-700 dark:text-gray-400">
        Showing{" "}
        <span className="font-semibold text-gray-900">{getStart()}</span> to{" "}
        <span className="font-semibold text-gray-900">{getEnd()}</span> of{" "}
        <span className="font-semibold text-gray-900">{count}</span> Entries
      </span>
      <div className="inline-flex mt-2 xs:mt-0">
        <button
          onClick={getPreviousPage}
          disabled={!previous}
          className={`inline-flex items-center px-4 py-2 text-sm font-medium text-white rounded-l ${
            previous ? "bg-gray-800 hover:bg-gray-900" : "bg-gray-500"
          }`}
          aria-label="Previous page"
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              getPreviousPage();
            }
          }}
        >
          <ArrowLongLeftIcon className="w-5 h-5 mr-2" />
          Prev
        </button>
        <button
          onClick={getNextPage}
          disabled={!next}
          className={`inline-flex items-center px-4 py-2 text-sm font-medium text-white rounded-r ${
            next ? "bg-gray-800 hover:bg-gray-900" : "bg-gray-500"
          }`}
          aria-label="Next page"
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              getNextPage();
            }
          }}
        >
          Next
          <ArrowLongRightIcon className="w-5 h-5 ml-2" />
        </button>
      </div>
    </div>
  );
}
