import React from "react";
import PageWrapper from "components/common/pageWrapper";
import { XCircleIcon } from "@heroicons/react/24/outline";

/**
 * Payment cancelled page
 */
export default function PaymentCancelled() {
  return (
    <div className="flex justify-center items-center m-auto h-screen">
      <div className="flex flex-col justify-center items-center border-2 border-red-500 rounded-xl p-8">
        <XCircleIcon className="text-red-500 w-20 h-20" />
        <div className="text-red-500 text-2xl font-bold">Payment Cancelled</div>
        <div className="text-gray-500 text-base font-semibold">
          You have cancelled the checkout process
        </div>
        <a
          href="/profile"
          className="text-blue-500 text-base font-semibold mt-4"
        >
          Return to profile page
        </a>
      </div>
    </div>
  );
}
