import React from "react";
import { Playlist } from "types/playlist";
import NoImage from "images/No-Image.png";
import { BookmarkIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { selectLatestPlaylistItemImg } from "helpers/playlist";
import { FeedItem } from "types/feed";
import { APP_URLS } from "navigation";

interface Props {
  playlist: Playlist;
  itemId: FeedItem["id"];
  handleAddToPlaylist: (playlist: Playlist) => void;
  handleRemoveFromPlaylist: (playlist: Playlist) => void;
}

/**
 * Component for displaying a playlist item in the PlaylistDrawer.
 */
export default function PlaylistDrawerItem({
  playlist,
  itemId,
  handleAddToPlaylist,
  handleRemoveFromPlaylist,
}: Props) {
  const imageSize = 64;
  const isPrivate = playlist.shared_with.length === 0;
  const subtitle = isPrivate
    ? `Private | ${playlist.items.length} items`
    : `Shared with ${playlist.shared_with.length} users | ${playlist.items.length} items`;

  /**
   * Checks if the item is in the playlist.
   */
  function isItemInPlaylist() {
    const items = playlist.items;
    return items.some((item) => item.feed_item?.id === itemId);
  }

  const image = selectLatestPlaylistItemImg(playlist);

  return (
    <div className="flex flex-row items-center" aria-label={`Playlist item ${playlist.name}`}>
      <a
        className="flex flex-row items-center"
        href={APP_URLS.PLAYLIST_DETAILS_MOBILE.replace(":id", playlist.id)}
        aria-label={`View details of playlist ${playlist.name}`}
      >
        <div className="pr-4">
          <img
            src={image}
            className={`rounded-xl overflow-hidden box-border object-cover h-[${imageSize}px] w-[${imageSize}px] ${
              image === NoImage ? "border-2" : ""
            }`}
            alt={`Image for playlist ${playlist.name}`}
          />
        </div>
        <div>
          <div className="capitalize font-bold text-xl text-gray-800">
            {playlist.name}
          </div>
          <div className="font-semibold text-gray-400">{subtitle}</div>
        </div>
      </a>
      <div className="ml-auto">
        {isItemInPlaylist() ? (
          <BookmarkIcon
            height={36}
            className="text-gray-400"
            onClick={() => handleRemoveFromPlaylist(playlist)}
            aria-label={`Remove item from playlist ${playlist.name}`}
            role="button"
          />
        ) : (
          <PlusCircleIcon
            height={36}
            className="text-gray-400"
            onClick={() => handleAddToPlaylist(playlist)}
            aria-label={`Add item to playlist ${playlist.name}`}
            role="button"
          />
        )}
      </div>
    </div>
  );
}
