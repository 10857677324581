import { Spinner } from "flowbite-react";
import React, { ReactElement } from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { FEEDBACK_TYPE_LABELS, Feedback } from "types/feedback";

interface Props {
  feedback: Feedback | null;
  pending: boolean;
}

/**
 * Component that displays details about a feedback.
 */
export default function FeedbackDetails({ feedback, pending }: Props) {
  /**
   * Renders a row of the feedback details.
   */
  function renderRow(label: string, value?: string | number | boolean) {
    let renderedValue: undefined | string | number | boolean | ReactElement =
      value;
    if (typeof value === "boolean") {
      renderedValue = value ? (
        <CheckCircleIcon className="w-8 text-green-500" aria-label="Yes" />
      ) : (
        <XCircleIcon className="w-8 text-red-500" aria-label="No" />
      );
    } else if (!value && value !== 0) {
      renderedValue = "-";
    } else if (
      typeof value === "string" &&
      (value.includes("http://") || value.includes("https://"))
    ) {
      renderedValue = (
        <a
          href={value}
          className="text-blue-600 font-medium underline cursor-pointer break-all"
          target="_blank"
          rel="noreferrer"
          aria-label={`Link to ${value}`}
        >
          {value}
        </a>
      );
    }
    return (
      <div className="grid grid-cols-2 border-b-2 items-center">
        <div className="text-gray-500 text-base py-2 font-semibold mr-8">
          {label}
        </div>
        <div className="text-gray-500 text-base py-2 break-word">
          {renderedValue}
        </div>
      </div>
    );
  }

  /**
   * Display a loading indicator if pending.
   */
  if (pending) {
    return (
      <div className="flex justify-center" role="status" aria-live="polite">
        <Spinner aria-label="Loading" />
      </div>
    );
  }

  /**
   * Display an error message if there's no feedback.
   */
  if (!feedback) {
    return (
      <div className="flex justify-center" role="alert" aria-live="assertive">
        Failed to retrieve feedback information
      </div>
    );
  }

  /**
   * Display feedback details.
   */
  return (
    <div className="flex justify-center">
      {pending ? (
        <Spinner aria-label="Loading" />
      ) : (
        <div className="w-full lg:w-1/2" role="region" aria-live="polite">
          {renderRow("ID:", feedback.id)}
          {renderRow("Created by:", feedback.user.username)}
          {renderRow("Type:", FEEDBACK_TYPE_LABELS[feedback.type])}
          {renderRow(
            "File:",
            feedback.file || "No file attached to this feedback",
          )}
          {renderRow("Description:", feedback.description)}
        </div>
      )}
    </div>
  );
}
