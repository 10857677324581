import React, { useEffect, useRef, useState } from "react";
import {
  ChevronDoubleUpIcon,
  FunnelIcon,
  HomeIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { APP_URLS } from "navigation";
import BrainCoinIcon from "images/Navbar-Icon-Gold.png";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useDebounce } from "use-debounce";
import {
  searchForFeedItems,
  setExpandFeedMenu,
} from "redux/feed/feedItemsSlice";
import MobileTopNavBarSearchResults from "./MobileTopNavBarSearchResults";
import MobileTopNavBarItem from "./MobileTopNavBarItem";
import { getUser } from "redux/user/userSlice";
import MobileNavBarExpandableMenu from "../common/MobileNavBarExpandableMenu";
import MobileNavBarFeedTypeSelection from "../common/MobileNavBarFeedTypeSelection";
import MobileNavBarDataSetSelection from "../common/MobileNavBarDataSetSelection";
import {history} from "helpers/history";

const ICON_CLASSNAME = "w-6 h-6 text-gray-500 transition duration-75";
export const MOBILE_TOP_NAV_BAR_HEIGHT = "50px";

/**
 * The top navigation bar for mobile devices.
 */
export default function MobileTopNavBar() {
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchInteractedWith, setSearchInteractedWith] = useState(false);
  const feedFilterTypes = useAppSelector(
    (state) => state.feedFilterTypes.feedFilterTypes,
  );
  const user = useAppSelector((state) => state.user.user);
  const expandFeedMenu = useAppSelector(
    (state) => state.feedItems.expandFeedMenu,
  );
  const [pendingClickSearchItem, setPendingClickSearchItem] = useState(false);
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const navRef = useRef<HTMLDivElement | null>(null);


  /**
   * Handle clicking the home button.
   */
  function handleClickHome() {
    dispatch(setExpandFeedMenu(false));
    history.navigate(APP_URLS.LANDING_PAGE);
  }

  /**
   * Handle searching for feed items.
   */
  useEffect(() => {
    if (debouncedSearchQuery) {
      dispatch(
        searchForFeedItems({
          queryParams: {
            query: debouncedSearchQuery,
            types: feedFilterTypes.join(","),
          },
        }),
      );
      setSearchInteractedWith(true);
    }
  }, [debouncedSearchQuery]);

  /**
   * Fetch the user.
   */
  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <nav
      className={
        "fixed top-0 left-0 z-50 w-screen bg-gray-100 border-b-2 border-gray-200"
      }
      ref={navRef}
      aria-label="Mobile top navigation bar"
    >
      <div className="flex flex-row items-center pl-2">
        <div className="ml-auto flex flex-row gap-2">
          <MobileTopNavBarItem
            onClick={handleClickHome}
            icon={<HomeIcon className={ICON_CLASSNAME} />}
          />
          <MobileTopNavBarItem
            onClick={() => dispatch(setExpandFeedMenu(!expandFeedMenu))}
            icon={
              expandFeedMenu ? (
                <ChevronDoubleUpIcon className={ICON_CLASSNAME} />
              ) : (
                <FunnelIcon className={ICON_CLASSNAME} />
              )
            }
          />
        </div>
        <div className="w-full flex flex-row rounded-full border-gray-200 mx-1 border-[1px] bg-white justify-center items-center">
          <MagnifyingGlassIcon className="inline h-6 text-gray-500 px-2" />
          <input
            type="text"
            className="w-full border-0 rounded-r-full bg-transparent pl-0 focus:ring-0"
            placeholder="Search"
            onClick={() => setShowSearchResults(true)}
            onChange={(e) => setSearchQuery(e.target.value)}
            aria-label="Search"
          />
        </div>
        <a
          className="flex flex-row shrink-0 justify-center items-center border-gray-200 border-l-2 rounded-l-full pr-1 pl-1 bg-gray-50"
          href={APP_URLS.PROFILE}
          aria-label="Profile"
        >
          <img src={BrainCoinIcon} className="h-12 inline-block" alt="Profile" />
        </a>
      </div>
      {showSearchResults && searchQuery && (
        <MobileTopNavBarSearchResults
          pendingClickSearchItem={pendingClickSearchItem}
          navRef={navRef}
          searchInteractedWith={searchInteractedWith}
          setPendingClickSearchItem={setPendingClickSearchItem}
          setShowSearchResults={setShowSearchResults}
        />
      )}

      <MobileNavBarExpandableMenu expanded={expandFeedMenu} gridCols={3}>
        <MobileNavBarFeedTypeSelection feedFilterTypes={feedFilterTypes} />
        <MobileNavBarDataSetSelection />
      </MobileNavBarExpandableMenu>
    </nav>
  );
}
