import { ConnectedAdvertiser } from "types/brainCoin";
import { get, post } from "./apiService";

/**
 * Send a GET request to the API to retrieve all advertisers
 */
export const retrieveAdvertisers = async () => {
  return await get("/api/brain_coin/advertisers/");
};

/**
 * Send a GET request to the API to retrieve all advertisers connected to the user
 */
export const retrieveConnectedAdvertisers = async () => {
  return await get("/api/brain_coin/connected_advertisers/");
};

/**
 * Send a POST request to the API to set connected advertisers
 */
export const setConnectedAdvertisers = async (
  data: Array<ConnectedAdvertiser>,
) => {
  return await post("/api/brain_coin/connected_advertisers/", data);
};
