import React from "react";

/**
 * Component that renders a separator in DesktopNavBar.
 */
export default function DesktopNavBarSeparator() {
  return (
    <div className="flex items-center justify-center p-2 text-base font-normal text-gray-400 gap-1 select-none">
      <div className="block h-[1px] w-full bg-gray-300 rounded-full"></div>
      <span>Admin</span>
      <div className="block h-[1px] w-full bg-gray-300 rounded-full"></div>
    </div>
  );
}
