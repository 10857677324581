import React, { useEffect } from "react";
import {
  retrieveNewsItemsDataset,
  retrieveTracksDataset,
  retrieveUserDataset,
  retrieveVideosDataset,
} from "redux/feed/feedDatasetSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import FeedDataTable from "../common/FeedDataTable";
import UserData from "../common/UserData";
import { generateYoutubeUrl } from "helpers/youtube";
import { APP_URLS } from "navigation";
import { formatDateTime, formatSeconds } from "helpers/date";
import { generateSpotifyUrl } from "helpers/spotify";

interface Props {
  userId: string;
}

/**
 * Component for displaying feed data details of a user.
 */
export default function FeedDataUserDetails({ userId }: Props) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.feedDataset.user);
  const pending = useAppSelector(
    (state) => state.feedDataset.retrieveUserDatasetPending,
  );

  function onClickVideosRow(itemId: string) {
    // Navigate to video details page
    window.location.href = APP_URLS.FEED_DATA_VIDEO_DETAILS.replace(
      ":videoId",
      itemId,
    );
  }

  function onClickTracksRow(itemId: string) {
    // Navigate to track details page
    window.location.href = APP_URLS.FEED_DATA_TRACK_DETAILS.replace(
      ":trackId",
      itemId,
    );
  }

  function onClickNewsRow(itemId: string) {
    // Navigate to news details page
    window.location.href = APP_URLS.FEED_DATA_NEWS_DETAILS.replace(
      ":newsId",
      itemId,
    );
  }

  function fetchData() {
    if (userId) {
      dispatch(retrieveUserDataset({ userId }));
    }
  }

  useEffect(() => {
    fetchData();
  }, [userId]);

  return (
    <div className="grid grid-cols-1 p-4 gap-4">
      <UserData user={user} pending={pending} />

      <div className="col-span-1 border rounded p-2 bg-gray-100" aria-label="Videos user interacted with">
        <FeedDataTable
          headerText={`Videos ${user?.username || "user"} interacted with`}
          defaultOrdering="title"
          paginatedList={useAppSelector((state) => state.feedDataset.videos)}
          fetchThunkFunction={retrieveVideosDataset}
          pending={useAppSelector(
            (state) => state.feedDataset.retrieveVideosDatasetPending,
          )}
          labels={{
            id: "ID",
            title: "Title",
            youtube_video_id: "Link",
            liked: "Liked",
            liked_at: "Liked at",
            disliked: "Disliked",
            disliked_at: "Disliked at",
            watched: "Watched",
            watched_at: "Watched at",
            watched_for: "Watched for",
            skipped: "Skipped",
          }}
          additionalQueryParams={{ user_id: userId }}
          onClickRow={onClickVideosRow}
          valueOverrides={{
            youtube_video_id: generateYoutubeUrl,
            liked_at: formatDateTime,
            disliked_at: formatDateTime,
            watched_at: formatDateTime,
            skipped_at: formatDateTime,
            watched_for: formatSeconds,
          }}
        />
      </div>

      <div className="col-span-1 border rounded p-2 bg-gray-100" aria-label="Tracks user interacted with">
        <FeedDataTable
          headerText={`Tracks ${user?.username || "user"} interacted with`}
          defaultOrdering="title"
          paginatedList={useAppSelector((state) => state.feedDataset.tracks)}
          fetchThunkFunction={retrieveTracksDataset}
          pending={useAppSelector(
            (state) => state.feedDataset.retrieveTracksDatasetPending,
          )}
          labels={{
            id: "ID",
            title: "Title",
            spotify_track_id: "Link",
            liked: "Liked",
            liked_at: "Liked at",
            disliked: "Disliked",
            disliked_at: "Disliked at",
            watched: "Watched",
            watched_at: "Watched at",
            watched_for: "Watched for",
            skipped: "Skipped",
          }}
          additionalQueryParams={{ user_id: userId }}
          onClickRow={onClickTracksRow}
          valueOverrides={{
            spotify_track_id: generateSpotifyUrl,
            liked_at: formatDateTime,
            disliked_at: formatDateTime,
            watched_at: formatDateTime,
            skipped_at: formatDateTime,
            watched_for: formatSeconds,
          }}
        />
      </div>

      <div className="col-span-1 border rounded p-2 bg-gray-100"  aria-label="Feed Data Table">
        <FeedDataTable
          headerText={`News ${user?.username || "user"} interacted with`}
          defaultOrdering="title"
          paginatedList={useAppSelector((state) => state.feedDataset.newsItems)}
          fetchThunkFunction={retrieveNewsItemsDataset}
          pending={useAppSelector(
            (state) => state.feedDataset.retrieveNewsItemsDatasetPending,
          )}
          labels={{
            id: "ID",
            title: "Title",
            bing_news_url: "Link",
            liked: "Liked",
            liked_at: "Liked at",
            disliked: "Disliked",
            disliked_at: "Disliked at",
            watched: "Watched",
            watched_at: "Watched at",
            watched_for: "Watched for",
            skipped: "Skipped",
          }}
          additionalQueryParams={{ user_id: userId }}
          onClickRow={onClickNewsRow}
          valueOverrides={{
            liked_at: formatDateTime,
            disliked_at: formatDateTime,
            watched_at: formatDateTime,
            skipped_at: formatDateTime,
            watched_for: formatSeconds,
          }}
        />
      </div>
    </div>
  );
}
