import { CheckoutSession, StripeNFTProduct } from "types/payments";
import { post, get } from "./apiService";

/**
 * Send a POST request to the API to retrieve a checkout url
 */
export const fetchCheckoutUrl = async ({
  priceId,
  walletAddress,
}: {
  priceId: string;
  walletAddress: string;
}): Promise<string> => {

  const response = await post("/api/payments/create-checkout-session-url/", {
    price_id: priceId,
    wallet_address: walletAddress,
  });
  return response?.data || "";
};

/**
 * Send a POST request to the API to retrieve a NFT-related stripe products
 */
export const fetchNFTProducts = async (): Promise<Array<StripeNFTProduct>> => {
  const response = await get("/api/payments/get-nft-products/");
  return response?.data || [];
};

/**
 * Retrieve a user's checkout sessions which hold data about the NFTs they've purchased and can redeem
 */
export const fetchCheckoutSessions = async (): Promise<Array<
  CheckoutSession
>> => {
  return await get("/api/payments/checkout-sessions/");
};

/**
 * Redeem an NFT by sending a POST request to the API
 */
export const redeemNFT = async (checkoutSessionId: CheckoutSession["id"]) => {
  return await post(
    `/api/payments/checkout-sessions/${checkoutSessionId}/redeem-nft/`,
    {},
  );
};
