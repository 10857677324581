import React, { ReactNode } from "react";

export const MOBILE_TOP_NAV_BAR_HEIGHT = "50px";

/**
 * Component that renders a list item used in MobileTopNavBar.
 */
export default function MobileTopNavBarItem({
  href,
  icon,
  onClick,
}: {
  href?: string;
  icon: ReactNode;
  onClick?: () => void;
}) {
  return (
    <div className="flex justify-center">
      <a
        href={href}
        onClick={onClick}
        className={
          "flex flex-col items-center text-base font-normal text-gray-900"
        }
        aria-label="Mobile Top Navigation Bar Item"
      >
        <span
          className={"rounded-2xl border-2 p-2 border-gray-200 bg-gray-50"}
          aria-hidden="true"
        >
          {icon}
        </span>
      </a>
    </div>
  );
}
