import React from "react";

/**
 * Display errors in a list.
 */
export const displayErrors = (errors: string | Array<string> | undefined) => {
  if (errors) {
    if (typeof errors === "string") {
      // Wrap errors in array if errors is a string
      errors = [errors];
    }
    if (errors && errors.length > 0) {
      return errors.map((error, idx) => (
        <span key={idx} className="flex text-sm text-red-700 font-medium">
          {error}
        </span>
      ));
    }
  }
};
