import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ModalEditCreatePlaylist from "components/playlist/ModalEditCreatePlaylist";
import SelectPlaylistPopup from "components/playlist/SelectPlaylistPopup";
import PageWrapper from "components/common/pageWrapper";
import Video from "components/common/video";
import { isDispatchResponseError } from "redux/utils";
import { PlusIcon } from "@heroicons/react/24/solid";
import {
  FlagIcon,
  ForwardIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import MyPlaylistList from "components/playlist/MyPlaylistList";
import SharedPlaylistList from "components/playlist/SharedPlaylistList";
import Header from "components/common/header";
import { retrieveConnectedAdvertisers } from "redux/brainCoin/brainCoinSlice";
import AdByRandomProvider from "components/ads/AdByRandomProvider";
import { getIsSmScreen } from "helpers/responsiveness";
import { APP_URLS } from "navigation";
import { useIsSmScreen } from "hooks/useIsSmScreen";
import { generateYoutubeUrl } from "helpers/youtube";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import {
  dislikeFeedItem,
  getDislikedFeedItems,
  getLikedFeedItems,
  getRandomFeedItem,
  increaseWatchedFor,
  likeFeedItem,
  skipFeedItem,
  watchFeedItem,
} from "redux/feed/feedItemsSlice";
import { FEED_ITEM_TYPES } from "types/feed";
import { ReportFeedItemModal } from "components/report/ReportFeedItemModal";

/**
 * Videos page
 */
export default function Videos() {
  const dispatch = useAppDispatch();
  const isSmScreen = useIsSmScreen();
  const feedItem = useAppSelector((state) => state.feedItems.feedItem);
  const likedFeedItems = useAppSelector(
    (state) => state.feedItems.likedFeedItems,
  );
  const dislikedFeedItems = useAppSelector(
    (state) => state.feedItems.dislikedFeedItems,
  );
  const [showPlaylistPopup, setShowPlaylistPopup] = useState<boolean>(false);
  const [showSelectPlaylistPopup, setShowSelectPlaylistPopup] =
    useState<boolean>(false);
  const [progressSeconds, setProgressSeconds] = useState(0);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);

  let prevProgressState: OnProgressProps = {
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
  };

  /**
   * Increase watched_for time for the current feed item
   */
  function updateWatchedFor() {
    if (feedItem && progressSeconds > 0) {
      dispatch(
        increaseWatchedFor({
          feed_item: feedItem.id,
          watched_for: progressSeconds,
          watched: true,
        }),
      );
      setProgressSeconds(0);
    }
  }

  /**
   * Mark feed item as liked
   */
  const handleLikeFeedItem = async () => {
    const response = await dispatch(likeFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      // Update how long user has watched this media
      updateWatchedFor();

      // Retrieve a new random video
      dispatch(
        getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.VIDEO } }),
      );
    }
  };

  /**
   * Mark feed item as disliked
   */
  const handleDislikeFeedItem = async () => {
    const response = await dispatch(dislikeFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      // Update how long user has watched this media
      updateWatchedFor();

      // Retrieve a new random video
      dispatch(
        getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.VIDEO } }),
      );
    }
  };

  /**
   * Mark feed item as skipped
   */
  const handleSkipFeedItem = async () => {
    const response = await dispatch(skipFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      // Update how long user has watched this media
      updateWatchedFor();

      // Retrieve a new random video
      dispatch(
        getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.VIDEO } }),
      );
    }
  };

  /**
   * Mark feed item as watched
   */
  const onPlay = () => {
    dispatch(watchFeedItem(feedItem?.id || ""));
  };

  /**
   * Update progress seconds
   */
  function onProgress(state: OnProgressProps) {
    const progressIncrease =
      state.playedSeconds - prevProgressState.playedSeconds;
    if (progressIncrease > 0) {
      if (progressIncrease < 1) {
        // if progressIncrease >= 1 then we can assume that the user has seeked forward
        // We need to ignore that case
        setProgressSeconds(progressSeconds + progressIncrease);
      }
    }
    prevProgressState = state;
  }

  /**
   * Render list of liked videos
   */
  const displayLikedFeedItems = () => {
    if (likedFeedItems.length > 0) {
      return likedFeedItems.map((likedFeedItem, idx) => (
        <li key={idx} className="pb-3 sm:py-4">
          <Video video={likedFeedItem} />
        </li>
      ));
    } else {
      return (
        <p className="py-4 text-gray-500 text-sm font-semibold">
          No Liked Videos added yet!
        </p>
      );
    }
  };

  /**
   * Render list of disliked videos
   */
  const displayDislikedFeedItems = () => {
    if (dislikedFeedItems.length > 0) {
      return dislikedFeedItems.map((dislikedFeedItem, idx) => (
        <li key={idx} className="pb-3 sm:py-4">
          <Video video={dislikedFeedItem} />
        </li>
      ));
    } else {
      return (
        <p className="py-4 text-gray-500 text-sm font-semibold">
          No Disliked Videos added yet!
        </p>
      );
    }
  };

  if (getIsSmScreen() || isSmScreen) {
    // Force the user to mobile-compatible videos view
    window.location.href = APP_URLS.FEED_MOBILE;
  }

  /**
   * Fetch data from API
   */
  useEffect(() => {
    document.title = "Videos | BrainCargo";

    dispatch(retrieveConnectedAdvertisers());
    dispatch(
      getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.VIDEO } }),
    );
    dispatch(
      getLikedFeedItems({ queryParams: { types: FEED_ITEM_TYPES.VIDEO } }),
    );
    dispatch(
      getDislikedFeedItems({ queryParams: { types: FEED_ITEM_TYPES.VIDEO } }),
    );
  }, []);

  /**
   * Increase watch time when user leaves the page
   */
  useEffect(() => {
    const onUnload = function (e: BeforeUnloadEvent) {
      updateWatchedFor();
    };

    window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [progressSeconds]);

  return (
    <PageWrapper>
      <div className="border shadow-md sm:rounded-lg bg-white">
        <Header title="Videos" />

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 p-2 auto-rows-min">
          <div className="grid grid-cols-2 col-span-2 gap-2 auto-rows-min">
            <div className="col-span-2 justify-center items-center">
              {feedItem ? (
                <ReactPlayer
                  className="w-full aspect-video rounded"
                  url={generateYoutubeUrl(feedItem.youtube_video_id || "")}
                  onPlay={onPlay}
                  onProgress={onProgress}
                  aria-label="Video player"
                />
              ) : (
                <div className="text-center">
                  <p className="bg-gray-200 font-semibold p-6">
                    Videos are not available at this time
                  </p>
                </div>
              )}
            </div>

            <div className="col-span-2 flex flex-col md:flex-row gap-2 w-full rounded">
              <button
                onClick={handleLikeFeedItem}
                disabled={!feedItem}
                className="flex p-2 bg-[#EBEDF0] font-semibold border rounded-lg justify-center"
                aria-label="Like video"
              >
                <HandThumbUpIcon className="w-6 h-6" />
                &#160;Like
              </button>
              <button
                onClick={handleDislikeFeedItem}
                disabled={!feedItem}
                className="flex p-2 bg-[#EBEDF0] font-semibold border rounded-lg justify-center"
                aria-label="Dislike video"
              >
                <HandThumbDownIcon className="w-6 h-6" />
                &#160;Dislike
              </button>
              <button
                onClick={handleSkipFeedItem}
                disabled={!feedItem}
                className="flex p-2 md:ml-auto bg-[#EBEDF0] font-semibold border rounded-lg justify-center"
                aria-label="Skip video"
              >
                <ForwardIcon className="w-6 h-6" />
                &#160;Skip
              </button>
              <button
                onClick={() => setShowReportModal(true)}
                disabled={!feedItem}
                className="flex p-2 bg-[#EBEDF0] font-semibold border rounded-lg justify-center"
                aria-label="Report video"
              >
                <FlagIcon className="w-6 h-6" />
                &#160;Report
              </button>
              <button
                onClick={() => setShowSelectPlaylistPopup(true)}
                disabled={!feedItem}
                className="flex p-2 bg-[#EBEDF0] font-semibold border rounded-lg justify-center"
                aria-label="Add video to playlist"
              >
                <PlusIcon className="w-6 h-6" />
                &#160;Add to Playlist
              </button>
            </div>

            <AdByRandomProvider className="col-span-2 justify-center items-center" />

            <div className="border rounded col-span-2 xl:col-span-1">
              <div className="bg-gray-100 p-4 border-b">
                <span className="font-semibold">Liked Videos</span>
              </div>
              <ul className="px-4 divide-y divide-gray-200 max-h-[40rem] overflow-auto">
                {displayLikedFeedItems()}
              </ul>
            </div>
            <div className="border rounded col-span-2 xl:col-span-1">
              <div className="bg-gray-100 p-4 border-b">
                <span className="font-semibold">Disliked Videos</span>
              </div>
              <ul className="px-4 divide-y divide-gray-200 max-h-[40rem] overflow-auto">
                {displayDislikedFeedItems()}
              </ul>
            </div>
          </div>
          <div className="w-full">
            <div className="border rounded">
              <MyPlaylistList smallHeader />
              <SharedPlaylistList smallHeader />
            </div>
          </div>
        </div>

        <ModalEditCreatePlaylist
          show={showPlaylistPopup}
          onClose={() => setShowPlaylistPopup(false)}
        />
        {showSelectPlaylistPopup && feedItem && (
          <SelectPlaylistPopup
            feedItem={feedItem}
            show={showSelectPlaylistPopup}
            onClose={() => setShowSelectPlaylistPopup(false)}
          />
        )}

        {feedItem && (
          <ReportFeedItemModal
            show={showReportModal}
            setShow={setShowReportModal}
            feedItem={feedItem}
          />
        )}
      </div>
    </PageWrapper>
  );
}
