import React, { ReactNode } from "react";

/**
 * Component that renders an expandable list item used in MobileBottomNavBar item.
 */
export default function MobileBottomNavBarExpandableItem({
  expandedIcon,
  collapsedIcon,
  expanded,
  onClick,
  label,
}: {
  expandedIcon: ReactNode;
  collapsedIcon: ReactNode;
  expanded: boolean;
  onClick: () => void;
  label: string;
}) {
  return (
    <div className="flex justify-center">
      <button
        onClick={onClick}
        className={
          "flex flex-col items-center text-base font-normal text-gray-900"
        }
      >
        <span className={"rounded-2xl border-2 p-2 border-gray-200"}>
          {expanded ? expandedIcon : collapsedIcon}
        </span>
        <span className="text-xs mt-1 text-center">{label}</span>
      </button>
    </div>
  );
}
