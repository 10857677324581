import { User } from "types/user";

export interface Feedback {
  id: string;
  type: FeedbackType;
  file: string | null;
  description: string;
  created_at: string;
  updated_at: string;
  user: User;
}

export type FeedbackType = "bug" | "suggestion" | "feedback" | "other";

export const FEEDBACK_TYPE_LABELS: { [key in FeedbackType]: string } = {
  bug: "Bug",
  suggestion: "Suggestion",
  feedback: "Feedback",
  other: "Other",
};

export interface CreateFeedbackPayload {
  type: Feedback["type"];
  description: Feedback["description"];
  file: File | null;
}
