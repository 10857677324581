import { FeedItem } from "types/feed";
import {
  animateDragLeftRight,
  disableTransition,
  enableTransition,
  focusFeedItem,
  moveFeedItemLeft,
  moveFeedItemRight,
  moveFollowingFeedItemsToFront,
  offsetFeedItemsVertically,
  DISLIKE_POPUP_DURATION_MS,
  HOLD_LEEWAY_PX,
  HOLD_MS,
  LIKE_POPUP_DURATION_MS,
  MAX_DOUBLE_TAP_DELAY_MS,
  MIN_HORIZONTAL_DRAG_PX,
  MIN_VERTICAL_DRAG_PX,
  TAP_MS,
  TRANSITION_MS,
} from "./utils";

/**
 * Handle touch move events.
 */
export function handleTouchMove({
  e,
  currY,
  currX,
  startY,
  startX,
  currFeedItemIdx,
  feedItems,
  tapTimeout,
}: {
  e: TouchEvent;
  currY: number;
  currX: number;
  startY: number;
  startX: number;
  currFeedItemIdx: number;
  feedItems: Array<FeedItem>;
  tapTimeout: NodeJS.Timeout | null;
}) {
  currY = e.targetTouches[0].pageY;
  currX = e.targetTouches[0].pageX;
  if (currY !== 0 && feedItems) {
    const deltaX = currX - startX;
    const deltaY = currY - startY;

    // Clear tapTimeout
    if (tapTimeout) {
      clearTimeout(tapTimeout);
    }

    // Support movement along only one axis at a time
    if (Math.abs(deltaY) > Math.abs(deltaX)) {
      // Vertical movement
      // resetItemPositions({ dataRef });

      // Block users from swiping down if there are no previous feedItems available
      // and block them from swiping up if there are no next feedItems available
      if (
        (currFeedItemIdx !== 0 || deltaY <= 0) &&
        (currFeedItemIdx < feedItems.length - 1 || deltaY > 0)
      ) {
        offsetFeedItemsVertically({
          feedItemIdx: currFeedItemIdx,
          feedItems,
          deltaY,
        });
      }
    } else {
      // Horizontal movement
      focusFeedItem({ feedItems, feedItemIdx: currFeedItemIdx });
      if (deltaX <= 0) {
        animateDragLeftRight({
          startX,
          currX,
          feedItems,
          feedItemIdx: currFeedItemIdx,
        });
      } else {
        animateDragLeftRight({
          startX,
          currX,
          feedItems,
          feedItemIdx: currFeedItemIdx,
        });
      }
    }
  }

  return { currY, currX };
}

/**
 * Handle touch start events.
 */
export function handleTouchStart({
  e,
  holdMs,
  startY,
  startX,
  currY,
  currX,
  playing,
  setShowPlaylistsDrawer,
  setPlaying,
  feedItems,
}: {
  e: TouchEvent;
  holdMs: number;
  startY: number;
  startX: number;
  currY: number;
  currX: number;
  playing: boolean;
  setShowPlaylistsDrawer: (val: boolean) => void;
  setPlaying: (val: boolean) => void;
  feedItems: FeedItem[];
}) {
  // Prepare variables used by handleTouchEnd, handleTouchMove & holdInterval
  startY = e.targetTouches[0].pageY;
  currY = e.targetTouches[0].pageY;
  currX = e.targetTouches[0].pageX;
  startX = e.targetTouches[0].pageX;
  holdMs = 0;
  setShowPlaylistsDrawer(false);

  // Prepare tap handler timeout
  const tapTimeout = setTimeout(() => {
    if (holdMs !== 0) {
      clearTimeout(tapTimeout);
    }
    setPlaying(!playing);
  }, TAP_MS);

  // Prepare variables used by HoldHandler
  const holdInterval = setInterval(() => {
    clearTimeout(tapTimeout);
    holdMs += 10;
    const absDeltaY = Math.abs(currY - startY);
    const absDeltaX = Math.abs(currX - startX);
    const meetsHoldCriterion =
      holdMs >= HOLD_MS &&
      absDeltaX <= HOLD_LEEWAY_PX &&
      absDeltaY <= HOLD_LEEWAY_PX;
    if (meetsHoldCriterion) {
      handleHold({ setShowPlaylistsDrawer });
      clearInterval(holdInterval);
    } else if (holdMs > HOLD_MS) {
      clearInterval(holdInterval);
    }
  }, 10);

  // We need to disable css transition when dragging the element, otherwise
  // it will interfere with the item's positioning
  disableTransition({ feedItems });

  return { holdMs, holdInterval, startY, currY, currX, startX, tapTimeout };
}

/**
 * Handle touch end events.
 */
export function handleTouchEnd({
  startY,
  startX,
  currX,
  currY,
  holdInterval,
  tapTimeout,
  prevTouchDate,
  currFeedItemIdx,
  feedItems,
  setCurrFeedItemIdx,
  setShowLikePopup,
  setShowDislikePopup,
  handleLikeFeedItem,
  handleDislikeFeedItem,
  handleAddToDefaultPlaylist,
  handleSkipFeedItem,
  setPlaying,
}: {
  startY: number;
  startX: number;
  currX: number;
  currY: number;
  holdInterval: NodeJS.Timeout | null;
  tapTimeout: NodeJS.Timeout | null;
  prevTouchDate: Date | null;
  currFeedItemIdx: number;
  feedItems: Array<FeedItem>;
  setCurrFeedItemIdx: (val: number) => void;
  setShowLikePopup: (val: boolean) => void;
  setShowDislikePopup: (val: boolean) => void;
  handleLikeFeedItem: () => Promise<boolean>;
  handleDislikeFeedItem: () => Promise<boolean>;
  handleAddToDefaultPlaylist: () => Promise<boolean>;
  handleSkipFeedItem: () => Promise<boolean>;
  setPlaying: (val: boolean) => void;
}) {
  // Clear holdInterval
  if (holdInterval) {
    clearInterval(holdInterval);
  }

  // Check if double-tap condition is met
  const now = new Date();
  if (prevTouchDate) {
    const diffMs = now.getTime() - prevTouchDate.getTime();
    if (diffMs <= MAX_DOUBLE_TAP_DELAY_MS) {
      // Clear tapTimeout
      if (tapTimeout) {
        clearTimeout(tapTimeout);
      }

      handleDoubleTap({
        setShowLikePopup,
        handleLikeFeedItem,
        currFeedItemIdx,
        feedItems,
      });
    }
  }
  prevTouchDate = new Date();

  // We need to enable css transition after dragging the element, otherwise
  // it will interfere with the item's positioning
  enableTransition({ feedItems });

  const deltaY = currY - startY;
  const deltaX = currX - startX;

  if (
    Math.abs(deltaY) >= MIN_VERTICAL_DRAG_PX ||
    Math.abs(deltaX) >= MIN_HORIZONTAL_DRAG_PX
  ) {
    // User moved the content enough to trigger actions
    if (Math.abs(deltaX) >= Math.abs(deltaY)) {
      setPlaying(false);
      // Handle horizontal swipes
      if (deltaX < 0) {
        handleSwipeLeft({
          currFeedItemIdx,
          feedItems,
          setShowDislikePopup,
          handleDislikeFeedItem,
          setCurrFeedItemIdx,
        });
      }
      if (deltaX > 0) {
        handleSwipeRight({
          currFeedItemIdx,
          feedItems,
          setShowLikePopup,
          handleLikeFeedItem,
          setCurrFeedItemIdx,
          handleAddToDefaultPlaylist,
        });
      }
    } else {
      setPlaying(false);
      // Handle vertical swipes
      if (deltaY < 0) {
        handleSwipeUp({
          currFeedItemIdx,
          feedItems,
          setCurrFeedItemIdx,
          handleSkipFeedItem,
        });
      }
      if (deltaY > 0) {
        handleSwipeDown({
          currFeedItemIdx,
          feedItems,
          setCurrFeedItemIdx,
        });
      }
    }
  } else {
    // Not enough movement - reset items' positions
    focusFeedItem({ feedItems, feedItemIdx: currFeedItemIdx });
  }

  return { prevTouchDate };
}

/**
 * Handle double tapping feed item.
 */
export async function handleDoubleTap({
  setShowLikePopup,
  handleLikeFeedItem,
  currFeedItemIdx,
  feedItems,
}: {
  setShowLikePopup: (val: boolean) => void;
  handleLikeFeedItem: () => Promise<boolean>;
  currFeedItemIdx: number;
  feedItems: FeedItem[];
}) {
  await handleLikeFeedItem();
  setShowLikePopup(true);
  focusFeedItem({ feedItems, feedItemIdx: currFeedItemIdx });

  setTimeout(() => {
    setShowLikePopup(false);
  }, LIKE_POPUP_DURATION_MS);
}

/**
 * Handle swiping up.
 */
export function handleSwipeUp({
  currFeedItemIdx,
  feedItems,
  setCurrFeedItemIdx,
  handleSkipFeedItem,
}: {
  currFeedItemIdx: number;
  feedItems: Array<FeedItem>;
  setCurrFeedItemIdx: (val: number) => void;
  handleSkipFeedItem: () => Promise<boolean>;
}) {
  const newCurrFeedItemIdx = currFeedItemIdx + 1;

  // Block users from swiping up if there are no next feedItems available
  if (feedItems && newCurrFeedItemIdx >= feedItems.length) {
    focusFeedItem({ feedItems, feedItemIdx: currFeedItemIdx });
    return;
  }

  enableTransition({ feedItems });
  focusFeedItem({
    feedItems,
    feedItemIdx: newCurrFeedItemIdx,
  });
  setCurrFeedItemIdx(newCurrFeedItemIdx);
  handleSkipFeedItem();
}

/**
 * Handle swiping down.
 */
export function handleSwipeDown({
  currFeedItemIdx,
  setCurrFeedItemIdx,
  feedItems,
}: {
  currFeedItemIdx: number;
  setCurrFeedItemIdx: (val: number) => void;
  feedItems: FeedItem[];
}) {
  const newCurrFeedItemIdx = currFeedItemIdx - 1;

  // Block users from swiping down if there are no previous feedItems available
  if (newCurrFeedItemIdx < 0) {
    focusFeedItem({ feedItems, feedItemIdx: currFeedItemIdx });
    return;
  }

  enableTransition({ feedItems });
  focusFeedItem({
    feedItems,
    feedItemIdx: newCurrFeedItemIdx,
  });
  setCurrFeedItemIdx(newCurrFeedItemIdx);
}

/**
 * Handle swiping left.
 */
export async function handleSwipeLeft({
  currFeedItemIdx,
  feedItems,
  setShowDislikePopup,
  handleDislikeFeedItem,
  setCurrFeedItemIdx,
}: {
  currFeedItemIdx: number;
  feedItems: Array<FeedItem>;
  setShowDislikePopup: (val: boolean) => void;
  handleDislikeFeedItem: () => Promise<boolean>;
  setCurrFeedItemIdx: (val: number) => void;
}) {
  await handleDislikeFeedItem();
  enableTransition({ feedItems });
  const newCurrFeedItemIdx = currFeedItemIdx + 1;
  setCurrFeedItemIdx(newCurrFeedItemIdx);
  setShowDislikePopup(true);
  moveFeedItemLeft({ feedItems, feedItemIdx: currFeedItemIdx });
  moveFollowingFeedItemsToFront({ feedItems, feedItemIdx: currFeedItemIdx });

  setTimeout(() => {
    setShowDislikePopup(false);
  }, DISLIKE_POPUP_DURATION_MS);

  setTimeout(() => {
    disableTransition({ feedItems });
    focusFeedItem({ feedItems, feedItemIdx: newCurrFeedItemIdx });
  }, TRANSITION_MS);
}

/**
 * Handle swiping right.
 */
export async function handleSwipeRight({
  currFeedItemIdx,
  feedItems,
  setShowLikePopup,
  handleLikeFeedItem,
  setCurrFeedItemIdx,
  handleAddToDefaultPlaylist,
}: {
  currFeedItemIdx: number;
  feedItems: Array<FeedItem>;
  setShowLikePopup: (val: boolean) => void;
  handleLikeFeedItem: () => Promise<boolean>;
  setCurrFeedItemIdx: (val: number) => void;
  handleAddToDefaultPlaylist: () => Promise<boolean>;
}) {
  await Promise.allSettled([
    handleLikeFeedItem(),
    handleAddToDefaultPlaylist(),
  ]);
  enableTransition({ feedItems });
  const newCurrFeedItemIdx = currFeedItemIdx + 1;
  setCurrFeedItemIdx(newCurrFeedItemIdx);
  setShowLikePopup(true);
  moveFeedItemRight({ feedItems, feedItemIdx: currFeedItemIdx });
  moveFollowingFeedItemsToFront({ feedItems, feedItemIdx: currFeedItemIdx });

  setTimeout(() => {
    setShowLikePopup(false);
  }, DISLIKE_POPUP_DURATION_MS);

  setTimeout(() => {
    disableTransition({ feedItems });
    focusFeedItem({ feedItems, feedItemIdx: newCurrFeedItemIdx });
  }, TRANSITION_MS);
}

/**
 * Handle holding feed item.
 */
export function handleHold({
  setShowPlaylistsDrawer,
}: {
  setShowPlaylistsDrawer: (val: boolean) => void;
}) {
  setShowPlaylistsDrawer(true);
}
