import React from "react";
import { QuizQuestion, QuizQuestionFormData } from "types/quiz";
import NoImage from "images/No-Image.png";

interface Props {
  question: QuizQuestion | QuizQuestionFormData;
  additionalClassName?: string;
  hideIfNoImage?: boolean;
}

/**
 * Quiz question image.
 */
export default function QuestionImage({
  question,
  additionalClassName = "",
  hideIfNoImage = false,
}: Props) {
  /**
   * Convert file to url.
   */
  function convertFileToUrl(file: File | string) {
    if (typeof file === "string") {
      return file;
    }
    return URL.createObjectURL(file);
  }

  /**
   * Generate image src.
   */
  function getImageSrc() {
    if (question.image) {
      return convertFileToUrl(question.image);
    } else if (question.external_image) {
      return question.external_image || undefined;
    } else {
      return NoImage;
    }
  }

  /**
   * Don't render if there is no image.
   */
  if (hideIfNoImage && !question.external_image && !question.image) return null;

  return (
    <img
      src={getImageSrc()}
      alt={question.image ? "Quiz question image" : "No image available"}
      className={`object-cover inline w-[128px] h-[128px] rounded-xl border-2 border-gray ${additionalClassName}`}
    />
  );
}
