import { MOBILE_FEED_BOTTOM_NAV_BAR_HEIGHT } from "components/navigation/mobile/bottom/MobileBottomNavBarFeed";
import { MOBILE_TOP_NAV_BAR_HEIGHT } from "components/navigation/mobile/top/MobileTopNavBar";

import { FeedItem } from "types/feed";
import { CSSProperties } from "react";

/** Number of pixels that the touch point can be moved before hold action is canceled */
export const HOLD_LEEWAY_PX = 50;

/** Height of the VideoPlayer SeekBar */
export const SEEK_BAR_HEIGHT = 20;

/** Number of miliseconds that have to pass before tap and hold action is triggered */
export const HOLD_MS = 500;

/** Represents for how long should the like popup be visible upon liking a feedItem */
export const LIKE_POPUP_DURATION_MS = 600;

/** Represents for how long should the like popup be visible upon liking a feedItem */
export const DISLIKE_POPUP_DURATION_MS = 600;

/** Value of transition-duration css property in miliseconds set for draggable items */
export const TRANSITION_MS = 200;

/** The minimal amount of pixels users have to drag up/down before gesture handler functions are fired */
export const MIN_VERTICAL_DRAG_PX = 200;

/** The minimal amount of pixels users have to drag left/right before gesture handler functions are fired */
export const MIN_HORIZONTAL_DRAG_PX = 100;

/** Two taps within given miliseconds will trigger the double tap action */
export const MAX_DOUBLE_TAP_DELAY_MS = 300;

/** Number of miliseconds that pass before single tap action is triggered */
export const TAP_MS = 550;

/** Represents the maximal rotation(degrees) of the feedItem card upon swiping left or right */
export const MAX_HORIZONTAL_SWIPE_ROTATION = 30;

export const TRANSITION_STYLE_PROPERTY: CSSProperties = {
  transitionProperty: "all",
  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
  transitionDuration: `${TRANSITION_MS}ms`,
};

export const FORWARD_FEED_ITEM_Z_INDEX = "20";
export const REAR_FEED_ITEM_Z_INDEX = "10";
export const YOUTUBE_PLAYER_CONTROLS_Z_INDEX = "100";

/**
 * Generates a unique id for a feed item html element.
 */
export function generateFeedItemHtmlId(
  feedItem: FeedItem,
  currFeedItemIdx: number,
) {
  return currFeedItemIdx + "-" + feedItem.id;
}

/**
 * Returns the html element of a feed item.
 */
export function getFeedItemHtmlElement(feedItem: FeedItem, idx: number) {
  return document.getElementById(generateFeedItemHtmlId(feedItem, idx));
}

/**
 * Returns an array of html elements of all feed items.
 */
export function getAllFeedItemHtmlElements(feedItems: FeedItem[]) {
  return feedItems.map((feedItem, idx) =>
    getFeedItemHtmlElement(feedItem, idx),
  );
}

/**
 * Disables transition css for all feed items.
 */
export function disableTransition({ feedItems }: { feedItems: FeedItem[] }) {
  const feedItemHtmlElements = getAllFeedItemHtmlElements(feedItems);
  feedItemHtmlElements.forEach((htmlElement) => {
    if (htmlElement) {
      htmlElement.style.transitionDuration = "0ms";
    }
  });
}

/**
 * Enables transition css for all feed items.
 */
export function enableTransition({ feedItems }: { feedItems: FeedItem[] }) {
  const feedItemHtmlElements = getAllFeedItemHtmlElements(feedItems);
  feedItemHtmlElements.forEach((htmlElement) => {
    if (htmlElement) {
      htmlElement.style.transitionDuration = `${TRANSITION_MS}ms`;
    }
  });
}

/**
 * Returns the height of the feed item component.
 */
export function getComponentHeight() {
  return (
    window.outerHeight -
    parseInt(MOBILE_TOP_NAV_BAR_HEIGHT) -
    parseInt(MOBILE_FEED_BOTTOM_NAV_BAR_HEIGHT)
  );
}

/**
 * Returns the width of the feed item component.
 */
export function getComponentWidth() {
  return window.outerWidth;
}

/**
 * Moves the feed item to the left.
 */
export function moveFeedItemLeft({
  feedItemIdx,
  feedItems,
}: {
  feedItemIdx: number;
  feedItems: FeedItem[];
}) {
  const feedItemHtmlElement = getFeedItemHtmlElement(
    feedItems[feedItemIdx],
    feedItemIdx,
  );
  if (feedItemHtmlElement) {
    feedItemHtmlElement.style.left = 1.5 * -getComponentWidth() + "px";
  }
}

/**
 * Moves the feed item to the right.
 */
export function moveFeedItemRight({
  feedItemIdx,
  feedItems,
}: {
  feedItemIdx: number;
  feedItems: FeedItem[];
}) {
  const feedItemHtmlElement = getFeedItemHtmlElement(
    feedItems[feedItemIdx],
    feedItemIdx,
  );
  if (feedItemHtmlElement) {
    feedItemHtmlElement.style.left = 1.5 * getComponentWidth() + "px";
  }
}

/**
 * Moves the feed item to the forefront and other feed items above/below it.
 */
export function focusFeedItem({
  feedItemIdx,
  feedItems,
}: {
  feedItemIdx: number;
  feedItems: FeedItem[];
}) {
  const itemHeight = getComponentHeight();
  const feedItemHtmlElements = getAllFeedItemHtmlElements(feedItems);
  feedItemHtmlElements.forEach((htmlElement, idx) => {
    // feedItems[feedItemIdx] is supposed to have its top property set to 0px
    // all other items must have theirs set relative to to it
    const topOffset = -(itemHeight * (feedItemIdx - idx));

    if (htmlElement) {
      htmlElement.style.top = topOffset + "px";
      htmlElement.style.left = "0px";
      htmlElement.style.transform = "";
    }
  });
}

/**
 * Handles vertical dragging of feed items.
 */
export function offsetFeedItemsVertically({
  feedItemIdx,
  feedItems,
  deltaY,
}: {
  feedItemIdx: number;
  feedItems: FeedItem[];
  deltaY: number;
}) {
  const itemHeight = getComponentHeight();
  const feedItemHtmlElements = getAllFeedItemHtmlElements(feedItems);
  feedItemHtmlElements.forEach((htmlElement, idx) => {
    if (htmlElement) {
      const topOffset = -(itemHeight * (feedItemIdx - idx)) + deltaY;
      htmlElement.style.top = topOffset + "px";
    }
  });
}

/**
 * Updates the z-indexes of feed items.
 */
export function updateFeedItemZIndexes({
  feedItemIdx,
  feedItems,
}: {
  feedItemIdx: number;
  feedItems: FeedItem[];
}) {
  // Set current feed item's z-index to 1 and all others to 0
  const feedItemHtmlElements = getAllFeedItemHtmlElements(feedItems);
  feedItemHtmlElements.forEach((htmlElement, idx) => {
    if (htmlElement) {
      if (idx === feedItemIdx) {
        htmlElement.style.zIndex = FORWARD_FEED_ITEM_Z_INDEX;
      } else {
        htmlElement.style.zIndex = REAR_FEED_ITEM_Z_INDEX;
      }
    }
  });
}

/**
 * Moves up feed items following the current feed item.
 */
export function moveFollowingFeedItemsToFront({
  feedItems,
  feedItemIdx,
}: {
  feedItems: FeedItem[];
  feedItemIdx: number;
}) {
  // Move following feed items into forefront
  const itemHeight = getComponentHeight();
  const followingFeedItems = feedItems.slice(feedItemIdx + 1);
  followingFeedItems.forEach((feedItem, idx) => {
    const itemIdx = idx + feedItemIdx + 1;
    const htmlElement = getFeedItemHtmlElement(feedItem, itemIdx);
    if (htmlElement) {
      htmlElement.style.top = itemHeight * idx + "px";
    }
  });
}

/**
 * Animates the feed item drag to the left/right.
 */
export function animateDragLeftRight({
  feedItems,
  feedItemIdx,
  startX,
  currX,
}: {
  feedItems: FeedItem[];
  feedItemIdx: number;
  startX: number;
  currX: number;
}) {
  const currFeedItemHtmlElement = getFeedItemHtmlElement(
    feedItems[feedItemIdx],
    feedItemIdx,
  );

  // Move horizontally
  const deltaX = currX - startX;

  // Rotate
  const width = getComponentWidth();
  const rotationPerPixel = MAX_HORIZONTAL_SWIPE_ROTATION / width;
  const rotation = rotationPerPixel * deltaX;
  if (currFeedItemHtmlElement) {
    currFeedItemHtmlElement.style.left = deltaX + "px";
    currFeedItemHtmlElement.style.transform = `rotate(${rotation}deg)`;
  }

  // Move following feed items into forefront
  const itemHeight = getComponentHeight();
  const movementPerPixel = itemHeight / width;
  const movement = movementPerPixel * Math.abs(deltaX);
  const followingFeedItems = feedItems.slice(feedItemIdx + 1);
  followingFeedItems.forEach((feedItem, idx) => {
    const itemIdx = idx + feedItemIdx + 1;
    const htmlElement = getFeedItemHtmlElement(feedItem, itemIdx);
    if (htmlElement) {
      htmlElement.style.top = itemHeight * (idx + 1) - movement + "px";
    }
  });
}
