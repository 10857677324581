import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import Header from "components/common/header";
import FeedbackDetailsComponent from "components/feedback/FeedbackDetails";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useParams } from "react-router-dom";
import { retrieveFeedback } from "redux/feedback/feedbackSlice";

/**
 * Feedback details page.
 */
export default function FeedbackDetails() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const feedback = useAppSelector((state) => state.feedback.feedback);
  const pendingGetFeedback = useAppSelector(
    (state) => state.feedback.pendingGetFeedback,
  );

  /**
   * Retrieve feedback.
   */
  useEffect(() => {
    if (id) {
      dispatch(retrieveFeedback({ id }));
    }
  }, [id]);

  useEffect(() => {
    document.title = "Feedback | BrainCargo";
  }, []);

  return (
    <PageWrapper>
      <div className="border shadow-md lg:rounded-lg bg-white pb-6 space-y-2" role="main">
        <Header title="Feedback" />
        <div className="py-2 px-4">
          <FeedbackDetailsComponent
            feedback={feedback}
            pending={pendingGetFeedback}
          />
        </div>
      </div>
    </PageWrapper>
  );
}
