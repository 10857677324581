import { UserRestricted } from "types/user";

export type FeedItemType = "video" | "news" | "track" | "movie";

export const FEED_ITEM_TYPES: {
  [key in "VIDEO" | "NEWS" | "TRACK" | "MOVIE"]: FeedItemType;
} = {
  VIDEO: "video",
  NEWS: "news",
  TRACK: "track",
  MOVIE: "movie",
};

export const FEED_ITEM_TYPE_LABELS: {
  [key in FeedItemType]: string;
} = {
  video: "Video",
  news: "News",
  track: "Track",
  movie: "Movie",
};

export interface FeedItem {
  id: string;
  title: string;
  description: string | null;
  image: string | null;
  type: FeedItemType;
  youtube_video_id: string;
  spotify_track_id: string;
  tmdb_movie_id: string;
  bing_news_url: string;
  liked: boolean;
  disliked: boolean;
  skipped: boolean;
  watched: boolean;
  playlists: Array<string>;
  hidden: boolean;
  ratings: Array<Rating>;
  streaming_providers: Array<StreamingProvider>;

  tmdb_data: TMDBData | null;
}

export interface FeedItemDataset extends Omit<FeedItem, "playlists"> {
  liked_by: Array<string>;
  disliked_by: Array<string>;
  watched_by: Array<string>;
  skipped_by: Array<string>;
  liked_by_count: number;
  disliked_by_count: number;
  watched_by_count: number;
  skipped_by_count: number;
}

export type FeedDataRef = React.MutableRefObject<{
  currX: number;
  currY: number;
  prevIframePosition: number;
  currIframePosition: number;
  nextIframePosition: number;
}>;

export interface FeedItemActions {
  id: string;

  feed_item: FeedItemType;
  user: string;

  liked: boolean;
  liked_at: string | null;

  disliked: boolean;
  disliked_at: string | null;

  watched: boolean;
  watched_at: string | null;
  watched_for: number | null;

  skipped: boolean;
  skipped_at: string | null;
}

export interface FeedItemActionsPayload {
  id?: string;

  feed_item: string;
  user?: string;

  liked?: boolean;
  liked_at?: string | null;

  disliked?: boolean;
  disliked_at?: string | null;

  watched?: boolean;
  watched_at?: string | null;
  watched_for?: number | null;

  skipped?: boolean;
  skipped_at?: string | null;
}

export interface FeedItemReport {
  id: string;
  user: UserRestricted;
  feed_item: FeedItem;
  description: string;
  is_reviewed: boolean;
  created_at: string;
  updated_at: string;
}

export interface StreamingProvider {
  id: string;
  link: string;
  price_amount: number | null;
  price_currency: string | null;
  quality: string;
  service: string;
  streaming_type: string;
  tmdb_movie_id: string;
  updated_at: string;
  created_at: string;
}

export interface Rating {
  url: string;
  score: string;
  provider: string;
}

export interface SubmitFeedItemReportPayload {
  feed_item: string;
  description: string;
}

export interface ToggleFeedItemReportStatusPayload {
  id: string;
  is_reviewed: boolean;
}

export interface UpdateFeedItemPayload {
  id?: string;
  title?: string;
  description?: string | null;
  image?: string | null;
  type?: FeedItemType;
  youtube_video_id?: string;
  spotify_track_id?: string;
  bing_news_url?: string;
  liked?: boolean;
  disliked?: boolean;
  skipped?: boolean;
  watched?: boolean;
  hidden?: boolean;
}

export interface TMDBVideo {
  iso_639_1: string;
  iso_3166_1: string;
  name: string;
  key: string;
  site: string;
  size: number;
  type: string;
  official: boolean;
  published_at: string;
  id: string;
}

export interface TMDBCast {
  adult: boolean;
  gender: number;
  id: number;
  known_for_department: string;
  name: string;
  original_name: string;
  popularity: number;
  profile_path: string;
  cast_id: number;
  character: string;
  credit_id: string;
  order: number;
}

export interface TMDBGenre {
  id: number;
  name: string;
}

export interface TMDBData {
  release_date: string;
  revenue: number;
  runtime: number;
  genres: Array<TMDBGenre>;
  videos: Array<TMDBVideo>;
  cast: Array<TMDBCast>;
}
